export const nriFaqData = [
  {
    name: 'Who is an NRI (Non-Resident Indian)?',
    links: [
      
    ],
    paradetail:
      "An NRI refers to an Indian citizen who possesses a valid Indian passport and resides abroad for purposes such as employment, conducting business, vacationing outside India, or residing abroad under circumstances that suggest an uncertain duration of stay. This classification also extends to non-resident foreign citizens of Indian origin, who are treated equivalently to NRIs."
  },
  {
    name: 'Who Qualifies as a Person of Indian Origin (PIO) for Property Investment?',
    links: [
     "They held an Indian passport at any point.", 
     "They, their father, or their paternal grandfather was a citizen of India by virtue of the Constitution of India or the Citizenship Act, 1955 (Act No. 57 of 1955)."
    ],
    paradetail:
      "A foreign citizen, except those from Pakistan, Bangladesh, Afghanistan, Bhutan, Sri Lanka, or Nepal, is deemed a person of Indian origin if they meet the following criteria:"
  },
  {
    name: 'Understanding Overseas Corporate Bodies (OCBs) ',
    links: [
     
    ],
    paradetail:
      "Overseas Corporate Bodies (OCBs) encompass entities predominantly owned by individuals of Indian nationality or origin residing outside India. This category includes overseas companies, partnership firms, societies, and other corporate bodies. For an entity to be classified as an OCB, at least 60% of its ownership must be held by individuals of Indian nationality or origin who are residing outside India. Additionally, overseas trusts qualify if a minimum of 60% of the beneficial interest is irrevocably held by such individuals. Importantly, this ownership must be held by them directly and not in the capacity of nominees."
  }
  ];
