import React from 'react';
// import { motion } from 'framer-motion';
// Video
// import { Link } from 'react-router-dom';
import Layout from "../../../layout/Layout/Layout";


import QRastrum1 from '../../../../assets/pdp/astrumqr/Astrum-RERA-QR-Code.jpg';


// Stylesheet
import './barcode.scss';



const Barcode = () => {
  return (

    <section className='barcode-sec'>   
       <Layout>
       <div className='pdp_decription'>
                <div className='bannertagline'>
                    <p>MahaRERA Registration Numbers: P51800029353. <br/>Project details are available at <a href="https://maharera.mahaonline.gov.in" target="_blank" rel="noopener"> https://maharera.mahaonline.gov.in</a>
                    </p>
                </div>

                <div className='pdp_barcode'>
                    <div className='barcode'>
                        <img src={QRastrum1} alt="plan" />
                        {/* <span>Avanya Tower A</span> */}
                    </div>
                    {/* <div className='barcode'>
                        <img src={QRcode} alt="plan" />
                        <span>Avanya Tower B</span>
                    </div> */}
                </div>
            </div>
    </Layout>

    </section>

  );
};

export default Barcode;
