import React, { useState } from 'react';
import './bottomNav.scss';
import { useLocation } from 'react-router-dom';


const BottomNav = ({pdpBottomNavData}) => {
  const [sticky, setSticky] = useState(false);

  const stickyOverviewNav = () => {
    if (window.scrollY >= 800) {
      if (!sticky) setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const location = useLocation();


  const scrollToAbout = (e) => {
    if(location.hash) {
        var anchor = document.querySelector(location.hash).getBoundingClientRect();
        window.scrollTo(anchor.left, anchor.top + window.scrollY - 60);
    }
  }

  window.addEventListener('scroll', stickyOverviewNav);
  return (
    <section className={sticky ? 'bottom_nav sticky_overview_nav' : 'bottom_nav'}>
      <ul>
        {pdpBottomNavData.map((item, index) => (
          <li key={index}>
            <a href={`#${item.src}`} onClick={scrollToAbout}>{item.title}</a>
          </li>
        ))}
      </ul>
      <hr />
    </section>
  );
};

export default BottomNav;
