import React, { useState } from 'react'
import axios from 'axios';
import attachment from '../../../../assets/icons/attachment.svg'
import ButtonPrimary from '../../button/ButtonPrimary';
import distance from '../../../../assets/icons/distance.svg';
import callwhite from '../../../../assets/icons/callwhite.svg';
import mail from '../../../../assets/icons/mail.svg';
import LayoutMain from "../../../layout/LayoutMain/LayoutMain";

import './channelpartner.scss';


const TeamDetail = () => {

    const [CompanyNameIndivisualName, setCompanyNameIndivisualName] = useState('')
    const [Nameoftheowner, setNameoftheowner] = useState('')
    const [Mobile, setMobile] = useState('')
    const [PANNo, setPANNo] = useState('')
    const [GSTNo, setGSTNo] = useState('')
    const [RERANo, setRERANo] = useState('')
    const [associatname, setAssociatname] = useState('')
    const [Designation, setDesignation] = useState('')
    const [salesmanager, setSalesmanager] = useState('')
    const [NumberofYearsinOperation, setNumberofYearsinOperation] = useState('')


     const [errorMsg, setErrorMsg] = useState('')

    const onChangeTagInput = (value) => {
        return value.replace(/[^a-zA-Z\d ]/ig, "");
      }
      const onChangeTagInputNumber = (value) => {
        return value.replace(/[^0-9.]/g, '');
      }
    

  

   
    // const data = getToken();

    const partnerFormHandler = async (e) => {
        e.preventDefault();

        // getting salesforAPI Auth Token 
      

        const errordiv = document.getElementById('error');
        const errorArr = [];
        var html = '';
        const formEntries = new FormData(e.target).entries();
        const json = Object.assign(...Array.from(formEntries, ([x,y]) => ({[x]:y})));

        const body = {};

        body.addressline1 = json.address;
        body.companyname = json.CompanyNameIndivisualName;
        body.companytype = 'Partnership';
        body.email = json.EmailID;
        body.mobilenumber = json.Mobile;
        body.phone = json.Mobile;
        body.name = json.Nameoftheowner;
        body.pan = json.PANNo;
        body.gstnumber = json.GSTNo;
        body.reranumber = json.RERANo;
        body.firmType = json.entity_radio_group;
        body.firmName = json.CompanyNameIndivisualName;
        body.salesManagerName = json.salesmanager;
        body.noOfYearsInOperation = json.NumberofYearsinOperation;
        body.designation = json.Designation;
        body.brokerAssociation = json.broker_radio_groupthree;
        body.expertise = json.expertise_checkbox_group;
        body.focusLocation = json.focus_radio_grouptwo;


        if(json.salesManagerName == ""){
            errorArr.push('Please fill propper salesManagerName!')
        } 
        if(json.designation == ""){
            errorArr.push('Please fill propper designation!')
        } 
        if(json.noOfYearsInOperation == ""){
            errorArr.push('Please fill propper noOfYearsInOperation!')
        } 

        if(!json.broker_radio_groupthree){
            errorArr.push('Please select your affiliate broker ')
        }
        if(!json.focus_radio_grouptwo){
            errorArr.push('Please select your focus location ')
        }
        if(!json.expertise_checkbox_group){
            errorArr.push('Please select your expertise ')
        }

        if(json.address == ""){
            errorArr.push('Please fill full address!')
        }  
        if(json.CompanyNameIndivisualName == "" || json.CompanyNameIndivisualName.length < 4){
            errorArr.push('Please fill full companyname!')
        }  
        if(json.GSTNo == ""){
            errorArr.push('Please fill propper gstnumber!')
        } 
        if(json.PANNo == ""){
            errorArr.push('Please fill propper pan number!')
        } 
        if(json.RERANo == ""){
            errorArr.push('Please fill propper reranumber!')
        } 
        if(json.tnc != "True"){
            errorArr.push('Please accept term and condition checkbox.')
        }  
        if(!json.entity_radio_group){
            errorArr.push('Please select your entity')
        }

        console.log(json)
        console.log(errorArr)
        if(errorArr.length) {
            html = '<ul>';
            errorArr.forEach((item, index) => {
                html += '<li style="color:red">'+item+'</li>'; 
            });
            html += '</ul>';
           
           setErrorMsg(html)

            return false;
        } else {
            setErrorMsg('')
        }

        const res = await axios.post('http://localhost:8000/api/v1/channel-partner', body).then(function (response) {
            if(response.data.error) {
                setErrorMsg('<ul><li>' + response.data.message+ '</ul></li>');
            }     
            return response;
        })
        .catch(function (error) {
            console.log(error);
        });

        
    }

    return (

        <section className='careerpage'>

            <LayoutMain>
                <div className="contact_container">


                    <div className="bg-about">
                        <div className="title_container">
                            <h3>Join our network today.</h3>
                            <p>For any further assistance, please fill up the form and we will get back to you at the earliest.</p></div>
                        <div className="input_container">
                            {/* <!-- Channel Partner Form --> */}
                            <div className="channel_partner_form">
                                <form onSubmit={partnerFormHandler} id="channel_partner_form" method="POST" autocomplete="off">
                                    <div className="parent-divider">
                                        <div className="divide_form-group">
                                            <div className="register-form_group">
                                                <input type="hidden" name="sourcetype" value="" />
                                                <div className="form-group">
                                                    <h4 className="title-input">Entity*</h4>
                                                    <div class="radioflex">
                                                        <div class="boxradio">
                                                            <input type="radio" id="LLP" name="radio-groupone" value="LLP" />
                                                            <input type="radio" id="Individual" name="entity_radio_group" value="Individual"  />
                                                            <label for="Individual">Individual</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="radio" id="Private" name="entity_radio_group" value="Private Ltd. Co." />
                                                            <label for="Private">Private Ltd. Co.</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="radio" id="Public" name="entity_radio_group" value="Public Ltd. Co." />
                                                            <label for="Public">Public Ltd. Co.</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="radio" id="Proprietorship" name="entity_radio_group" value="Proprietor" />
                                                            <label for="Proprietorship">Proprietorship</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="radio" id="Partnership" name="entity_radio_group" value="Partnership" />
                                                            <label for="Partnership">Partnership</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="radio" id="LLP" name="entity_radio_group" value="LLP" />
                                                            <label for="LLP">LLP</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" id="CompanyNameIndivisualName"  value={CompanyNameIndivisualName} onChange={(e) => setCompanyNameIndivisualName(onChangeTagInput(e.target.value))}  name="CompanyNameIndivisualName" placeholder="Company Name/ Individual Name*" required />
                                                </div>
                                                <div className="form-group">
                                                    <input value={Nameoftheowner} onChange={(e) => setNameoftheowner(onChangeTagInput(e.target.value))} type="text" id="Nameoftheowner" name="Nameoftheowner" placeholder="Name of the owner*"  required />
                                                </div>
                                                <div className="form-group"><h4 className="title-input">Office Address*</h4>
                                                    <input type="text" id="address" name="address" placeholder="Type your address here*" required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="email" id="EmailID" name="EmailID" placeholder="Email ID*" required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="tel" value={Mobile}  onChange={(e) => setMobile(onChangeTagInputNumber(e.target.value))} id="Mobile" name="Mobile" placeholder="Mobile*" required />
                                                </div>
                                                <div className="form-group">
                                                    <h4 className="title-input">Your Focus Location / Area*</h4>
                                                    <div class="radioflex">
                                                        <div class="boxradio">
                                                            <input type="radio" id="Location" name="focus_radio_grouptwo" value="Western Suburbs" />
                                                            <label for="Location">Western Suburbs</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="radio" id="location2" name="focus_radio_grouptwo" value="South Mumbai" />
                                                            <label for="location2">South Mumbai</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="radio" id="location3" name="focus_radio_grouptwo" value="Central Suburbs" />
                                                            <label for="location3">Central Suburbs</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="radio" id="location4" name="focus_radio_grouptwo" value="Navi Mumbai" />
                                                            <label for="location4">Navi Mumbai</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="radio" id="location5" name="radio-grouptwo" value="Outside Mumbai" />
                                                            <label for="location5">Outside Mumbai</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <input value={NumberofYearsinOperation} onChange={(e) => setNumberofYearsinOperation(onChangeTagInput(e.target.value))} type="number" id="NumberofYearsinOperation" name="NumberofYearsinOperation" placeholder="Number of Years in Operation*" required />
                                                </div>
                                                <div className="form-group">
                                                    <input value={salesmanager} onChange={(e) => setSalesmanager(onChangeTagInput(e.target.value))} type="text" id="salesmanager" name="salesmanager" placeholder="Sales Manager Name*" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="divide_form-group">
                                            <div className="register-form_group">
                                                <div className="form-group">
                                                    <h4 className="title-input">Expertise*</h4>
                                                    <div class="radioflex">
                                                        <div class="boxradio">
                                                            <input type="checkbox" id="Expertise1" name="expertise_checkbox_group" value="Residential" />
                                                            <label for="Expertise1">Residential</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="checkbox" id="Expertise2" name="expertise_checkbox_group" value="Commercial" />
                                                            <label for="Expertise2">Commercial</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="checkbox" id="Expertise3" name="expertise_checkbox_group" value="Retail" />
                                                            <label for="Expertise3">Retail</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="checkbox" id="Expertise4" name="expertise_checkbox_group" value="Industrial Land" />
                                                            <label for="Expertise4">Industrial Land</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="checkbox" id="Expertise5" name="expertise_checkbox_group" value="Other" />
                                                            <label for="Expertise5">Other</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <input value={PANNo} onChange={(e) => setPANNo(onChangeTagInput(e.target.value))} type="text" id="PANNo" name="PANNo" placeholder="PAN No.*" />
                                                </div>
                                                <div className="form-group">
                                                    <input value={GSTNo} onChange={(e) => setGSTNo(onChangeTagInput(e.target.value))} type="text" id="GSTNo" name="GSTNo" placeholder="GST No.*" />
                                                </div>
                                                <div className="form-group">
                                                    <input value={RERANo} onChange={(e) => setRERANo(onChangeTagInput(e.target.value))} type="text" id="RERANo" name="RERANo" placeholder="RERA No.*" />
                                                </div>
                                                <div className="form-group">
                                                    <h4 className="title-input">Which of the Dynamix Group projects are you interested in?*</h4>

                                                    <div class="radioflex">
                                                        <div class="boxradio">
                                                            <input type="checkbox" id="interested1" name="checkbox-group" value="ResidDivumential" />
                                                            <label for="interested1">Residential</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="checkbox" id="interested2" name="checkbox-group" value="Parkwoods" />
                                                            <label for="interested2">Parkwoods</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="checkbox" id="interested3" name="checkbox-group" value="Aldeia de Goa" />
                                                            <label for="interested3">Aldeia de Goa</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="checkbox" id="interested4" name="checkbox-group" value="Avanya" />
                                                            <label for="interested4">Avanya</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="checkbox" id="interested5" name="checkbox-group" value="Astrum" />
                                                            <label for="interested5">Astrum</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="checkbox" id="interested6" name="checkbox-group" value="Luma" />
                                                            <label for="interested6">Luma</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <h4 className="title-input">Affiliation to any Broker Association*</h4>
                                                    <div class="radioflex">
                                                        <div class="boxradio">
                                                            <input type="radio" id="Single" name="broker_radio_groupthree" value="Single" />
                                                            <label for="Single">Single</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="radio" id="Jointly" name="broker_radio_groupthree" value="Jointly" />
                                                            <label for="Jointly">Jointly</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="radio" id="Public-ltd" name="broker_radio_groupthree" value="Public Ltd. Co." />
                                                            <label for="Public-ltd">Public Ltd. Co.</label>
                                                        </div>
                                                        <div class="boxradio">
                                                            <input type="radio" id="Anyone" name="broker_radio_groupthree" value="Anyone" />
                                                            <label for="Anyone">Anyone</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-minmb clearfix">
                                                    <div className="form-group div-50">
                                                        <input alue={associatname} onChange={(e) => setAssociatname(onChangeTagInput(e.target.value))} type="text" id="associatname" name="associatname" placeholder="Name*" />
                                                    </div>
                                                    <div className="form-group div-50">
                                                        <input value={Designation} onChange={(e) => setDesignation(onChangeTagInput(e.target.value))} type="text" id="Designation" name="Designation" placeholder="Designation*" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="doc-sec clearfix">
                                        <h4 class="title-input_bold">Documents*</h4>
                                        <div class="doc-form clearfix">
                                            <div class="flex-col">
                                                <div class="form-row">
                                                    <div class="row">
                                                        <div class="col4"><h4 class="sub-dtitle">PAN Card</h4></div>
                                                        <div class="col8">
                                                            <input type="file" id="pan_card" name="pan_card" accept="image/png, image/jpeg,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" /><br />
                                                            <span class="info-partner"><b>Allowed Type:</b> jpeg,jpg,png &nbsp;&nbsp;&nbsp;<br /> <b>File size should not exceed 2 Mb</b></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-col">
                                                <div className="form-row ">
                                                    <div className="row">
                                                        <div className="col4"><h4 className="sub-dtitle">Aadhar Card</h4></div>
                                                        <div className="col8">
                                                            <input type="file" id="adhar_card" name="adhar_card" accept="image/png, image/jpeg,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" /><br />
                                                            <span className="info-partner"><b>Allowed Type:</b> jpeg,jpg,png &nbsp;&nbsp;&nbsp;<br /> <b>File size should not exceed 2 Mb</b> </span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-col">
                                                <div className="form-row ">
                                                    <div className="row">
                                                        <div className="col4"><h4 className="sub-dtitle">Rera Certificate</h4></div>
                                                        <div className="col8">
                                                            <input type="file" id="rera_certificate" name="rera_certificate" accept="image/png, image/jpeg,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                                            <br /><span className="info-partner"><b>Allowed Type:</b> jpeg,jpg,png,pdf &nbsp;&nbsp;&nbsp;<br /> <b>File size should not exceed 2 Mb</b> </span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="agree-txt radio_group_parents">
                                        <input type="checkbox" id="tnc" name="tnc" value="True" />
                                        <label for="tnc">
                                            <span> I agree and authorise to call/ send SMS/ Promotional/ Informational messages/notifications. This will override the registry with DNC/NDNC*</span></label></div>
                                
                                
                                            <div class="btn-right"><button class="btn_primary channel_partner">Submit</button></div>

                                </form>
                            </div>
                            <div id="error" dangerouslySetInnerHTML={{__html: errorMsg}}></div>

                        </div>
                    </div>
                </div>
            </LayoutMain>


        </section>





    )
}

export default TeamDetail
