import React from 'react'
import './topmainheadinguide.scss';

const TopmainHeading = () => {
  return (


    <div className="guidedeheading">
      <div className="text-center">
        <div className="main-heading">
          <div className="psudo-box">
            <h6>Buyer's Guide</h6>
            <span className="psudo-border"></span>
          </div>
          <h1 className='new-htwo-hone'>Your Path to a Seamless Home Purchase</h1>
        </div>
        <div className="para-detail">
          <p>Investing a significant portion of your hard-earned savings and valuable time into what could potentially become<br/> your most cherished possession—your home—may understandably spark a myriad of questions and uncertainties.</p>
        </div>
      </div>
    </div>

  )
}

export default TopmainHeading
