import React from 'react';
import { motion } from 'framer-motion';
// Components
import Layout from '../../../layout/LayoutMain/LayoutMain';
// Images
import DimentionIcn from '../../../../assets/icons/tour360.png';
// Stylesheet
import './exterior.scss';
import { textAnimate } from '../../home/projects/Projects';

const Exterior = () => {
  return (
    <section className="exterior" id="View">
      <motion.div className="title_container" initial="offscreen" whileInView="onscreen">
        <motion.p variants={textAnimate}>EXTERIOR 360&deg; VIEW</motion.p>
        <motion.h2 variants={textAnimate}>Get 360&deg; view of our property</motion.h2>
      </motion.div>
      <div className="virtual_image">
        <img src={DimentionIcn} alt="3d icon" />
      </div>
    </section>
  );
};

export default Exterior;
