import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import LandmarkImg from "../../../../assets/pdp/Shrsiti-Overview.webp";
// Stylesheet
import "./landmarksrishti.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarksrishti' id="Overview">
                <div>
                    {/* <p>Overview</p> */}
                    <h2>Redefining Modern Living in the Heart of Mira Road</h2>
                    <img src={LandmarkImg} alt="landmark img" />
                </div>
                <div>
                    <h4>Step into a new era of luxurious living with Srishti Namaah, the project that has been redefining Mira Road for over three decades.
                    Experience a lifestyle like never before, where spacious 2-bedroom homes welcome abundant natural light and refreshing air. Indulge in the luxury of lavish sundecks that offer captivating views of the lush green horizon. But that's just the beginning. Srishti Namaah enhances your lifestyle even further with over 30 world-class amenities, a high-street retail zone, and every convenience you desire. It's a true sanctuary of comfort and wellness that provides a sense of seclusion while keeping the world at your feet with seamless road, rail, and metro connectivity.</h4>
                    <p>Be a part of a community that truly redefines modern living. Welcome to Srishti Namaah, where luxury meets convenience and contemporary living takes centre stage.</p>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
