import React from 'react';
import { motion } from 'framer-motion';
// Components
import Layout from '../../../layout/Layout/Layout';
import LayoutMain from '../../../layout/LayoutMain/LayoutMain';
// Stylesheet
import './valuepage.scss';
import { textAnimate } from '../../home/projects/Projects';

const ValuePage = () => {
  return (
    <section className="valuepage" id="Ourvalues">
      <LayoutMain>
        <div className="value_center">
          <motion.div className="value_title" initial="offscreen" whileInView="onscreen">
            <motion.p variants={textAnimate}>Our Values</motion.p>
            <motion.h2 variants={textAnimate}>Our Purpose and Core Values</motion.h2>
          </motion.div>
          <div className="para-detail">
            <p>
             Guiding Principles that Define Our Path" - these are the unwavering principles that steer our journey, ensuring excellence, integrity, and innovation in everything we do.
            </p>
          </div>
        </div>
      </LayoutMain>
      <Layout>
        <div className="value-number">
          <ul>
            <li>
              <span>01</span>
              <h3>Our Commitment to Exemplary Service and Satisfaction</h3>
              <p>
              We exist today because of them and the faith they repose in us. It is our duty to continually achieve the highest standards of customer service and satisfaction.
              </p>
            </li>
            <li>
              <span>02</span>
              <h3>Diligence and Mastery in Our Craft</h3>
              <p>
              We firmly believe that building faster, better, more sustainably, and more profitably can only be achieved through relentless diligence and mastery of all facets of our trade. We are dedicated to pushing the boundaries of what is possible and delivering excellence in every project we undertake.
              </p>
            </li>
            <li>
              <span>03</span>
              <h3>Foundational Values</h3>
              <p>
              Honesty, integrity, and hard work are the bedrock of our organization. These virtues are not just words on paper but ingrained in our DNA. They are at the very foundation of everything we do, guiding our actions and decisions.
              </p>
            </li>
            <li>
              <span>04</span>
              <h3>Nurturing Expression, Ideas, and Skills</h3>
              <p>
              We understand that our success is a collective effort. To achieve our stated purpose, we are committed to fostering an environment where every member of our organization has the freedom and encouragement to express themselves, pursue their ideas, and showcase their skills. We believe that innovation and progress thrive in an atmosphere of openness and collaboration.

              </p>
            </li>
          </ul>
        </div>
      </Layout>
    </section>
  );
};

export default ValuePage;
