import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarksahyadri.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarksahyadri' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>The Peak Of Living</h2>
                    <div className="paraproject">
                        <p>Marve Beach to the west and the idyllic Aarey Colony to the east – Sahyadri residential towers provide an unspoilt, refreshing view to both. What else would you rather wake up to every day in your home? This project boasts of posh 2 and 3-BHK apartments that are spacious, well-equipped, warm and accompanied by 3 levels of car parking. And when you feel like taking a dip, there is always the podium-level swimming pool to greet you.</p>
                    </div>
                </div>
                <div>
                  <ul>
                  <li><strong>Nature</strong> <span>Residential Towers (2 wings – 21 storey each)</span></li>
                    <li><strong>Location</strong> <span>Malad (East), Mumbai</span></li>
                    <li><strong>Design Architect</strong> <span>M/s IAG Consultant</span></li>
                    <li><strong>BMC Architect</strong> <span>M/s IAG Consultant</span></li>
                    <li><strong>Structural Consultant</strong> <span>M/s Mahimtura Consultant</span></li>
                    <li><strong>Contractor</strong> <span>M/s Conwood Tiles & Ceramics; M/s Rushi Housecon</span></li>
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
