import React, { useState } from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import PlusIcn from "../../../../assets/icons/plus.png";
import MinusIcn from "../../../../assets/icons/minus.png";
// Stylesheet
import "./info.scss";

import { divumfeaturesData } from "../../../../constants/featuresData";

const Info = () => {
    const [active, setActive] = useState(null);
    const toggle = (i) => {
        if (active == i) {
            return setActive(null);
        }
        setActive(i);
    };
    return (
        <section className='info' id="Neighbourhood">
            <Layout>
                <div className='info_title'>
                    <p>Neighbourhood</p>
                    <h2>Seamless access to local benefits</h2>
                </div>
                <div className='dropdown_container'>
                    {
                        divumfeaturesData.map((item, index) => (
                            <div key={index}>
                                <div className='dropdown_item'>
                                    <div className='dropdown_title_container' onClick={() => toggle(index)}>
                                    <div className='dropdown_title'>
                                     <img src={item.src} width="25px" alt="slider img" />
                                        <div classname='bullet-icon'>
                                       <img src={item.src} alt="slider img" />
                                           </div>
                                            <h3>{item.name}</h3>
                                        </div>
                                        {
                                            active == index ? <img src={MinusIcn} alt="plus" /> : <img src={PlusIcn} alt="plus" />
                                        }

                                    </div>
                                    <hr />
                                </div>
                                <ul className={active == index ? "info_content info_active" : "info_content"}>
                                    {item.links.map((lnk, indx) => (
                                        <li key={indx}>
                                            {lnk}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))
                    }
                </div>
                <div class="travel-time"><small>*Indicative Travel Time</small></div>
            </Layout>
        </section>
    )
}

export default Info;
