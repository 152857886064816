import React, { useRef } from 'react'
// Component
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';

// Images
import SliderBtn from '../../../../assets/icons/cursor-arrow.svg'

// SwiperCore.use([Keyboard, Mousewheel]);

const ExpSlider = ({ expData }) => {
  const swiperRef = useRef();
  return (
    <div className='exp_slider_container'>
      <img className='slider_btn' onClick={() => swiperRef.current.slideNext()} src={SliderBtn} alt="Slider Btn" />
      <Swiper
        spaceBetween={12}
        slidesPerView={3.5}
        // mousewheel={true}
        breakpoints={{
          '@0.00': {
            slidesPerView: 1.2,
            spaceBetween: 10,
          },
          '@0.75': {
            slidesPerView:3.4,
            spaceBetween: 12,
          },
          '@1.00': {
            slidesPerView: 3.4,
            spaceBetween: 12,
          },
          '@1.50': {
            slidesPerView: 3.4,
            spaceBetween: 12,
          },
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        modules={[Mousewheel]}
      >
        {
          expData?.map((item, indx) => (
            <SwiperSlide key={indx}>
              <div className='exp_item'>
                <img src={item.src} alt="slider img" />
                <div className='shadow'></div>
                <div className='slider_cntent'>
                  <p>{item.title}</p>
                </div>
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  )
}

export default ExpSlider
