export const loanFeaturesData = [
  {
    name: 'Can I get my House financed?',
    paraques:
      'Yes. All Dynamix Group properties offered for sale have clear titles. All Dynamix Group projects are approved by most leading banks and financial institutions for availing of home loans.',
    paradetailtwo: 'What are the documents required for availing a home loan?',
    links: [
      'Completed Application Form',
      'Passport Photograph',
      'Photo Identity Proof',
      'Residence Address Proof',
      'Signature Verification Proof',
      'Age Proof',
      'Fee Cheque',
    ]
  },
  {
    name: 'How many loans can I avail?',
    paraques:
      'You can avail a maximum loan of 80% of the Agreement value. However, your loan amount may differ as per your income eligibility as appraised by the bank. All loans are at the sole discretion of the bank.',
    paradetailtwo: 'For Salaried Applicants',
    links: [
      'Last 3 months’ Salary Slip',
      'Form 16',
      'Repayment track record of existing loans/ loan closure letter',
      'Bank Statement for the last 6 months from Salary Account',
    ]
  },
  {
    name: 'What is the term of the loan that banks offer?',
    paraques:
      'Loan tenures to a maximum of 30 years, depending on age of the primary applicant.',
    paradetailtwo: 'For Self Employed Applicants',
    links: [
      'A brief introduction to Business/Profession',
      'Photo Identity Proof, Residence Address Proof, and Signature Verification Statement for all the main partners/directors ',
      'Repayment track record of existing loans/ loan closure letter',
      'Board resolution in case of a company',
      'Proof of the existence of the company',
      'Office Address Proof',
      'Income Tax Return / Computation of Total Income / Auditors Report / Balance Sheet / Profit & Loss Account certified by Chartered Accountant for last 2 years (both for business and personal of partners/directors)',
    ]
  }
];
