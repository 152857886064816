import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarkudyog.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkudyog' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>The Prelude To Progress</h2>
                    <div className="paraproject">
                        <p>Udyog Bhavan is a quality commercial project by the Dynamix Group. This service industrial estate, complete with a basement and three floors, represents a great investment for any booming business in the city of Mumbai.</p>
                    </div>
                </div>
                <div>
                  <ul>
                  <li><strong>Nature</strong> <span>Service Industrial Estate (Basement+Gr+2 floors)</span></li>
                    <li><strong>Location</strong> <span>Goregaon (West), Mumbai</span></li>
                    <li><strong>Design Architect</strong> <span>M/s Architectural Consultants</span></li>
                    <li><strong>BMC Architect</strong> <span>M/s Architectural Consultants</span></li>
                    <li><strong>Structural Consultant</strong> <span>	M/s Voltas International Ltd.</span></li>
                    <li><strong>Contractor</strong> </li>
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
