import React from 'react'
import InvestorIcon1 from '../../../../assets/icons/clock.svg'
import InvestorIcon2 from '../../../../assets/icons/download.svg'

import Ascotcenterll1 from '../../../../assets/resource-pdf/ascotcenterll/six-monthly-compliance-monitoring-report-june-dec-2022-1.pdf';
import Ascotcenterll2 from '../../../../assets/resource-pdf/ascotcenterll/environment-clearance-certificate.pdf';
import Ascotcenterll3 from '../../../../assets/resource-pdf/ascotcenterll/E.C.-NOC-09.12.2014.pdf';

import Divum1 from '../../../../assets/resource-pdf/divum/amended_EC_divum.pdf';
import Divum2 from '../../../../assets/resource-pdf/divum/compaliance_divum.pdf';

import Parkwood1 from '../../../../assets/resource-pdf/parkwoods/compaliance_parkwood.pdf';
import Parkwood2 from '../../../../assets/resource-pdf/parkwoods/environmental-clearance-noc.pdf';


import Layout from "../../../layout/LayoutMain/LayoutMain";
import './resourcescardspage.scss';

const ResourcesCardspage = () => {
  return (

    <section className='resourcescards'>

      <div className="invest-heading">
        <div className="text-centercontent">
          <div className="main-heading">
            <div className="psudo-box">
              <h6>Resources</h6>
              <span className="psudo-border"></span>
            </div>
            <h1 className='new-htwo-hone margin-none'>Empowering Knowledge</h1>
          </div>
          <div className="para-detail mt-4">
            <p>Explore a Wealth of Information for Informed Decisions</p>
          </div>
        </div>
      </div>

      <Layout>
        <div className="investor-cards">

          <div className='cards-row'>
            <div className="left-heading">
              <h4>Ascot Center II</h4>
            </div>
          </div>

          <div className='cards-listing'>
            <ul>
              <li><a href={Ascotcenterll2} target='_blank'>
                <h6 data-hover="Unclaimed dividends (IEPF)">Environment Clearance Certificate</h6>
                <span><img src={InvestorIcon1} alt="clock" />25 May 2023 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" /></label></a>
              </li>
              <li><a href={Ascotcenterll1} target='_blank'>
                <h6>Six Monthly compliance Monitoring report</h6>
                <span><img src={InvestorIcon1} alt="clock" />Jun - Dec 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" /></label></a>
              </li>
              <li><a href={Ascotcenterll3} target='_blank'>
                <h6>E.C.-NOC</h6>
                <span><img src={InvestorIcon1} alt="clock" />09 Dec 2014 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" /></label></a>
              </li>
            </ul>
          </div>


        </div>
        <div className="investor-cards">

          <div className='cards-row'>
            <div className="left-heading">
              <h4>Divum</h4>
            </div>
          </div>

          <div className='cards-listing'>
            <ul>
              <li><a href={Divum1} target='_blank'>
                <h6>Environmental Clearance NOC</h6>
                <span><img src={InvestorIcon1} alt="clock" />31 Mar 2020 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" /></label></a>
              </li>
              <li><a href={Divum2} target='_blank'>
                <h6>Compliance Reports</h6>
                <span><img src={InvestorIcon1} alt="clock" />22 Jul 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" /></label></a>
              </li>
            </ul>
          </div>


        </div>
        <div className="investor-cards">

          <div className='cards-row'>
            <div className="left-heading">
              <h4>Parkwoods</h4>
            </div>
          </div>

          <div className='cards-listing'>
            <ul>
              <li><a href={Parkwood2} target='_blank'>
                <h6>Environmental Clearance NOC</h6>
                <span><img src={InvestorIcon1} alt="clock" />26 May 2023 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" /></label></a>
              </li>
              <li><a href={Parkwood1} target='_blank'>
                <h6>Compliance Reports</h6>
                <span><img src={InvestorIcon1} alt="clock" />16 May 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" /></label></a>
              </li>
            </ul>
          </div>


        </div>
      </Layout>

    </section>





  )
}

export default ResourcesCardspage
