import React from 'react';
import './joinfeatures.scss';
import { joinFeature } from '../../../../constants/joinFeature';

const JoinFeatures = () => {
    return (
        <section className='career_features' id='Project'>
            <div className='features_title_container'>
                <h2>Why Should You <br/> Join Us?</h2>
                <p>Unlock Your Potential in a Culture of Excellence and Innovation</p>
            </div>
            <div className='features_items'>
                {
                    joinFeature.map((item, key) => (
                        <div className='features_item' key={key}>
                           <div classname='bullet-icon'>
                            <img src={item.src} alt="slider img" />
                            </div>
                            <p>{item.name}</p>
                    </div>
                    ))
                }
            </div>
        </section>
    )
}

export default JoinFeatures
