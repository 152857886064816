import React, { useState } from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./facilitieseducation.scss";


const Facilities = () => {
    const [active, setActive] = useState(null);
    const toggle = (i) => {
        if (active == i) {
            return setActive(null);
        }
        setActive(i);
    };
    return (
        <section className='facilitieseducation' id="Location">
            <Layout>
                <div className='info_title'>
                    <h2>School Facilities</h2>
                </div>
                <div className="col_design">
                    <div className="col-loan white">
                        <h4 className="font-t2 mrg-bot20 opt-Medium">School Staff</h4>
                        <p className="font-t4 col-drk">Our teaching staff are experienced, competent, compassionate, firm and yet sensitive towards their students’ needs, and our non-teaching staff are meticulous and dedicated. They not only have sound administrative knowledge, but are also extremely caring and passionate.</p>
                    </div>
                    <div className="col-loan white">
                        <h4 className="font-t2 mrg-bot20 opt-Medium">E.C.C.E. Teacher Training Centre</h4>
                        <p className="font-t4 col-drk">In order to appoint well-trained and qualified teachers, the trust commenced its own Training Centre for pre-primary teachers under the affiliation of S.N.D.T. University from the Academic Year 2003.</p>
                    </div>
                    <div className="col-loan white">
                        <h4 className="font-t2 mrg-bot20 opt-Medium">Day Care Centre</h4>
                        <p className="font-t4 col-drk">The centre is spacious and has a well-defined area for different activities for the children between the age group of 2-12 years. It is managed by experienced professionals who pay attention to each and every student.</p>
                    </div>
                    <div className="col-loan white">
                        <h4 className="font-t2 mrg-bot20 opt-Medium">Transport</h4>
                        <p className="font-t4 col-drk">The schools have over 117 buses with well-trained staff. Cameras are fitted inside the bus for students’ safety.</p>
                    </div>
                    <div className="col-loan white">
                        <h4 className="font-t2 mrg-bot20 opt-Medium">Anvay</h4>
                        <p className="font-t4 col-drk">‘Anvay’ is an integration program for pre-schoolers with autism, developmental delay or other behavioural needs.</p>
                    </div>
                    <div className="col-loan white">
                        <h4 className="font-t2 mrg-bot20 opt-Medium">P.T.A</h4>
                        <p className="font-t4 col-drk">To encourage an active involvement of the parents in their child’s education, all our schools have P.T.A. (Parents Teachers Associations) meetings, open house, counselling sessions and seminars regularly.</p>
                    </div>
                </div>
            </Layout>
        </section>
    )
}

export default Facilities;
