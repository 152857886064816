import React, { useState } from 'react';
import './bottomNav.scss';


const BottomNav = ({pdpBottomNavData}) => {
  const [sticky, setSticky] = useState(false);

  const stickyOverviewNav = () => {
    if (window.scrollY >= 800) {
      if (!sticky) setSticky(true);
    } else {
      setSticky(false);
    }
  };

  window.addEventListener('scroll', stickyOverviewNav);
  return (
    <section className={sticky ? 'bottom_nav sticky_overview_nav' : 'bottom_nav'}>
      <ul>
        {pdpBottomNavData.map((item, index) => (
          <li key={index}>
            <a href={`#${item.src}`}>{item.title}</a>
          </li>
        ))}
      </ul>
      <hr />
    </section>
  );
};

export default BottomNav;
