import React, { useEffect, useState } from 'react';
import { config } from "../../config";
import Navigation from '../../components/common/navigation/Navigation'
import Contact from '../../components/common/contact/Contact';
import TopArticle from '../../components/common/blogsdetail/toparticle/TopArticle';
import BlogsContent from '../../components/common/blogsdetail/blogscontent/BlogsContent';
import BlogTags from '../../components/common/blogsdetail/blogtags/BlogTags';
import Footer from '../../components/common/footer/Footer'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { format } from 'date-fns'
import DocumentMeta from 'react-document-meta';






const BlogDetail = () => {
  const [blog, setBlog] = useState({});
  const params = useParams();
  const title = params.title;
  const location = useLocation();
  const meta = {
    title: blog.post_title,
    description: blog.post_meta_desc,
    meta: {
      charset: 'utf-8',
    }
  };

  const getBlog = async () => {
    try {
      const res = await axios.get(config.BLOG_API_URL + config.BLOG_SLUG + location.pathname.split('/')[2]);
      console.log(res)
      setBlog(res.data);
    } catch (err) {
      console.log(':: err blog list ', err)
    }
  };
  useEffect(() => {
    getBlog()
  }, []);

  if (!blog?.id) return null;
  console.log(blog)
  return (
    <DocumentMeta {...meta}>
      <Navigation />
      <TopArticle title={blog.post_title} date={format(new Date(blog.post_publish_date), 'dd MMM, yyyy')} />
      <BlogsContent blog={blog} />
      <BlogTags blog={blog} />
      <Contact />
      <Footer />
    </DocumentMeta>
  )
}

export default BlogDetail
