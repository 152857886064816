import React from 'react';
import { Link } from 'react-router-dom';
import AddIcn from '../../../../assets/admin/icons/plus-dark.svg';
import BackIcn from '../../../../assets/admin/icons/back-left.svg';
import './adminTitlePrimary.scss';

const AdminTitlePrimary = ({ title, btnPath, btnType }) => {
  return (
    <div className="hero_banner_title">
      <h3>{title}</h3>
      <Link to={btnPath}>
        <button>
          <img src={btnType === 'Back' ? BackIcn : AddIcn} alt="plass_icon" />
          {btnType === 'Back' ? 'Back' : 'Add New'}
        </button>
      </Link>
    </div>
  );
};

export default AdminTitlePrimary;
