import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarkchandiheights.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkchandiheights' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>Experience Elemental Harmony</h2>
                    <div className="paraproject">
                        <p>Built keeping the traditional five elements of Vedic philosophy in mind – earth, fire, water, air, and space – Lakshachandi Heights is where residents enjoy inner peace and tranquillity. The apartments are carefully designed to invoke a sense of harmony and provide the ultimate refuge from busy work lives. As for the location, Goregaon has emerged as one of the hotbeds of the city, and makes for the perfect area in Mumbai to find your dream home.</p>

                        <p>Find the balance you seek in your living space at Lakshachandi Heights, Goregaon (East).</p>
                    </div>
                </div>
                <div>
                  <ul>
                  <li><strong>Project Highlights</strong></li>
                    <li>3 towers – 21 floors</li>
                    <li>2, 3-BHK apartments </li>
                    <li>Built-up Area 2,36,000 sq. ft. </li>
                    <li>Clubhouse with swimming pool </li>
                    <li>Well planned apartments </li>
                    <li>Constructed by L&T </li>                
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
