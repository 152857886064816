import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarksheelheights.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarksheelheights' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>A Society Of Bliss</h2>
                    <div className="paraproject">
                        <p>Living in a society has never been as good as at Panchsheel Heights – 4 glorious towers of 21 storeys each featuring bright rooms, all-round ventilation, spotless furnishings, and all the amenities you can dream of. The grand clubhouse makes recreation a daily matter, while the swimming pool, jogging and cycling tracks, and the state-of-the-art gym make healthy living your new reality. </p>
                    </div>
                </div>
                <div>
                  <ul>
                  <li><strong>Nature</strong> <span>Residential (4 Towers – 21 storeys each)</span></li>
                    <li><strong>Location</strong> <span>Kandivali (West), Mumbai</span></li>
                    <li><strong>Design Architect</strong> <span>M/s Architectural Consultants</span></li>
                    <li><strong>BMC Architect</strong> <span>M/s Architectural Consultants</span></li>
                    <li><strong>Structural Consultant</strong> <span>M/s Naik Consultant</span></li>
                    <li><strong>Contractor</strong> <span>Dynamix Group</span></li>
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
