import React, { useState, useEffect } from 'react';
import { config } from "../../config";
import axios from "axios";
import Navigation from '../../components/common/navigation/Navigation'
import Contact from '../../components/common/contact/Contact';
import TopHeading from '../../components/common/bloglisting/topheading/TopHeading';
import CardListing from '../../components/common/bloglisting/cardslist/CardListing';
import Footer from '../../components/common/footer/Footer'
import { Helmet } from "react-helmet";
import { Link, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const transformBlogList = (data) => {
  return Object.keys(data).map((key) => {
    return {
      id: key,
      ...data[key]
    };
  })
}

const BlogListing = () => {
  const [blogList, setBlogList] = useState([]);
  const params = useParams();
  const location = useLocation();




  const getBlogList = async () => {
    try {
      const params = {};

      if (location.pathname.split('/')[2] != undefined) {
        params.params = { tags: location.pathname.split('/')[2] };
      }
      const res = await axios.get(config.BLOG_API_URL + "?_sort=post_timestamp&_order=DESC", params);
      const list = transformBlogList(res.data);
      setBlogList([...list]);
    } catch (err) {
      console.log(':: err blog list ', err)
    }
  };
  useEffect(() => {
    getBlogList()
  }, []);


  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.dynamixgroup.co.in/blogs" />
      </Helmet>
      <Navigation />
      <TopHeading />
      <CardListing blogList={blogList} />
      <Contact />
      <Footer />
    </>
  )
}

export default React.memo(BlogListing);
