import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import DisclaimerContent from '../../components/common/disclaimer/disclaimercontent/DisclaimerContent';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";





const Disclaimer = () => {

  const location = useLocation();
  if (location.hash) {
    setTimeout(() => {
      var anchor = document.querySelector(location.hash).getBoundingClientRect();
      window.scrollTo(anchor.left, anchor.top + window.scrollY - 60);
    }, 500);
  }

  return (
    <>
      <Helmet>
        <title>Property Buyers Guide - Dynamix Group</title>
        <meta name="description" content="Here is a guide to all your home buying queries with all necessary information and assistance to make your home buying hassle free. Click to know more!" />
      </Helmet>
      <Navigation />
      <DisclaimerContent />
      <Contact />
      <Footer />
    </>
  );
};

export default Disclaimer;
