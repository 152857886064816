import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarkprakriti.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkprakriti' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>Planned To Perfection</h2>
                    <div className="paraproject">
                        <p>The 13-storeys residential tower called Prakriti enables you to own your dream home in Gokuldham, one of the best locations in Mumbai. And it is home that everything you and your loved ones deserve: quality design, exemplary entrance hall, unique and prominent architecture, a terrace swimming pool, and more!</p>
                    </div>
                </div>
                <div>
                  <ul>
                  <li><strong>Nature</strong> <span>Residential Tower – (2 wings – 13 storeys each)</span></li>
                    <li><strong>Location</strong> <span>Goregaon (East), Mumbai</span></li>
                    <li><strong>Design Architect</strong> <span>M/s Architectural Consultants</span></li>
                    <li><strong>BMC Architect</strong> <span>M/s Architectural Consultants</span></li>
                    <li><strong>Structural Consultant</strong> <span>M/s Engineering Design Centre</span></li>
                    <li><strong>Contractor</strong> <span>M/s Rushi Housecon</span></li>
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
