import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  HomePage,
  ErrorPage,
  PdpPage,
  LumaPage,
  DivumPage,
  AvanyaPage,
  AstrumPage,
  ParkwoodsPage,
  AldeiadeGoaPage,
  SrishtiNamaahPage,
  PlpPage,
  PlpPagecompleted,
  LegacyPage,
  AboutPage,
  GalleryPage,
  GallerylistingPage,
  InvestorPage,
  BlogListing,
  BlogsDetail,
  ResourcesPage,
  Career,
  Contactus,
  Disclaimer,
  PrivacyPolicy,
  Channelpartner,
  Cpascotcentre,
  Cpboulevard,
  Cpjasminetower,
  Cplakshachandiapartments,
  Cplakshachandiheights,
  Cppachsheelgarden,
  Cppachsheelheights,
  Cpparkresidence,
  Cpprakriti,
  Cpsahyadri,
  Cpshagunmalltower,
  Cpshikharkunj,
  Cpswapnalok,
  Cpudyogbhawan,
  Cpupvantower,
  Cpwoodland,
  AdminLayout,
  AdminHome,
  AddBanner,
  BuyerGuide,
  Nri,
  EducationPage,
  AdminLogin,
  DairyPage,
  RealestatePage,
  HealthcarePage,
  CsrPage,
  RaetPage,
  AmenitiesfeaturePage
} from './pages';
const renderFavIcon = () => {
  const setFavicon = () => {
    const lightTheme = window.matchMedia('(prefers-color-scheme: light)').matches;
    const favicon = document.getElementById('favicon');
    if (favicon) {
      if (lightTheme) {
        favicon.href = '/favicon.ico';
      } else {
        favicon.href = '/favicon.ico';
      }
    }
  };

  // Set the initial favicon
  setFavicon();

  // Listen for changes in the color scheme
  window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', setFavicon);

  // Cleanup the listener when the component unmounts
  return () => {
    window.matchMedia('(prefers-color-scheme: light)').removeEventListener('change', setFavicon);
  };
}
const App = () => {
  useEffect(renderFavIcon, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/pdp" element={<PdpPage />} />
        <Route path="/current-projects" element={<PlpPage />} />
        <Route path="/completed-projects" element={<PlpPagecompleted />} />
        <Route path="/current-projects/luma" element={<LumaPage />} />
        <Route path="/current-projects/raet" element={<RaetPage />} />
        <Route path="/current-projects/101" element={<AmenitiesfeaturePage />} />
        <Route path="/current-projects/divum" element={<DivumPage />} />
        <Route path="/current-projects/avanya" element={<AvanyaPage />} />
        <Route path="/current-projects/Parkwoods" element={<ParkwoodsPage />} />
        <Route path="/current-projects/astrum" element={<AstrumPage />} />
        <Route path="/current-projects/aldeiadegoa" element={<AldeiadeGoaPage />} />
        <Route path="/current-projects/srishtinamaah" element={<SrishtiNamaahPage />} />
        <Route path="/our-legacy" element={<LegacyPage />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/gallerypage" element={<GalleryPage />} />
        <Route path="/gallerylistingpage" element={<GallerylistingPage />} />
        <Route path="/an-investors-heaven" element={<InvestorPage />} />
        <Route path="/blogs" element={<BlogListing />} />
        <Route path="/tags/:slug" element={<BlogListing />} />
        <Route path="/blogs/:title" element={<BlogsDetail />} />
        <Route path="/resources" element={<ResourcesPage />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/channel-partner" element={<Channelpartner />} />
        <Route path="/buyers-guide" element={<BuyerGuide />} />
        <Route path="/nri-desk" element={<Nri />} />
        <Route path="/education" element={<EducationPage />} />
        <Route path="/real-estate" element={<RealestatePage />} />
        <Route path="/dairy" element={<DairyPage />} />
        <Route path="/healthcare" element={<HealthcarePage />} />
        <Route path="/corporate-social-responsiblity" element={<CsrPage />} />

        <Route path="/completed-projects/ascot-centre" element={<Cpascotcentre />} />
        <Route path="/completed-projects/boulevard-15" element={<Cpboulevard />} />
        <Route path="/completed-projects/jasmine-tower" element={<Cpjasminetower />} />
        <Route path="/completed-projects/lakshachandi-apartments" element={<Cplakshachandiapartments />} />
        <Route path="/completed-projects/lakshachandi-heights" element={<Cplakshachandiheights />} />
        <Route path="/completed-projects/panchsheel-garden" element={<Cppachsheelgarden />} />
        <Route path="/completed-projects/panchsheel-heights" element={<Cppachsheelheights />} />
        <Route path="/completed-projects/the-park-residence" element={<Cpparkresidence />} />
        <Route path="/completed-projects/prakriti" element={<Cpprakriti />} />
        <Route path="/completed-projects/sahyadri" element={<Cpsahyadri />} />
        <Route path="/completed-projects/shagun-mall-tower" element={<Cpshagunmalltower />} />
        <Route path="/completed-projects/shikharkunj" element={<Cpshikharkunj />} />
        <Route path="/completed-projects/swapnalok" element={<Cpswapnalok />} />
        <Route path="/completed-projects/udyog-bhawan" element={<Cpudyogbhawan />} />
        <Route path="/completed-projects/upvan-tower" element={<Cpupvantower />} />
        <Route path="/completed-projects/woodlands" element={<Cpwoodland />} />

        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/admin" element={<AdminLayout />}>
          <Route path="" element={<AdminHome />} />
          <Route path="/admin/home/add-banner" element={<AddBanner />} />
          {/* <Route path="pdp" element={<Pdp />} /> */}
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
};

export default App;
