import React from 'react';
import ButtonPrimary from '../../components/common/button/ButtonPrimary';
import ErrorImg from '../../assets/error.svg';
import './errorPage.scss';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

const ErrorPage = () => {
  const location = useLocation();
  return (
    <section className="error_page">
      <img src={ErrorImg} alt="error_img" />
      <h2>Sorry!</h2>
      <h3>We can't seem to find the resource you're looking for.</h3>
      <p>
        Please check that the Web site address is spelled correctly. Or go to our home page, and use the menus to navigate to a specific
        section.
        
      </p>
      <p>{location.pathname}</p>
      <Link to="/">
        <ButtonPrimary text="Back to home page" />
      </Link>
    </section>
  );
};

export default ErrorPage;

