import React from 'react'




import './topheading.scss';

const TopHeading = () => {
  return (

    <section className='topcontent'>

      <div className="top-heading">
        <div className="text-centercontent">
          <div className="main-heading">
            <div className="psudo-box">
              <h6>Blogs</h6>
              <span className="psudo-border"></span>
            </div>
            <h1 className='new-htwo-hone'>Insights, Inspiration, and Innovation: Explore Our Blog</h1>
          </div>
          <div className="para-detail">
            <p>Stay Tuned To The Latest News and Blog Postings From The Dynamix Group</p>
          </div>
        </div>
      </div>
    </section>





  )
}

export default TopHeading
