import React from 'react'
import './topmainheading.scss';

const TopmainHeading = () => {
  return (


    <div className="toptextcolum">
      <div className="text-center">
        <div className="main-heading">
          <div className="psudo-box">
            <h6>Our Legacy</h6>
            <span className="psudo-border"></span>
          </div>
          <h1 className='new-htwo-hone margin-none'> Building a Timeless Legacy </h1>
        </div>
        <div className="para-detail mt-4">
          <p>Our Journey In Redefining Real Estate Excellence</p>
        </div>
      </div>
    </div>

  )
}

export default TopmainHeading
