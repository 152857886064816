import React from 'react';
import { motion } from 'framer-motion';
// Components
import ExpSlider from './ExpSlider';
// Stylesheet
import './experience.scss';

// Data
import { expData } from '../../../../constants/expData';
import { textAnimate } from '../../home/projects/Projects';

const Experience = () => {
  return (
    <section className="exp" id="Amenities">
      <motion.div className="exp_title_container" initial="offscreen" whileInView="onscreen">
        <div>
          <motion.p variants={textAnimate}>AMENITIES</motion.p>
          <motion.h2 variants={textAnimate}>Experience resort style living every day</motion.h2>
        </div>
      </motion.div>
      <ExpSlider expData={expData} />
    </section>
  );
};

export default Experience;
