import React from 'react';
import './heroparkresidence.scss';
import { motion } from 'framer-motion';


const textAnimate = {
    offscreen: { opacity: 0, y: 300 },
    onscreen: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.25 } }
};

const Hero = () => {
    return (
        <motion.section className='pdp_heroparkresidence overlay-bg'>
            <motion.div className="hero_content" initial="offscreen" whileInView="onscreen" variants={textAnimate}>
                <h1>Park Residence</h1>
                <h3 className="detail">MALAD (EAST)</h3>
            </motion.div>
        </motion.section>

    )
}

export default Hero
