import React, { useEffect, useRef } from 'react'
// Component
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';

// Images
import SliderBtn from '../../../../assets/icons/cursor-arrow.svg'
import SliderBtnleft from '../../../../assets/icons/cursor-arrowleft.svg';
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css"; 

function Fancybox(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div ref={containerRef}>{props.children}</div>;
}

// SwiperCore.use([Keyboard, Mousewheel]);

const ExpSlider = ({ expData }) => {
  const swiperRef = useRef();
  return (
    <div className='exp_slider_container'>
      <img className='slider_btn' onClick={() => swiperRef.current.slideNext()} src={SliderBtn} alt="Slider Btn" />
      <img className='slider_btn leftbtn' onClick={() => swiperRef.current.slidePrev()} src={SliderBtnleft} alt="Slider Btn" />
      <Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >
      <Swiper
        spaceBetween={12}
        slidesPerView={3.5}
        // mousewheel={true}
        breakpoints={{
          '@0.00': {
            slidesPerView: 1.2,
            spaceBetween: 10,
          },
          '@0.75': {
            slidesPerView:3.4,
            spaceBetween: 12,
          },
          '@1.00': {
            slidesPerView: 3.3,
            spaceBetween: 20,
          },
          '@1.50': {
            slidesPerView: 3.3,
            spaceBetween: 20,
          },
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        modules={[Mousewheel]}
      >
        {
          expData?.map((item, indx) => (
            <SwiperSlide key={indx}>
              <div className='exp_item'>
              <a data-fancybox="gallery" href={item.src}>
                    <img src={item.src} alt="slider img" />
                  </a>                <div className='shadow'></div>
                <div className='slider_cntent'>
                  <p>{item.title}</p>
                </div>
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
      </Fancybox>
    </div>
  )
}

export default ExpSlider
