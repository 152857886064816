import React from 'react'
import Navigation from '../../components/common/navigation/Navigation'
import ResourcesCards from '../../components/common/investor/resourcecards/ResourcesCards'
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer'
import { Link, useLocation } from 'react-router-dom';



const InvestorPage = () => {

  const location = useLocation();
  if(location.hash) {
      setTimeout(() => {
          var anchor = document.querySelector(location.hash).getBoundingClientRect();
          window.scrollTo(anchor.left, anchor.top + window.scrollY - 60);
      }, 500);
  }

  return (
    <>
      <Navigation />
      <ResourcesCards />
      <Contact />
      <Footer />
    </>
  )
}

export default InvestorPage
