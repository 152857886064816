import React from 'react';

import { motion } from 'framer-motion';
import FloorplanSlider from './FloorplanSlider';


// Stylesheet
import './floorPlan.scss';

// Data

import { textAnimate } from '../../home/projects/Projects';
import { ourFloorData } from '../../../../constants/ourFloorData';



const FloorPlan = () => {
  return (
    <section className="floor_pdp" id="Plan">
      <motion.div className="title_container" initial="offscreen" whileInView="onscreen">
        <motion.p variants={textAnimate}>FLOOR PLAN</motion.p>
        <motion.h2 variants={textAnimate}>Discover layout with our floor plans</motion.h2>
      </motion.div>
      <FloorplanSlider expData={ourFloorData} />

    </section>
  );
};

export default FloorPlan;
