import React, { useRef } from 'react';
// Videos
import TestaVideo from '../../../../assets/video/vc-two.mp4';
// Components
import Layout from '../../../layout/LayoutMain/LayoutMain';
// Stylesheet
import './testimonials.scss';

const Testimonials = () => {

  const videoRef = useRef(null);

  const playVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.play();
    }
  };

  return (
    <Layout newClass="new_layout">
      <section className="testimonial">
        <div className="testimonial_content">
          <h2>Elevating Real Estate: A Showcase of Exceptional Home Design</h2>
          {/* <button onClick={playVideo}>Play Video</button> */}

          <p>
            At Dynamix, we believe that architecture isn't just about bricks and mortar; it's about creating spaces that inspire, innovate, and elevate your lifestyle. Our commitment to exceptional design, thoughtful space planning, and architectural brilliance sets us apart.
          </p>
          <p>
            Step into a world where design meets functionality, where space is optimized for your comfort, and where architecture seamlessly blends with your aspirations. Discover how we transform empty spaces into living works of art, reflecting your unique style and personality.
          </p>
          <p>
            Dynamix Group, where every project is a masterpiece of design, space, and architecture, waiting for you to make it your own.
          </p>
        </div>
        <div className="video_container">
          <video className="testa_video" ref={videoRef} autoPlay loop muted playsInline>
            {/* <source src={TestaVideotwo} type="video/webm" /> */}
            <source src={TestaVideo} type="video/mp4" />
          </video>

          {/* <iframe src="https://www.dynamixgroup.co.in/static/media/vc-two.6e53044dab88f6cee8f3.mp4" allow="autoplay"></iframe> */}

          {/* <video playsinline="" className="home-slide-video" muted="" autoplay="" poster="//xtepindia.com/cdn/shop/files/Video-thumb-1.0_2445f0af-a661-4791-a832-04e2ca76d279.jpg?v=1699434138">
            <source src="https://cdn.shopify.com/videos/c/o/v/0d32c4c8179245b083c9657c4465a9bf.mp4" type="video/mp4" />
              <source src="https://cdn.shopify.com/videos/c/o/v/b6b42aa537d34631abf17d8c194ea549.webm" type="video/webm" />
              </video> */}


            </div>
          </section>
        </Layout>
        );
};

        export default Testimonials;
