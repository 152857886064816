import React from 'react';
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
// Stylesheet
import './scrollercontent.scss'

const ScrollerContent = () => {
    return (
        <section className='scrollsection'>
            <Layout>
                <div className='scrolltext'>
                    <div>
                        <p>OVER 32 MILLION SQ. FT. OF</p>
                        <p>QUALITY DEVELOPMENTS</p>
                        <p>DELIVERED TO COUNTLESS</p>
                        <p>HAPPY FAMILIES.</p>
                    </div>

                </div>
            </Layout>
        </section>
    )
}

export default ScrollerContent
