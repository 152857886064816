import React from 'react'




import './topheadingcareer.scss';

const TopHeadingcareer = () => {
  return (

    <section className='toparticle'>
      <div className="top-heading">
        <div className="text-centercontent">
          <div className="main-heading">
            <div className="psudo-box">
              <h6 className="new-sizecustom">Careers</h6>
              <span className="psudo-border"></span>
            </div>
            <h1 className='new-htwo-hone'>Join Our Team</h1>
          </div>
          <div class="para-detail mt-4"><p><strong>Explore the Opportunity to Be a Part of Excellence</strong>
          </p></div>
        </div>
      </div>
    </section>





  )
}

export default TopHeadingcareer
