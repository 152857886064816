import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cpascotcentre/hero/Hero';
import Landmark from '../../components/common/cpascotcentre/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const Cpascotcentre = () => {
  return (
    <>
      <Helmet>
        <title>Commercial Space in Andheri East | Commercial Complex near Mumbai Airport - Ascot Centre</title>
        <meta name="description" content="Explore Ascot Centre by Dynamix Group, a premium commercial complex in Mumbai offering state-of-the-art office spaces and modern amenities near mumbai airport. " />
        <link rel="canonical" href="https://www.dynamixgroup.co.in/completed-projects/ascot-centre" />        
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default Cpascotcentre;
