import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cpwoodland/hero/Hero';
import Landmark from '../../components/common/cpwoodland/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>Luxurious 4 BHK Apartments In Malad (E) | Flats In Malad East- Woodlands</title>
        <meta name="description" content="Luxurious 4/5 BHK flats in Malad East, Mumbai at Woodlands, one of the most remarkable residential projects with multiple amenities. Explore these premium 4 BHK apartments in Malad now!!" />
        <link rel="canonical" href="https://www.dynamixgroup.co.in/completed-projects/woodlands" />
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
