import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import TopmainHeading from '../../components/common/buyerguide/topmainheading/TopmainHeading';
import BottomNav from '../../components/common/pdp/bottomNav/BottomNav';
import FullBanner from '../../components/common/buyerguide/fullbanner/FullBanner';
import { buyerguideBottomNavData } from '../../constants/buyerguideBottomNavData';
import LoanFaq from '../../components/common/buyerguide/loanfaq/LoanFaq';
// import TaxInformation from '../../components/common/buyerguide/taxinformation/TaxInformation';
import Calculator from '../../components/common/buyerguide/calculator/Calculator';
import FrequentlyFaq from '../../components/common/buyerguide/frequentlyfaq/FrequentlyFaq';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";





const BuyerGuide = () => {

  const location = useLocation();
  if (location.hash) {
    setTimeout(() => {
      var anchor = document.querySelector(location.hash).getBoundingClientRect();
      window.scrollTo(anchor.left, anchor.top + window.scrollY - 60);
    }, 500);
  }

  return (
    <>
      <Helmet>
        <title>Property Buyers Guide - Dynamix Group</title>
        <meta name="description" content="Here is a guide to all your home buying queries with all necessary information and assistance to make your home buying hassle free. Click to know more!" />
        <link rel="canonical" href="https://www.dynamixgroup.co.in/buyers-guide" />
      </Helmet>
      <Navigation />
      <TopmainHeading />
      <BottomNav pdpBottomNavData={buyerguideBottomNavData} />
      <FullBanner />
      <LoanFaq />
      {/* <TaxInformation /> */}
      <Calculator />
      <FrequentlyFaq />
      <Contact />
      <Footer />
    </>
  );
};

export default BuyerGuide;
