import React from 'react'
// Components
import ExpSlider from './ExpSliderluma'
// Stylesheet
import './experienceluma.scss'

// Data
import { expData } from '../../../../constants/expData'

const Experience = () => {
    return (
        <section className='expluma' id='Amenities'>
            <div className='exp_title_container'>
                <div>
                    <p>AMENITIES</p>
                    <h2>THIS IS A CHANCE TO RETHINK<br />
                        YOUR IDEA OF WORK-LIFE BALANCE</h2>
                </div>
            </div>
            <ExpSlider expData={expData} />
        </section>
    )
}

export default Experience
