import React, { useRef, useEffect } from 'react';
// Components
import Layout from "../../../layout/Layout/Layout";
import LayoutMain from "../../../layout/LayoutMain/LayoutMain";
// Images
import PG1rep from "../../../../assets/gallery/PG1rep.webp";
import PG2rep from "../../../../assets/gallery/PG2rep.webp";
import PG3rep from "../../../../assets/gallery/PG3rep.webp";
import PG4rep from "../../../../assets/gallery/PG4rep.webp";
import PG5rep from "../../../../assets/gallery/PG5rep.webp";
import PG6rep from "../../../../assets/gallery/PG6rep.webp";
import PG7rep from "../../../../assets/gallery/PG7rep.webp";
import PG8rep from "../../../../assets/gallery/PG8rep.webp";
// import PG9rep from "../../../../assets/gallery/PG9rep.webp";
// import PG10rep from "../../../../assets/gallery/PG10rep.webp";
// import PG11rep from "../../../../assets/gallery/PG11rep.webp";

// Stylesheet
import "./galleryparkwood.scss";


import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Fancybox(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div ref={containerRef}>{props.children}</div>;
}



const Collage = () => {
  return (
    <section class="gallerycollapseparkwoods" id="Gallery">
            <Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >
    <>
    <div className="gallary_containerparkwoods">
      <div className="gallary_item grid_row_span_2">
      <a data-fancybox="gallery" href={PG1rep}>
            <img src={PG1rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">Elevation Side Angle</span>
            </div>
            </a>
      </div>
      <div className="gallary_item ">
      <a data-fancybox="gallery" href={PG2rep}>
            <img src={PG2rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">ELEVATION LOW ANGLE</span>
            </div>
            </a>
      </div>
      <div className="gallary_item grid_col_span_2 grid_row_span_2">
      <a data-fancybox="gallery" href={PG3rep}>
            <img src={PG3rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">Elevation</span>
            </div>
            </a>
      </div>
      <div className="gallary_item">
      <a data-fancybox="gallery" href={PG4rep}>
            <img src={PG4rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">kitchen</span>
            </div>
            </a>
      </div>
      <div className="gallary_item grid_row_span_2">
      <a data-fancybox="gallery" href={PG5rep}>
            <img src={PG5rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">living room</span>
            </div>
            </a>
      </div>
      <div className="gallary_item ">
      <a data-fancybox="gallery" href={PG6rep}>
            <img src={PG6rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">bedroom</span>
            </div>
            </a>
      </div>
      <div className="gallary_item grid_col_span_2 grid_row_span_2">
      <a data-fancybox="gallery" href={PG7rep}>
            <img src={PG7rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">master bedroom</span>
            </div>
            </a>
      </div>
      <div className="gallary_item">
      <a data-fancybox="gallery" href={PG8rep}>
            <img src={PG8rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">bathroom</span>
            </div>
            </a>
      </div>
    </div>
  </>
  </Fancybox>
  </section>
  )
}

const Gallery = () => {
  return (
    <section className="gallery" id="Gallery">
      <LayoutMain>
        <div className="gallary_title">
          <p>GALLERY</p>
          <h2>EXPLORE PARKWOODS</h2>
        </div>
      </LayoutMain>
      <Layout>

        <Collage />

        
      </Layout>
      
    </section>

  );
};


export default Gallery;
