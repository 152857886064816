import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarkupvan.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkupvan' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>A Stylish Residence For A Stylish You</h2>
                    <div className="paraproject">
                        <p>Luxury and comfort blend seamlessly at the ready-to-move Upvan Tower 2 BHK residences. Featuring modern interiors, meticulous design and magnificent amenities, Upvan Tower nurtures an urban life full of bliss and splendour.</p>
                    </div>
                </div>
                <div>
                  <ul>
                  <li><strong>Nature</strong> <span>Residential Tower (2 wings – 16 storey each)</span></li>
                    <li><strong>Location</strong> <span>Malad (East), Mumbai</span></li>
                    <li><strong>Design Architect</strong> <span>M/s IAG Consultant</span></li>
                    <li><strong>BMC Architect</strong> <span>M/s IAG Consultant</span></li>
                    <li><strong>Structural Consultant</strong> <span>M/s Mahimtura Consultant</span></li>
                    <li><strong>Contractor</strong> <span>M/s Ekesha Construction</span></li>
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
