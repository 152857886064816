import React from 'react';
import { motion } from 'framer-motion';
// Images
import DimentionIcn from '../../../../assets/icons/tour360.png';
// Stylesheet
import './virtualTour.scss';
import { textAnimate } from '../../home/projects/Projects';

const VirtualTour = () => {
  return (
    <section className="virtual_tour" id="Virtual">
      <motion.div className="title_container" initial="offscreen" whileInView="onscreen">
        <motion.p variants={textAnimate}>VIRTUAL TOUR</motion.p>
        <motion.h2 variants={textAnimate}>Explore our property easily with your screen</motion.h2>
      </motion.div>
      <div className="virtual_image">
        <img src={DimentionIcn} alt="3d icon" />
      </div>
    </section>
  );
};

export default VirtualTour;
