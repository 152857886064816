import React from 'react';

import { motion } from 'framer-motion';
import FloorplanSlider from '../../../common/pdp/floorPlan/FloorplanSlider';

//
// Stylesheet
import './floorPlangoa.scss';

// Data//

import { textAnimate } from '../../home/projects/Projects';
import { ourFloorDataraet } from '../../../../constants/ourFloorData';



const FloorPlan = () => {
  return (
    <section className="floor_designgoa" id="Plan">
      <motion.div className="title_container" initial="offscreen" whileInView="onscreen">
        <motion.p variants={textAnimate}>FLOOR PLANS</motion.p>
        <motion.h2 variants={textAnimate}>DISCOVER THE <br/>BEST LAYOUTS IN TOWN</motion.h2>
      </motion.div>
      <FloorplanSlider expData={ourFloorDataraet} />

    </section>
  );
};

export default FloorPlan;
