import React from 'react'




import './topheadingcareer.scss';

const TopHeadingcareer = () => {
  return (

    <section className='toparticle partner-channel'>
      <div className="top-heading">
        <div className="text-centercontent">
          <div className="main-heading">
            <div className="psudo-box">
              <h2>Channel Partner Registration</h2>
              <span className="psudo-border"></span>
            </div>
          </div>
          <div class="para-detail mt-4"><p>Channel Partners are an extension of our organization. Their growth results in our growth, <br /> hence we provide all the necessary support to our Channel Partners.
          </p></div>
        </div>
      </div>
    </section>





  )
}

export default TopHeadingcareer
