import React from 'react';
import './heroluma.scss';
import { motion } from 'framer-motion';


const textAnimate = {
    offscreen: { opacity: 0, y: 300 },
    onscreen: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.25 } }
};

const Hero = () => {
    return (
        <motion.section className='pdp_herolumabanner overlay-bg'>
            <motion.div className="hero_content" initial="offscreen" whileInView="onscreen" variants={textAnimate}>
                <h1 className='new-hone'>Luma</h1>
                <h2 className='new-hone-hthree'>Andheri East</h2>
                <h2 className='titlepara'>Premium 2 & 3 Bed Residences With Private Balconies in Andheri East</h2>
                {/* <h3 className="detail">Andheri East</h3>
                <p>
                Premium 2 & 3 Bed Residences With Private Balconies in Andheri East
                </p> */}
            </motion.div>
        </motion.section>

    )
}

export default Hero
