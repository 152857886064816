import React from 'react';
import './herogoa.scss';


import { color, motion } from 'framer-motion';


const textAnimate = {
    offscreen: { opacity: 0, y: 300 },
    onscreen: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.25 } }
};
//
const Hero = () => {
    return (
        <motion.section className='pdp_heroexpamenitiesbanner overlay-bg'>
            <motion.div className="hero_content" initial="offscreen" whileInView="onscreen" variants={textAnimate}>
                <h1>101 RESIDENCES</h1>
                <h3 className="detail">Bambolim, Goa</h3>
                <p> 
                Premium 2 & 3 Bed Residences with Private Balconies by the Bay
                </p>
            </motion.div>
        </motion.section>

    )
}

export default Hero
