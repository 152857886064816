import React from "react";
// Components
// Stylesheet
import "./layoutMain.scss";

const LayoutMain = ({ children, newClass }) => {
  return <div className={`layout_main ${newClass}`}>{children}</div>;
};

export default LayoutMain;
