import React from 'react';
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import DimentionIcn from '../../../../assets/bannerlegacy.png'
// Stylesheet
import './fullbanner.scss'

const FullBanner = () => {
    return (
        <section className='fullbannersection'>

            <div className='fullbanner'>
                <img src={DimentionIcn} alt="banner" />

                    <div className='bannerdetail-abs'>
                        <div>
                            <p>For over 54 years, Dynamix Group has been at the forefront of redefining the real estate landscape. Our legacy is a testament to our unwavering commitment to quality, innovation, and customer satisfaction.</p>
                            <p>From our humble beginnings, we've consistently delivered iconic projects that stand as landmarks of excellence. We've not just constructed buildings; we've crafted lifestyles and communities. Our legacy is built on a foundation of trust, transparency, and a relentless pursuit of perfection.</p>
                            <p>
                            As we look back on our journey, we take pride in the enduring relationships we've formed and the countless families who've made our creations their homes. Our legacy continues to grow as we embark on new endeavors, always striving to exceed expectations and set new benchmarks in the industry.
                            </p>
                            <p>
                            Explore our journey through the years, from our first groundbreaking project to our latest achievements. It's a legacy of innovation, integrity, and a passion for building a better future for all.
                            </p>
                        </div>
                    </div>
            </div>
        </section>
    )
}

export default FullBanner
