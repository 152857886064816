import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import GalleryCollapse from '../../components/common/gallerypage/gallerycollapse/GalleryCollapse';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';

const GalleryPage = () => {
  return (
    <>
      <Navigation />
      <GalleryCollapse />
      <Contact />
      <Footer />
    </>
  );
};

export default GalleryPage;
