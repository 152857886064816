import React from 'react';
import { motion } from 'framer-motion';
// Images
import PlayIcn from '../../../../assets/icons/play.png';
// Stylesheet
import './walkthrough.scss';
import { textAnimate } from '../../home/projects/Projects';

const Walkthrough = () => {
  return (
    <section className="walkthrough overlay-bg" id="Walkthrough">
      <motion.div className="walkthrough_container" initial="offscreen" whileInView="onscreen">
        <motion.p variants={textAnimate}>WALKTHROUGH</motion.p>
        <motion.h2 variants={textAnimate}>Experience property through video tour</motion.h2>
        <div className="mt-2">
          <img src={PlayIcn} alt="play" />
        </div>
      </motion.div>
    </section>
  );
};

export default Walkthrough;
