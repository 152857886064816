import React from 'react';
import { motion } from 'framer-motion';
import './topmainheading.scss';
import { textAnimate } from '../../home/projects/Projects';

const TopmainHeading = () => {
  return (
    <div className="listing-page">
      <div className="text-center">
        <div className="main-heading">
          <div className="psudo-box">
            <h6 className="new-sizecustom">ABOUT US</h6>
            <span className="psudo-border"></span>
          </div>
          <h1 className='new-htwo-hone' variants={textAnimate}>
          The Best Journeys Take You Home,  And In The City Of Dreams,<br/>Home Is Where Our Story Begins.
          </h1>
        </div>
        <div className="para-detail mt-4">
          <p>Discover our passion for crafting homes and creating stories that last a lifetime </p>
        </div>
      </div>
    </div>
  );
};

export default TopmainHeading;
