import React from 'react';
// import { motion } from 'framer-motion';
// Video
// import { Link } from 'react-router-dom';
import Layout from "../../../layout/Layout/Layout";


import QRparkwood1 from '../../../../assets/pdp/parkwoodqr/white-qr-1.webp';
import QRparkwood2 from '../../../../assets/pdp/parkwoodqr/white-qr-2.webp';

import Parkwoodpdf1 from '../../../../assets/resource-pdf/parkwoods/environmental-clearance-noc.pdf'
import Parkwoodpdf2 from '../../../../assets/resource-pdf/parkwoods/environmental-clearance-noc.pdf';

// Stylesheet
import './barcode.scss';



const Barcode = () => {
  return (

    <section className='barcode-sec'>   
       <Layout>
      <div className='pdp_decription'>

        <div className='bannertagline'>
          <p>MahaRERA Registration Number: P51700007024 (Parkwoods D5) & P51700053143 (Parkwoods D6). <br/> Project details are available at at <a href="https://maharera.mahaonline.gov.in" target="_blank" rel="noopener"> https://maharera.mahaonline.gov.in</a></p>
        </div>

        <div className='pdp_barcode'>
          <div className='barcode'>
            <img src={QRparkwood1} alt="plan" />
            <span>Parkwoods (D5) Scan for more information :</span>
          </div>
          <div className='barcode'>
            <img src={QRparkwood2} alt="plan" />
            <span>Parkwoods (D6) Scan for more information :</span>
          </div>
        </div>
      </div>
    </Layout>


    <Layout>
        <div className="btn-viewcenter">
          <a className="btn btn-black" target="_blank" href={Parkwoodpdf1}>Environmental Clearance NOC</a>
          <a className="btn btn-black" target="_blank" href={Parkwoodpdf2}>Compliance Reports</a>
        </div>
      </Layout>

    </section>

  );
};

export default Barcode;
