import React from 'react'
// Components
import ExpSlider from './ExpSlider'
// Stylesheet
import './experienceparkwood.scss'

// Data
import { expParkwoodData } from '../../../../constants/expData'

const Experience = () => {
    return (
        <section className='expparkwood' id='Amenities'>
            <div className='exp_title_container'>
                <div>
                <p>The trust has received donations from various philanthropic organisations & corporate bodies which helped us establish schools & institutions.</p>
                <h2>Schools and Institutions Managed by G.A.E.T.</h2>
                </div>
            </div>
            <ExpSlider expData={expParkwoodData} />
        </section>
    )
}

export default Experience
