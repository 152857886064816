import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarkshagunmall.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkshagunmall' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>An Auspicious Place For Flourishing Trade</h2>
                    <div className="paraproject">
                        <p>Shagun Mall is located in Goregoan (East) on the main film city road. Designed by the renowned, and trusted Dynamix group. It’s one of the first shopping mall of its kinds between Andheri (East) and Borivali (East). Meticulously planned, full air-conditioned and spread over the ground floor and first floor. With a total of 200 shops ranging from 60 sq.ft to 250 sq. ft of carpet area to suite your needs.</p>
                    </div>
                </div>
                <div>
                  <ul>
                  <li><strong>Project Highlights</strong> </li>
                    <li>Ample car parking </li>
                    <li>Cafeteria </li>
                    <li>Kid’s Corner </li>
                    <li>Centrally air-conditioned </li>
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
