import React from 'react'
import './currentproject.scss';

const CurrentProject = () => {
  return (
    <div className="listing-page">
      <div className="text-center">
        <div className="main-heading">
          <div className="psudo-box">
            <h6>Our Projects</h6>
            <span className="psudo-border"></span>
          </div>
          <h1 className='new-htwo-hone'>Current Projects</h1>
        </div>
        <div className="para-detail mt-4">
          <p>Building Tomorrow’s Communities Today</p>
        </div>
      </div>
    </div>
  )
}

export default CurrentProject
  