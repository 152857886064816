
import React from 'react';

import { motion } from 'framer-motion';
import FloorplanSlider from '../../pdp/floorPlan/FloorplanSlider';
import { Link } from 'react-router-dom';



// Stylesheet
import './floorPlandivum.scss';

// Data//

import { textAnimate } from '../../home/projects/Projects';
import { ourFloorDatadivum } from '../../../../constants/ourFloorData';


const FloorPlan = () => {
  return (
    <section className="floor_designdivum" id="Plan">
      <motion.div className="title_container" initial="offscreen" whileInView="onscreen">
        <motion.p variants={textAnimate}>FLOOR PLAN</motion.p>
        <motion.h2 variants={textAnimate}>DISCOVER THE<br /> BEST LAYOUTS IN TOWN</motion.h2>
      </motion.div>
      <FloorplanSlider expData={ourFloorDatadivum} />




    </section>
  );
};

export default FloorPlan;
