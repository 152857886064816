import React, { useState } from 'react'
import attachment from '../../../../assets/icons/attachment.svg'
import ButtonPrimary from '../../button/ButtonPrimary';
import LayoutMain from "../../../layout/LayoutMain/LayoutMain";

import './teamdetailnew.scss';

const TeamDetail = () => {

  const [name, setName] = useState("");
  const [tnc, setTnc] = useState(false);

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [file, setFile] = useState("");
  const [formMessage, setFormMessage] = useState("")

  let handleSubmit = async (e) => {
    e.preventDefault();
    const input = document.getElementById('fileinput');
    console.log(input)
    var data = new FormData()
    data.append('file', input.files[0])
    data.append('phone', phone)
    data.append('email', email)
    data.append('name', name)
    data.append('message', message)
    data.append('linkedin_profile_link', linkedinLink)
   

    if (!document.querySelector('#tnc').checked) {
      setFormMessage("Please accept term and conditions.");
      return false;
    }
    try {
      let res = await fetch("https://reputationindia.in/dynamixapi/api/v1/lead", {
        method: "POST",
        body: data,
      });

      setFormMessage("");
      let resJson = await res.json();
      console.log(resJson)
      if (res.status === 200) {
        e.target.reset();
        setFormMessage("Form submitted successfully");
      } else {
        setFormMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };


  return (

    <section className='careerpagenew'>

      <LayoutMain>
        <div className="contact_container">

          <div><p>Welcome to The Dynamix Group – a distinguished conglomerate at the forefront of India's real estate landscape. With an illustrious legacy spanning 54 years, we are renowned for our meticulously designed residential and commercial properties that grace the vibrant locales of Mumbai, Thane, and Goa.</p><br />
            <p>
              Are you a passionate and innovative individual seeking to make your mark in a dynamic industry? We invite you to embark on a journey with us, where your talent and creativity will find a nurturing home. Our team is composed of dedicated professionals who thrive in an environment that values diversity, fosters inclusivity, encourages collaboration, upholds professionalism, and infuses a touch of fun into every endeavor.</p><br />
            <p>
              Being a part of The Dynamix Group means becoming an integral thread in the rich tapestry of our legacy. As one of India's leading real estate developers, we offer you the chance to contribute to projects that define urban excellence and shape communities for generations to come.</p><br />
            <p>
              If the prospect of joining a team that exemplifies a half-century heritage and pioneers innovation in the real estate arena resonates with you, then The Dynamix Group is where your aspirations come to life.</p><br />
            <p>
              Join us today and become a driving force in crafting a future that mirrors your passion, dedication, and ambition.</p>
          </div>

          <div class="bg-about">
            <div class="title_container">
              <h2>About you</h2>
              <p>Your privacy is important to us. The information collected in this section will be treated with the highest level of confidentiality and will only be accessed by authorized personnel involved in the recruitment process</p></div>
            <div className="input_container">
              <form onSubmit={handleSubmit} enctype='multipart/form-data'>
                <div className="input_wrapper">
                  <div>
                    <input required type="text" name="name" placeholder="Name" onChange={(e) => setName(e.target.value)} />
                  </div>
                  <div>
                    <input required type="email" name="email" placeholder="Email id" onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div>
                    <input required pattern="[789][0-9]{9}" type="text" name='phone' placeholder="Phone Number" onChange={(e) => setPhone(e.target.value)} />
                  </div>
                  <div>
                    <input required type="url" name='linkedin_profile_link' placeholder="Linkedin profile link" onChange={(e) => setLinkedinLink(e.target.value)} />
                  </div>
                  <div>
                    <input  type="text" name="message" placeholder="Message" onChange={(e) => setMessage(e.target.value)} />
                  </div>
                </div>


           

                <div className="flexrow">
                  <div className="authorized">
                    <input  required type="file" id="fileinput" name='doc' onChange={(e) => setFile(e.target.value) } />
                    {/* <span><img src={attachment} /></span>
                    <span>Please upload 1 relevant document (max file size 10MB)</span> */}
                  </div>
                </div>

                <div className="agree-txt radio_group_parents">
                  <input type="checkbox" id="tnc" name="tnc" onChange={(e) => setTnc(e.target.value)} />
                  <label for="tnc">
                    <span> I agree and authorise to call/ send SMS/ Promotional/ Informational messages/notifications. This will override the registry with DNC/NDNC</span></label>
                </div>

                <div className='btn-right'>
                    {/* <ButtonPrimary type="submit" text="Submit" /> */}
                  <button class="btn_primary blackbtn">Submit</button>
                  </div>

              </form>
            </div>
            {formMessage}
          </div>
        </div>
      </LayoutMain>


    </section>





  )
}

export default TeamDetail
