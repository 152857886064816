import React from 'react'
import { Link } from 'react-router-dom';
import './projectone.scss';
import { listingDatacompleted } from '../../../../constants/listingData';
const Projectone = () => {
  console.log(listingDatacompleted)
  return (
    <div class="listing-section text-center">
      {
        listingDatacompleted.map((item, index) => (
          <Link to={`${item.link}`} onClick={() => window.scrollTo(0, 0)}>
            <section class="section relative-box" style={{ backgroundImage: `url(${item.src})` }}>
              <div class="flex-box">
                <div className='mid-sec'>
                  <span>{item.num}</span>
                  <div class="loader-one"></div>
                </div>
                <div>
                  <h2 class="title-center">{item.name}</h2>
                  <h5>View Details <small>+</small></h5>
                </div>
              </div>
              <div class="bottom-tagline">
                <div class="psudo-box">
                  <h6>{item.city}</h6>
                </div>
                <h3>{item.destination}</h3>
              </div>
            </section>
          </Link>
        ))
      }
    </div>
  )
}

export default Projectone

