import React from 'react';
// Components
import CalcContainer from './CalcContainer';
// Stylesheet
import './calculator.scss';

const Calculator = () => {
  return (
    <section className="emi_calculator" id="EMICALCULATOR">
      <div className="loancheck">
        <div className="text_center">
          <div className="main_heading">
            <h2>EMI Calculator</h2>
          </div>
          <div className="para_detail">

          </div>
        </div>
      </div>
      <CalcContainer />
    </section>
  );
};

export default Calculator;
