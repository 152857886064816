import React from "react";
// Components
// Stylesheet
import "./layout.scss";

const Layout = ({ children }) => {
  return <div className="layout">{children}</div>;
};

export default Layout;
