import React, { useRef, useEffect } from 'react';
// Components
import Layout from "../../../layout/Layout/Layout";
import LayoutMain from "../../../layout/LayoutMain/LayoutMain";
// Images
import AsG1rep from "../../../../assets/gallery/AsG1rep.webp";
import AsG2rep from "../../../../assets/gallery/AsG2rep.webp";
import AsG3rep from "../../../../assets/gallery/AsG3rep.webp";
import AsG4rep from "../../../../assets/gallery/AsG4rep.webp";
import AsG5rep from "../../../../assets/gallery/AsG5rep.webp";
import AsG6rep from "../../../../assets/gallery/AsG6rep.webp";
import AsG7rep from "../../../../assets/gallery/AsG7rep.webp";
import AsG8rep from "../../../../assets/gallery/AsG8rep.webp";
// import AsG9rep from "../../../../assets/gallery/AsG9rep.webp";
// import AsG10rep from "../../../../assets/gallery/AsG10rep.webp";



// Stylesheet
import "./galleryastrum.scss";

import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Fancybox(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div ref={containerRef}>{props.children}</div>;
}




const Collage = () => {
  return (


    <section className="gallerycollapseastrum">
    <Fancybox
      options={{
        Carousel: {
          infinite: false,
        },
      }}
    >


    <>
      <div className="gallary_containerastrum">
        <div className="gallary_item grid_row_span_2">
        <a data-fancybox="gallery" href={AsG1rep}>
            <img src={AsG1rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">ELEVATION SIDE ANGLE</span>
            </div>
            </a>
        </div>
        <div className="gallary_item ">
        <a data-fancybox="gallery" href={AsG2rep}>
            <img src={AsG2rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">ELEVATION FRONT VIEW</span>
            </div>
            </a>
        </div>
        <div className="gallary_item grid_col_span_2 grid_row_span_2">
        <a data-fancybox="gallery" href={AsG3rep}>
            <img src={AsG3rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">ELEVATION SIDE ANGLE</span>
            </div>
            </a>
        </div>
        <div className="gallary_item">
        <a data-fancybox="gallery" href={AsG4rep}>
            <img src={AsG4rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">ELEVATION SIDE ANGLE</span>
            </div>
            </a>
        </div>
        <div className="gallary_item grid_row_span_2">
        <a data-fancybox="gallery" href={AsG5rep}>
            <img src={AsG5rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">ELEVATION FRONT VIEW</span>
            </div>
            </a>
        </div>
        <div className="gallary_item">
        <a data-fancybox="gallery" href={AsG6rep}>
            <img src={AsG6rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">LIVING ROOM</span>
            </div>
            </a>
        </div>
        <div className="gallary_item grid_col_span_2 grid_row_span_2">
        <a data-fancybox="gallery" href={AsG7rep}>
            <img src={AsG7rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">KITCHEN</span>
            </div>
            </a>
        </div>
        <div className="gallary_item">
        <a data-fancybox="gallery" href={AsG8rep}>
            <img src={AsG8rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">MASTER BEDROOM</span>
            </div>
            </a>
        </div>
      </div>
    </>
    </Fancybox>
    </section>
  )
}

const Gallery = () => {
  return (
    <section className="gallery" id="Gallery">
      <LayoutMain>
        <div className="gallary_title">
          <p>GALLERY</p>
          <h2>EXPLORE  ASTRUM</h2>
        </div>
      </LayoutMain>
      <Layout>
        {/* Single collage */}
        <Collage />
        {/* Single collage */}

      </Layout>
    </section>

  );
};


export default Gallery;
