import React from 'react';
import './hero.scss';

const Hero = () => {
    return (
        <section className='pdp_hero overlay-bg'>
            <div className='pdp_decription'>
                <p>Project is Funded by and Mortgaged with Tata Capital Housing Finance Limited.</p>
                <p>MahaRERA Registration No. P51800018784.</p>
            </div>
        </section>
    )
}

export default Hero
