import React from 'react'
import Navigation from '../../components/common/navigation/Navigation'
import TopHeadingcareer from '../../components/common/career/topheadingcareer/TopHeadingcareer';
import JoincareerSlider from '../../components/common/career/joinslider/Joinslider';
import JoinFeatures from '../../components/common/career/joinfeatures/JoinFeatures';
import TeamDetail from '../../components/common/career/teamdetail/TeamDetail';
import Footer from '../../components/common/footer/Footer'
import { Helmet } from "react-helmet";





const Career = () => {
  return (
    <>
      <Helmet>
        <title>Career Opportunities at Dynamix Group</title>
        <meta name="description" content="Explore exciting career opportunities for professional growth at Dynamix Group. Visit now!" />
        <link rel="canonical" href="https://www.dynamixgroup.co.in/careers" />        
      </Helmet>
      <Navigation />
      <TopHeadingcareer />
      <JoincareerSlider />
      <JoinFeatures />
      <TeamDetail />
      <Footer />
    </>
  )
}

export default Career
