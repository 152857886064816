import React, { useState } from 'react';
import './SocialShareDropdown.scss';
const SocialShareDropdown = () => {
    const handleShare = (platform) => {
        let url = window.location.href;
        let shareUrl = '';
        switch (platform) {
            case 'facebook':
                shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
                break;
            case 'twitter':
                shareUrl = `https://twitter.com/share?url=${url}`;
                break;
            case 'linkedin':
                shareUrl = `https://www.linkedin.com/shareArticle?url=${url}`;
                break;
            default:
                break;
        }
        window.open(shareUrl, '_blank');
    };


    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <div className="container mt-5">

            <div className="btn-group">
                <button type="button" className="btn btn-outline-primary"><i className="fa fa-share-alt"></i></button>
                <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="sr-only">Toggle Dropdown</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-social">
                    <a className="dropdown-item social-facebook mb-1 rounded" href="#"><i className="fab fa-facebook-f"></i></a>
                    <a className="dropdown-item social-twitter mb-1 rounded" href="#"><i className="fab fa-twitter"></i></a>
                    <a className="dropdown-item social-youtube mb-1 rounded" href="#"><i className="fab fa-youtube"></i></a>

                    <a className="dropdown-item social-instagram rounded" href="#"><i className="fab fa-instagram"></i></a>
                </div>
            </div>
        </div>
    );
};

export default SocialShareDropdown;
