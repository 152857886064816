import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarkjasminetower.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkjasminetower' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>The Upper Echelons  Of Luxury Living</h2>
                    <div className="paraproject">
                        <p>True to the name, Jasmine Towers 2 BHK residential apartments evoke an image of bliss and peaceful living that is well separated from the chaotic pace of life. The lush green surroundings are emphasised with open spaces, wide and well-connected roads, and reinvigorating nature.</p>

                        <p>As part of the highly sought after Vasant Vihar township, Jasmine Towers has access to world class social infrastructure. Come, discover peaceful living with your family at Jasmine Tower, Vasant Vihar, Thane.</p>
                    </div>
                </div>
                <div>
                  <ul>
                  <li><strong>Project Highlights</strong> </li>
                    <li><span>2 wings – 23 floors</span></li>
                    <li> <span>Open green spaces</span></li>
                    <li><span>Clubhouse with swimming pool</span></li>

                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
