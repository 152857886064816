import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import GalleryRow from '../../components/common/gallerylistingpage/galleryrow/GalleryRow';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';

const GallerylistingPage = () => {
  return (
    <>
      <Navigation />
      <GalleryRow />
      <Contact />
      <Footer />
    </>
  );
};

export default GallerylistingPage;
