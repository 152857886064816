import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './switch.scss';

const spring = {
  type: 'spring',
  stiffness: 700,
  damping: 30
};

const Switch = () => {
  const [isOn, setIsOn] = useState(false);
  const toggle = () => setIsOn(!isOn);
  return (
    <div className="switch" data-isOn={isOn} onClick={toggle}>
      <motion.div className="handle" layout transition={spring}></motion.div>
    </div>
  );
};

export default Switch;
