import React from 'react';
import HeroBannerListing from '../../../components/common/admin/adminHome/heroBannerListing/HeroBannerListing';
import AboutSection from '../../../components/common/admin/adminHome/aboutSection/AboutSection';
import VirtualTourSection from '../../../components/common/admin/adminHome/virtualTourSection/VrtualTourSection';

const AdminHome = () => {
  return (
    <>
      <HeroBannerListing />
      <AboutSection />
      <VirtualTourSection />
    </>
  );
};

export default AdminHome;
