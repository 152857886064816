import React from 'react';
// Images
import Layout from "../../../layout/LayoutMain/LayoutMain";

// Stylesheet
import './principle.scss'

const Principle = () => {
    return (
        <section className='principle' id='cSR'>
            <Layout>
                <div className='principle-box'>
                    <div className='title_container'>
                        <h2>What we do is the reflection of our principles.</h2>
                    </div>
                    <p>At our company, our actions are a direct reflection of our unwavering principles and values. We have built our foundation on a strong belief in making a positive impact on society. Whether it is through our high-impact corporate social responsibility initiatives or our commitment to developing top-quality projects for all income groups, we are dedicated to enhancing people's lives by creating a better-built environment.</p>
                    <a href='/corporate-social-responsiblity'><button className="btn_primary">Read More</button></a>
                </div>
            </Layout>
        </section>
    )
}

export default Principle
