import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cppachsheelgarden/hero/Hero';
import Landmark from '../../components/common/cppachsheelgarden/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>Residential Apartments In Kandivali West | Flats in Kandivali West | Panchsheel Garden</title>
        <meta name="description" content="Looking for Residential Apartments in Kandivali West, Mumbai? We offer premium flats in Kandivali with modern amenities. Explore Panchsheel Garden In Kandivali by Dynamix Group for luxury flats now!" />
        <link rel="canonical" href="https://www.dynamixgroup.co.in/completed-projects/pachsheel-garden" />        
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
