import React from 'react';
import LogoDark from '../../assets/logo-light-new.svg'
import './adminLogin.scss';
import { Link } from 'react-router-dom';

const AdminLogin = () => {
  return (
    <section className="admin_login">
      <div className='admin_login_container'>
        <img src={LogoDark} alt="logo_light" />
        <div className='admin_login_wrapper'>
          <h2>Admin Panel</h2>
          <p>Please login to the account</p>
          <form>
            <input type="text" placeholder="Username" />
            <input type="text" placeholder="Password" />
            <button className="btn_primary">Submit</button>
          </form>
          <Link to="#">Forgot Password ?</Link>
        </div>
      </div>
    </section>
  );
};

export default AdminLogin;
