//current
import time70 from "../assets/timeline/1970.png"
import time75 from "../assets/timeline/1975.webp"
import time79 from "../assets/timeline/1979.webp"
import time83 from "../assets/timeline/1983.webp"
import time85 from "../assets/timeline/1985.webp"
import time87 from "../assets/timeline/1987.webp"
import time89 from "../assets/timeline/1989.webp"
import time94 from "../assets/timeline/1994.webp"
import time99 from "../assets/timeline/1999.webp"
import time00 from "../assets/timeline/2000.webp"
import time01 from "../assets/timeline/2001.webp"
import time03 from "../assets/timeline/2003.webp"
import time07 from "../assets/timeline/2007.webp"
import time08 from "../assets/timeline/2008.webp"
import time10 from "../assets/timeline/2010.webp"
import time11 from "../assets/timeline/2011.webp"
import time13 from "../assets/timeline/2013.webp"
import time19 from "../assets/timeline/2019.webp"
import time20 from "../assets/timeline/2020.webp"
import time21 from "../assets/timeline/2021.webp"
import time23 from "../assets/timeline/2023.webp"
import time22 from "../assets/timeline/2022.webp"
import timeline101 from "../assets/timeline/101-timeline.webp"
import raet from "../assets/timeline/raet-timeline.webp"





export const timeLineData = [
    {
        src: time70,
        year: "1970",
        para: "The Dynamix Group was established by KM Goenka."
    },
    {
        src: time75,
        year: "1975",
        para: "The journey began with the launch of our first project, Bhagwati Apartment in Malad West."
    },
    {
        src: time79,
        year: "1979",
        para: "We embarked on the creation of Gokhuldham Township in Goregaon East, laying the foundation for a thriving community."
    },
    {
        src: time83,
        year: "1983",
        para: "Yashodham Township in Goregaon East came into being, symbolizing our commitment to quality living spaces."
    },
    {
        src: time85,
        year: "1985",
        para: "The inception of Srishti Township in Mira Road East marked another milestone in our journey."
    },
    {
        src: time87,
        year: "1987",
        para: "Vasant Vihar Township in Thane West started taking shape, which is still considered one of the sought-after residential localities in Thane."
    },
    {
        src: time89,
        year: "1989",
        para: "Upper Govind Nagar Township in Malad East was launched, providing a haven for families."
    },
    {
        src: time94,
        year: "1994",
        para: "Mahavir Nagar Township in Kandivali West came to life, showcasing our dedication to excellence."
    },
    {
        src: time99,
        year: "1999",
        para: "Udhyog Bhavan, a hallmark commercial project, was unveiled in Goregaon West."
    },
    {
        src: time00,
        year: "2000",
        para: "Ascot Centre, a prominent commercial development in Andheri East, was introduced. Additionally, Jasmine Tower, a remarkable project in Vasant Vihar Thane, was launched."
    },
    {
        src: time01,
        year: "2001",
        para: "Shagun  Tower made its debut, offering premium apartments and a bustling shopping mall with 200 shops."
    },
    {
        src: time03,
        year: "2003",
        para: "Aledia de Goa Phase 1, featuring seaside plots, was launched in Bambolim, Goa"
    },
    {
        src: time07,
        year: "2007",
        para: "Vida de Aldeia, Goa’s grandest seaside apartments at Aldeia de Goa were unveiled, embodying luxury living."
    },
    {
        src: time08,
        year: "2008",
        para: "Parkwoods Phase 1 was introduced on Ghodbunder Road, setting new standards in residential development."
    },
    {
        src: time10,
        year: "2010",
        para: "The construction of DMart on Ghodbunder Road commenced, bringing convenience to the Neighbourhood."
    },
    {
        src: time11,
        year: "2011",
        para: "Woodlands, featuring premium 4 & 5-bedroom residences, was unveiled, redefining luxury living."
    },
    {
        src: time13,
        year: "2013",
        para: "Frangipani Villas at Aldeia de Goa was launched, offering palatial villas with international standard club house."
    },
    {
        src: time19,
        year: "2019",
        para: "Parkwoods Phase 2 was introduced on Ghodbunder Road, further enhancing the community."
    },
    {
        src: time20,
        year: "2020",
        para: "Avanya Tower A & B were unveiled in Dahisar East, garnering a tremendous response."
    },
    {
        src: time21,
        year: "2021",
        para: "Astrum, a premium project with super-premium 2 & 3-bedroom residences, was launched in Upper Govind Nagar, Malad East."
    },
    {
        src: time22,
        year: "2022",
        para: "Avanya Tower C was introduced in Dahisar East, expanding our commitment to quality living."
    },
    {
        src: time23,
        year: "2023",
        para: "Luma in Andheri East, close to the International Airport, was launched, offering premium 2 & 3-bedroom residences."
    },
    {
        src: timeline101,
        year: "2024",
        para: "101 Residences has been launched in Bambolim, Goa, featuring premium 2 and 3-bed residences with private balconies overlooking the bay."
    },
    {
        src: raet,
        year: "2024",
        para: "Raet has been launched, featuring ultra-luxury 4 bedroom and penthouse residences with private wraparound balconies in Goa."
    },

]
