import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

// Component
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
// Images
// import SliderBtn from '../../../../assets/icons/slider-arrow.png';

const ProjectSlider = ({ projectData }) => {
  const swiperRef = useRef();

  return (
    <div className="project_slider">
      <div className="slider_btn slider_btn_left" onClick={() => swiperRef.current.slidePrev()} />
      <div className="slider_btn slider_btn_right" onClick={() => swiperRef.current.slideNext()} />
      <Swiper
        spaceBetween={12}
        slidesPerView={2.1}
        // mousewheel={true}
        loop={true}
        breakpoints={{
          '@0.00': {
            slidesPerView: 1,
            spaceBetween: 10
          },
          '@0.75': {
            slidesPerView: 2,
            spaceBetween: 12
          },
          '@1.00': {
            slidesPerView: 2.1,
            spaceBetween: 12
          },
          '@1.50': {
            slidesPerView: 2.1,
            spaceBetween: 12
          }
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        modules={[Mousewheel]}
      >
        {projectData?.map((item, indx) => (

          <SwiperSlide key={indx}>
            <div className="project_item">
              <div className="project_item_img_con">
                <img src={item.src} alt="slider img" />
                
                <div className="shadow"></div>
              </div>
              <div className="slider_cntent">
                <p>{item.place}</p>
                <h2>{item.title}</h2>
                <Link to={`${item.link}`} onClick={() => window.scrollTo(0, 0)}>

                <button className="btn_primary">Learn More</button>
                </Link>

              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProjectSlider;
