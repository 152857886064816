import './adminTitlePrimary.scss';

 const AdminTitle = ({ text }) => {
  return (
    <div className="hero_banner_title">
      <h3>{text}</h3>
    </div>
  );
};

export default AdminTitle;
