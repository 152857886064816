import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/parkwoods/hero/Hero';
import BottomNav from '../../components/common/parkwoods/bottomNav/BottomNav';
import Landmark from '../../components/common/parkwoods/landmark/Landmark';
import Info from '../../components/common/parkwoods/info/Info';
import Experience from '../../components/common/parkwoods/experience/Experience';
// import Features from '../../components/common/parkwoods/features/Features';
import Gallery from '../../components/common/parkwoods/gallery/Gallery';
import Walkthrough from '../../components/common/parkwoods/walkthrough/Walkthrough';
import VirtualTour from '../../components/common/parkwoods/virtualTour/VirtualTour';
import Exterior from '../../components/common/parkwoods/exterior/Exterior';
import FloorPlan from '../../components/common/parkwoods/floorPlan/FloorPlan';
import Barcode from '../../components/common/parkwoods/barcode/Barcode';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { pdpBottomNavData } from '../../constants/pdpBottomNavData';
import { Helmet } from "react-helmet";



const ParkwoodsPage = () => {
  return (
    <>
      <Helmet>
        <title>2 BHK Flats In Ghodbunder Road Thane (W), 2 BHK in Thane | Parkwoods - Dynamix Group</title>
        <meta name="description" content="Looking for a luxurious 2 BHK flat in Thane? Parkwoods by Dynamix Group offers luxurious 2 BHK flats in Ghodbunder Road, Thane West with the best modern living amenities." />
        <link rel="canonical" href="https://www.dynamixgroup.co.in/current-projects/parkwoods" />
      </Helmet>
      <Navigation />
      <Hero />
      <BottomNav pdpBottomNavData={pdpBottomNavData} />
      <Landmark />
      <Info />
      <Experience />
      {/* <Features /> */}
      <Gallery />
      <Walkthrough />
      <VirtualTour />
      <Exterior />
      <FloorPlan />
      <Barcode />
      <Contact />
      <Footer />
    </>
  );
};

export default ParkwoodsPage;
