import React from 'react';
import Layout from "../../../layout/Layout/Layout";

// Stylesheet
import './virtualTouravanya.scss'

const VirtualTour = () => {
  return (
    <Layout>
    <section className='virtual_touravanya' id='Virtual'>
      <div className='title_container'>
        <p>VIRTUAL TOUR</p>
        <h2>EXPLORE AN APARTMENT AT AVANYA</h2>
        
      
      </div>
      <iframe key="virtual-tour-1" width="100%" height="500" src="https://www.dynamixgroup.co.in/virtual-tour-avanya/aerial/day/"
        frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>

    </section>
    </Layout>
  )
}

export default VirtualTour
