import React from "react";
import { motion } from 'framer-motion';
// Components
import Layout from "../../../layout/Layout/Layout";
import LayoutMain from "../../../layout/LayoutMain/LayoutMain";
// Images
import Img1 from "../../../../assets/gallery/img1.png";
import Img2 from "../../../../assets/gallery/img2.png";
import Img3 from "../../../../assets/gallery/img3.png";
import Img4 from "../../../../assets/gallery/img4.png";
// Stylesheet
import "./gallery.scss";
import { textAnimate } from "../../home/projects/Projects";

const Collage = () => {
  return (
    <div className="gallary_container">
      <div className="gallary_item grid_row_span_2">
        <img src={Img1} alt="gallery img" />
      </div>
      <div className="gallary_item ">
        <img src={Img3} alt="gallery img" />
      </div>
      <div className="gallary_item grid_col_span_2 grid_row_span_2">
        <img src={Img4} alt="gallery img" />
      </div>
      <div className="gallary_item">
        <img src={Img2} alt="gallery img" />
      </div>
    </div>
  )
}

const Gallery = () => {
  return (
    <section className="gallery" id="Gallery">
      <LayoutMain>
        <motion.div className="gallary_title" initial="offscreen" whileInView="onscreen">
          <motion.p variants={textAnimate}>GALLERY</motion.p>
          <motion.h2 className="title_underline" variants={textAnimate}>Explore a glimpse of our property</motion.h2>
        </motion.div>
      </LayoutMain>
      <Layout>
        {/* Single collage */}
        <Collage />
        {/* Single collage */}
        <Collage />
      </Layout>
    </section>

  );
};


export default Gallery;
