import astrumbanner from "../assets/project-listing/astrumbanner.webp";
import avanyabanner from "../assets/project-listing/avanyabanner.webp";
import divumbanner from "../assets/project-listing/divumbanner.webp";
import aldeGoa from "../assets/project-listing/Goanew.webp";
import parkwoodsbanner from "../assets/project-listing/parkwoodsbanner.webp";
import Srishti from "../assets/project-listing/Srishti.webp";
import Luma from "../assets/project-listing/Luma.webp"
//
import woodlandsbanner from '../assets/project/woodlandsbanner.webp'
import parkresidencebanner from '../assets/project/parkresidencebanner.webp'
import boulevardbanner from '../assets/project/boulevardbanner.webp'
import lakshachandiheightsbanner from '../assets/project/lakshachandiheightsbanner.webp'
import lakshchandiaptsbanner from '../assets/project/lakshchandiaptsbanner.webp'
import jasminetowerbanner from '../assets/project/jasminetowerbanner.webp'
import shagunbanner from '../assets/project/shagunbanner.webp'
import sahyadribanner from '../assets/project/sahyadribanner.webp'
import prakritibanner from '../assets/project/prakritibanner.webp'
import panchsheelheightsbanner from '../assets/project/panchsheelheightsbanner.webp'
import panchsheelgardenbanner from '../assets/project/panchsheelgardenbanner.webp'
import ascotcentrebanner from '../assets/project/ascotcentrebanner.webp'
import upvantowerbanner from '../assets/project/upvantowerbanner.webp'
import udyogbhavanbanner from '../assets/project/udyogbhavanbanner.webp'
import swapnalokbanner from '../assets/project/swapnalokbanner.webp'
import shikharkunjbanner from '../assets/project/shikharkunjbanner.webp'
import raet from "../assets/project-listing/raet.webp";
import residents from "../assets/project-listing/residents.webp";


//
export const listingDatacompleted = [
  {
      link: "/completed-projects/woodlands",
      src: woodlandsbanner,
      num: "01",
      name: "Woodlands",
      city: "Mumbai",
      destination: "Malad East",


  },
  {
    link: "/completed-projects/the-park-residence",
    src: parkresidencebanner,
    num: "02",
    name: "Park Residence",
    city: "Mumbai",
    destination: "Malad East",


  },
  {
    link: "/completed-projects/boulevard-15",
    src: boulevardbanner,
    num: "03",
    name: "Boulevard 15",
    city: "Mumbai",
    destination: "Malad East",


  },
  
  {
    link: "/completed-projects/lakshachandi-heights",
    src: lakshachandiheightsbanner,
    num: "04",
    name: "Lakshachandi Heights",
    city: "Mumbai",
    destination: "Goregaon East",


  },
  {
    link: "/completed-projects/lakshachandi-apartments",
    src: lakshchandiaptsbanner,
    num: "05",
    name: "Lakshachandi Apartments",
    city: "Mumbai",
    destination: "Goregaon East",


  },
  {
    link: "/completed-projects/jasmine-tower",
    src: jasminetowerbanner,
    num: "06",
    name: "Jasmine Tower",
    city: "Mumbai",
    destination: "Vasant vihar, Thane",
},
  {
    link: "/completed-projects/shagun-mall-tower",
    src: shagunbanner,
    num: "07",
    name: "Shagun Tower",
    city: "Mumbai",
    destination: "Malad East",


  },
  {
    link: "/completed-projects/sahyadri",
    src: sahyadribanner,
    num: "08",
    name: "Sahyadri",
    city: "Mumbai",
    destination: "Malad East",


  },
  {
    link: "/completed-projects/prakriti",
    src: prakritibanner,
    num: "09",
    name: "Prakriti",
    city: "Mumbai",
    destination: "Goregaon East",


  },
  {                            
    link: "/completed-projects/panchsheel-heights",
    src: panchsheelheightsbanner,
    num: "10",
    name: "Panchsheel Heights",
    city: "Mumbai",
    destination: "Kandivali West",


  },
  {
    link: "/completed-projects/pachsheel-garden",
    src: panchsheelgardenbanner,
    num: "11",
    name: "Panchsheel Garden",
    city: "Mumbai",  
    destination: "Kandivali West",


  },
  
  {
    link: "/completed-projects/ascot-centre",
    src: ascotcentrebanner,
    num: "12",
    name: "Ascot Centre",
    city: "Mumbai",  
    destination: "Andheri East",

  },
  {
    link: "/completed-projects/upvan-tower",
    src: upvantowerbanner,
    num: "13",
    name: "Upvan Tower",
    city: "Mumbai",  
    destination: "Malad East",


  },
  {
    link: "/completed-projects/udyog-bhawan",
    src: udyogbhavanbanner,
    num: "14",
    name: "Udyog Bhawan",
    city: "Mumbai",  
    destination: "Goregaon West",


  },
  {
    link: "/completed-projects/swapnalok",
    src: swapnalokbanner,
    num: "15",
    name: "Swapnalok",
    city: "Mumbai",  
    destination: "Malad East",


  },
  {
    link: "/completed-projects/shikharkunj",
    src: shikharkunjbanner,
    num: "16",
    name: "Shikharkunj",
    city: "Mumbai",  
    destination: "Malad East",


  },

]


export const listingData = [
  {
    link: "/current-projects/raet",
    src: raet,
    num: "01",
    name: "RAET",
    city: "Goa",
    destination:"Bambolim"
  },
  {
    link: "/current-projects/luma",
    src: Luma,
    num: "02",
    name: "Luma",
    city: "Mumbai",
    destination:"Andheri East"
  },
  {
    link: "/current-projects/101",
    src: residents,
    num: "03",
    name: "101 RESIDENCES",
    city: "Goa",
    destination:"Bambolim"
  },
{
  link: "/current-projects/parkwoods",
  src: parkwoodsbanner,
  num: "04",
  name: "Parkwoods",
  city: "Ghodbunder Road",
  destination:"Thane West"
},
  {
    link: "/current-projects/divum",
    src: divumbanner,
    num: "05",
    name: "Divum",
    city: "Mumbai",
    destination:"Goregaon Precinct"
  },
  {
    link: "/current-projects/avanya",
    src: avanyabanner,
    num: "06",
    name: "Avanya",
    city: "Mumbai",
    destination:"Dahisar East"
  },

  {
    link: "/current-projects/astrum",
    src: astrumbanner,
    num: "07",
    name: "Astrum",
    city: "Mumbai",
    destination:"Malad East"
  },
  {
    link: "/current-projects/aldeiadegoa",
    src: aldeGoa,
    num: "08",
    name: "Aldeia de Goa",
    city: "Goa",
    destination:"Bambolim"
  },


  {
    link: "/current-projects/srishtinamaah",
    src: Srishti,
    num: "09",
    name: "Srishti Namaah",
    city: "Thane",
    destination:"Mira Road"
  },
  
];
