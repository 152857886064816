import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/pdp/hero/Hero';
import BottomNav from '../../components/common/pdp/bottomNav/BottomNav';
import Landmark from '../../components/common/pdp/landmark/Landmark';
// import Info from '../../components/common/pdp/info/Info';
import Experience from '../../components/common/pdp/experience/Experience';
import Features from '../../components/common/pdp/features/Features';
import Gallery from '../../components/common/pdp/gallery/Gallery';
import Walkthrough from '../../components/common/pdp/walkthrough/Walkthrough';
import VirtualTour from '../../components/common/pdp/virtualTour/VirtualTour';
import Exterior from '../../components/common/pdp/exterior/Exterior';
import FloorPlan from '../../components/common/pdp/floorPlan/FloorPlan';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { pdpBottomNavData } from '../../constants/pdpBottomNavData';

const PdpPage = () => {
  return (
    <>
      <Navigation />
      <Hero />
      <BottomNav pdpBottomNavData={pdpBottomNavData} />
      <Landmark />
      {/* <Info /> */}
      <Experience />
      <Features />
      <Gallery />
      <Walkthrough />
      <VirtualTour />
      <Exterior />
      <FloorPlan />
      <Contact />
      <Footer />
    </>
  );
};

export default PdpPage;
