import React, { useRef, useEffect } from 'react';
// Component
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
import SliderBtn from '../../../../assets/icons/cursor-arrow.svg';
import SliderBtnleft from '../../../../assets/icons/cursor-arrowleft.svg';



// Import Swiper styles
import 'swiper/css';


import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Fancybox(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div ref={containerRef}>{props.children}</div>;
}


// Images

// SwiperCore.use([Keyboard, Mousewheel]);

const FloorplanSlider = ({ expData }) => {
  const swiperRef = useRef();
  return (
    <div className="floor_slider">
      <img className="slider_btn leftbtn" onClick={() => swiperRef.current.slidePrev()} src={SliderBtnleft} alt="Slider Btn" />
      <img className="slider_btn" onClick={() => swiperRef.current.slideNext()} src={SliderBtn} alt="Slider Btn" />
      <Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >
        <Swiper
          spaceBetween={16}
          slidesPerView={2}
          // mousewheel={true}
          breakpoints={{
            '@0.00': {
              slidesPerView: 1.3,
              spaceBetween: 14
            },
            '@0.75': {
              slidesPerView: 1.3,
              spaceBetween: 14
            },
            '@1.00': {
              slidesPerView: 3.2,
              spaceBetween: 16
            },
            '@1.50': {
              slidesPerView: 3.1,
              spaceBetween: 16
            }
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Mousewheel]}
        >

          {expData?.map((item, indx) => (
            <SwiperSlide key={indx}>
              <div className="floor_items">
                <div className="floor_item">
                  <a data-fancybox="gallery" href={item.src}>
                    <img src={item.src} alt="slider img" />
                  </a>
                  <p>{item.title}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Fancybox>
    </div>
  );
};

export default FloorplanSlider;
