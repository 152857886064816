import Img1 from '../assets/joinbanner.jpg';
import Img2 from '../assets/joinbanner2.webp';
import Img3 from '../assets/joinbanner2.webp';
import Img4 from '../assets/joinbanner2.webp';

export const ourTeamData = [
  {
    src: Img1,
  },
  {
    src: Img2,
  },
  // {
  //   src: Img3,
  // },
  // {
  //   src: Img4,
  // }
];
