import React from 'react';
import { motion } from 'framer-motion';
// Components
import ManagemantSlider from './ManagementSlider';
// Stylesheet
import './management.scss';

// Data
import { ourManagementData } from '../../../../constants/ourManagementData';
import { textAnimate } from '../../home/projects/Projects';

const Management = () => {
  return (
    <section className="management" id="management">
      <div className="mang_title_container">
        <motion.div initial="offscreen" whileInView="onscreen">
          <motion.h2 variants={textAnimate}>Our Management</motion.h2>
          <motion.p variants={textAnimate}>
          Discover The Minds Behind Our Success Who Are Leading The Way Toward Excellence
          </motion.p>
        </motion.div>
      </div>
      <ManagemantSlider expData={ourManagementData} />
    </section>
  );
};

export default Management;
