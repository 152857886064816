import React from 'react'
// Components
import JoincareerSlider from './JoincareerSlider'
// Stylesheet
import './joinslider.scss'

// Data
import { ourTeamData } from '../../../../constants/ourTeamData'

const Joinslider = () => {
    return (
        <section className='jointeam' id='jointeam'>
            <JoincareerSlider expData={ourTeamData} />
        </section>
    )
}

export default Joinslider
