import React from 'react';
import { motion } from 'framer-motion';
// Components
import Layout from '../../../layout/LayoutMain/LayoutMain';
// Images
import LandmarkImg from '../../../../assets/pdp/landmark.png';
// Stylesheet
import './landmark.scss';
import { textAnimate } from '../../home/projects/Projects';

const Landmark = () => {
  return (
    <Layout>
      <section className="landmark" id="Overview">
        <motion.div initial="offscreen" whileInView="onscreen">
          <motion.p variants={textAnimate}>Overview</motion.p>
          <motion.h2 variants={textAnimate}>Western Suburbs' Newest Lankmark</motion.h2>
          <img src={LandmarkImg} alt="landmark img" />
        </motion.div>
        <div>
          <h4>
            Goregaon is one of the best connected and fastest growing suburbs of Mumbai, combining a vibrant residential zone and a
            top-class business district with fantastic leisure and lifestyle facilities. This dynamic combination has made it one of the
            most popular suburbs of our eclectic city.
          </h4>
          <p>
            Located in a stone’s throw distance from Goregaon East and moments away from the renowned Westin Mumbai Garden City, Nirlon
            Knowledge Park, International Business Park, Gokuldham Complex, the Gokuldham, Yashodham and Lakshdham High Schools, Oberoi
            Mall, Oberoi International School and Film City, Divum is a haven of serenity and convenience for couples and families alike.
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default Landmark;
