import React, { useEffect, useState } from 'react';
import SearchIcn from '../../../../assets/icons/search.svg';
import DefaultUser from '../../../../assets/defaultuser.svg';
import DropDown from '../../../../assets/icons/drop_down.svg';
import './adminNavigation.scss';
import { useLocation } from 'react-router-dom';

const AdminNavigation = () => {
  const [title, setTitle] = useState('Home');
  const location = useLocation();
  useEffect(() => {
    const setPath = () => {
      const currPath = location.pathname.split('/')[2];
      if (currPath === 'home') {
        setTitle('Home');
      }
    }
    setPath();
  });
  
  return (
    <div className="admin_navigation">
      <h3>{title}</h3>
      <div className="right_child">
        <div className="search_bar">
          <img src={SearchIcn} alt="search_icn" />
          <input type="text" name="search" placeholder="Press/to search" />
        </div>
        <div className="user_profile">
          <img className="user_image" src={DefaultUser} alt="defult_icn" />
          <p>Harish Panday</p>
          <img src={DropDown} alt="drop_down_icn" />
        </div>
      </div>
    </div>
  );
};

export default AdminNavigation;
