import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import LandmarkImg from "../../../../assets/pdp/healthbanner.jpg";
// Stylesheet
import "./healthcarepg.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='healthcarepgg' id="Overview">
                <div>
                    {/* <p>Overview</p> */}
                <h1 className="hidedesk new-htwo-hone margin-none">Healthcare</h1>
                    <img src={LandmarkImg} alt="landmark img" />
                </div>
                <div>
                <h1 className="hidemob new-htwo-hone margin-two">Healthcare</h1>

                    <p>GIVING YOUNG MINDS THE WINGS TO FLY.</p>

                    <p>State-of-the Art Medical Services for a Healthier India.</p>

                    <p> Goenka & Associates Medical & Research Centre was established to cater to those who need medical attention but face restrictions due to limited resources. The Trust has set up Gokuldham Medical Centre in order to provide subsidized yet top-of-the-line medical services.</p>

                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
