export const frequentlyFeaturesData = [
  {
    name: 'How to choose a home?',
    paradetail:
      "Embarking on the journey of selecting your dream home is a significant milestone. Before you venture into house hunting, it's essential to clearly identify your needs and preferences.",
    paradetailtwo: 'Here are several crucial points to consider during this important decision-making process:',
    links: [
      "Dwelling Type: Begin by determining the type of dwelling that aligns best with your lifestyle. Choices range from condos, apartments, and row houses to bungalows. Prioritize what resonates with you and your family's dynamics.",
      "Family Needs: Consider the unique needs of every family member. If you have seniors, their comfort and requirements should hold significant weight in your decision. Similarly, if pets are part of your household, an apartment on the ground floor might be preferable.",
      "Investment vs. Personal: Clarify your intent behind the purchase. Is it an investment for the future or a place for your personal abode? In case of potential resale, research the resale values beforehand.",
      "Neighbourhood: Define your preference for the neighbourhood. Would you lean towards a residential enclave or are you open to a more commercial setting? Also, evaluate whether a serene interior location takes precedence over a home closer to bustling main roads.",
      "Thorough Research: Prior to finalizing your choice, delve into comprehensive research on the following aspects:",
      "Property valuation",
      "Local traffic patterns",
      "Safety measures within the building or society and the area's crime rate",
      "Anticipated future development and growth in the vicinity",
      "Proximity to essential amenities such as markets, schools, transportation hubs, hospitals, dining options, and entertainment venues",
      "Quality of educational institutions nearby, particularly if you have school-age children",
      "Choosing a home is a journey that necessitates a thorough understanding of your aspirations and priorities. By thoughtfully considering these factors, you pave the way for making an informed and gratifying decision that aligns seamlessly with your lifestyle and vision.",
      ]
  },
  {
    name: 'How much do I have to save to buy a home?',
    paradetail: "",
      paradetailtwo: "",
      paradetailtwo: "",
    links: [
      "If you possess stable employment and a favourable credit history, the prospect of securing a home loan becomes a viable avenue for funding your new house. Most financial institutions and home lenders are inclined to extend loans that cover a substantial portion of the property's purchase price. However, the specific loan amount accessible to you is contingent upon factors such as your repayment capacity.",
      "Typically, home loans can encompass anywhere from 80% to 95% of the property's total cost, subject to the policies and criteria established by the respective lending institution. This range underscores the varying offerings within the financial sector, and it's advisable to diligently explore different banks and lenders to secure the most suitable financing arrangement for your unique circumstances.",
      "By assessing your financial standing, and repayment potential, and aligning with a lender who best aligns with your needs, you can ascertain the optimal savings goal to embark upon your journey towards homeownership.",
    ]
  },
  {
    name: 'How to get a home loan?',
    paradetail:
      "When it comes to obtaining a home loan, a plethora of banks and financial institutions offer competitive rates to cater to your needs. To navigate this process successfully, follow these steps:",
    
    links: [
      "Research and Compare: Begin by researching various banks and money lending companies that provide home loan services. Compare the terms, interest rates, repayment options, and additional features offered by each institution.",
      "Understand Your Eligibility: Familiarize yourself with the eligibility criteria set by different lenders. This may include factors like your credit score, income stability, employment history, and existing financial obligations.",
      "Gather Documentation: Prepare the necessary documents, which may include proof of identity, address, income, employment details, property documents, and any other specific requirements outlined by the lender.",
      "Application Submission: Fill out the home loan application form of your chosen bank or lender. Ensure that you provide accurate and complete information.",
      "Property Valuation and Legal Check: The lender will conduct a property valuation and legal verification process to assess the property's value and legal status. This step is crucial in determining the loan amount.",
      "Loan Approval: Once the lender reviews your application and property details, they will communicate their decision on loan approval. If approved, they will provide you with a formal loan offer.",
      "Loan Agreement Signing: Carefully review the loan terms, interest rates, repayment schedule, and other conditions outlined in the loan offer. If you agree, sign the loan agreement.",
      "Disbursement and Repayment: Upon signing, the loan amount will be disbursed to the seller or developer, and your repayment period will begin as per the agreed-upon schedule.",
      "Remember, the home loan market is diverse, and thorough research is vital to ensure you choose the right lender and loan product. By meticulously evaluating your options and understanding the terms, you can secure a home loan that best aligns with your financial goals.",
    ]
    
  },
  {
    name: "Who is liable to pay Stamp Duty-the buyer or the seller?",
    paradetail:
      "In the realm of property transactions, the responsibility for paying stamp duty is a pivotal consideration. The standard practice dictates the following:",
    paradetailtwo: "",
    links: [
      "Primary Liability: Typically, the onus of paying the stamp duty rests upon the buyer of the property. This obligation arises unless a distinct agreement between the parties stipulates otherwise.",
      "Special Agreements: However, it's important to note that agreements between parties can sometimes deviate from this norm. If a specific arrangement exists, detailing a different distribution of the stamp duty liability, the parties involved must adhere to the terms specified in their agreement.",
      "Legal Context: The Bombay Stamp Act, 1958 comprehensively addresses the framework for the payment of stamp duty, providing a clear directive on this matter.",
      "In essence, while the customary practice designates the buyer as the primary bearer of stamp duty, flexibility exists through negotiated agreements.",
    ]
  },
  {
    name: "Which are the instruments that attract the payment of Stamp Duty?",
    paradetail:
      "Various legal instruments related to property transactions are subject to the payment of stamp duty. These instruments include:",
    
    links: [
      "Agreement to Sell: When parties agree on the sale of property, an agreement to sell is executed. Stamp duty is levied on this agreement.",
      "Conveyance Deed: This legal document is used to transfer ownership of property from one party to another. Stamp duty is applicable on conveyance deeds.",
      "Exchange of Property: When properties are exchanged between parties, stamp duty is applicable on the exchange agreement.",
      "Gift Deed: Transferring ownership of property as a gift requires a gift deed, which attracts stamp duty.",
      "Partition Deed: In cases of property partition among co-owners, a partition deed is executed and is subject to stamp duty.",
      "Power of Attorney: If a power of attorney is granted for property-related matters, it attracts stamp duty.",
      "Settlement Deed: Transfer of property through a settlement deed is subject to stamp duty.",
      "Transfer of Lease: When a property lease is transferred, stamp duty is applicable.",
      "In all these instances, stamp duty is calculated based on the market value of the property involved.",

    ]
  },

  {
    name: "What is FSI?",
    paradetail:
      "Floor Space Index (FSI) is a crucial concept in urban planning and real estate development. It is defined as the ratio of the combined gross floor area of all floors, excluding areas specifically exempted under relevant regulations, to the total area of the plot.",
    links: [
      "Mathematically, FSI can be expressed using the following formula:",
      "FSI = (Total Covered Area on All Floors) / (Area of the Plot)",
      "This formula quantifies the permissible extent of construction on a given plot of land. It provides a framework for determining how much built-up space can be created relative to the size of the land parcel. FSI is a fundamental parameter used by city planners and regulatory authorities to manage urban development, control population density, and maintain a balanced urban environment.",
      "In essence, FSI plays a pivotal role in shaping the built environment of a city, ensuring optimal land utilization while adhering to prescribed norms and regulations.",

    ]
  },
{
    name: "What are the Tax implications on the sale of any Residential / Commercial property?",
    paradetail:
      "",
    links: [
      "When you sell a residential or a commercial property, there are important tax implications to consider, particularly in relation to capital gains.",
      "The profit realized from the sale of a residential or commercial property falls under the scope of capital gains tax. This means that you may be liable to pay tax on the financial gain resulting from the sale.",
      "Navigating the tax implications of property sales involves understanding the various aspects of capital gains tax, including differentiating between short-term and long-term capital gains, as well as any exemptions or deductions that may apply.",

    ]
  },
  {
    name: "What is meant by the market value of the property and is Stamp Duty payable on the market value of the property or on consideration as stated in the agreement?",
    paradetail:
      "",
    links: [
      "Property market value refers to the price at which a property could be purchased in an open market scenario on the date of executing a legal instrument. This value is often determined and published by governmental authorities.",
      "When it comes to Stamp Duty, it is essential to know that it is payable based on either the agreement value of the property or the market value, whichever is higher. In other words, if the market value of the property is higher than the agreed-upon value stated in the legal agreement, Stamp Duty will be calculated on the market value. This ensures that transactions are assessed accurately and transparently, reflecting the true worth of the property.",
      "Understanding the concept of market value and its relationship with Stamp Duty helps ensure fair and equitable property transactions, aligning with established regulatory standards.",

    ]
  },
  {
    name: "What is TDR?",
    paradetail:
      "",
    links: [
      "Transfer of Development Rights (TDR) involves the provision of an additional built-up area in exchange for the area relinquished or surrendered by the landowner. This mechanism allows the landowner to utilize the surplus built-up area for their own purposes or transfer it to another party in need of extra built-up space, usually in return for an agreed-upon sum of money.",
      "In essence, TDR facilitates a process wherein the owner can effectively convert the development potential of one property into another, either through personal utilization or monetization. This concept plays a pivotal role in urban planning and real estate transactions, offering flexibility in optimizing land usage and development.",
      
    ]
  },
  {
    name: "What permissions and papers should one check with the builder to verify the builder, on buying a flat from a builder in a building under construction?",
    paradetail:
      "",
    links: [
      "Purchasing a flat from a builder in an under-construction building necessitates thorough verification to ensure a secure investment. Key considerations include:",
      "Approved Building Plan: Review the sanctioned plan of the building, confirming the approved number of floors. Ensure the specific floor you're purchasing has the necessary approvals.",
      "Land Ownership: Validate the builder's ownership of the land or any legal agreements with a landlord. Seek legal assistance to scrutinize land titles for authenticity.",
      "Building Byelaws Compliance: Verify adherence to building byelaws applicable in the area, ensuring compliance with regulations related to setbacks, height restrictions, and other parameters.",
      "Accurate Specifications: Cross-reference the specifications outlined in the agreement to sell and sales brochure with the actual construction on-site. Ensure the promised features align with reality.",
      "Builder Reputation: Investigate the builder's reputation and track record in delivering quality projects. Online reviews and recommendations can offer valuable insights.",
      "Urban Land Ceiling NOC: Confirm whether the builder has obtained the necessary No Objection Certificate (NOC) related to urban land ceiling regulations, if applicable.",
      "Water and Electricity Approvals: Ensure the builder possesses the requisite NOCs from water and electricity authorities, validating essential utility connections.",
      "Lift Authority NOC: Verify the existence of a No Objection Certificate from relevant lift authorities, guaranteeing safe and compliant lift installation.",
    ]
  },
];
