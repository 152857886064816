import React from "react";
import Layout from "../../../layout/Layout/Layout";
// Components
// Stylesheet
import "./exterior.scss";

const Exterior = () => {
    return (
        <Layout>
        <section className='exterior' id="View">
            <div className='title_container'>
                <p>EXTERIOR VIEW</p>
                <h2>360&deg;  VIEWS</h2>
            </div>
            <iframe key="virtual-tour-2" width="100%" height="500" src="https://www.dynamixgroup.co.in/embed/360-degree/Goregaon/Virtual%20Tour/" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="">
            </iframe>
            {/* <div className='virtual_image'>
                <img src={DimentionIcn} alt="3d icon" />
            </div> */}
        </section>
        </Layout>
    )
}

export default Exterior;
