import React from 'react';
import { motion } from 'framer-motion';
import './features.scss';
import { textAnimate } from '../../home/projects/Projects';

const Features = () => {
    return (
        <section className='pdp_features' id='Project'>
            <motion.div className='features_title_container' initial="offscreen" whileInView="onscreen">
                <motion.p variants={textAnimate}>PROJECT FEATURES</motion.p>
                <motion.h2 variants={textAnimate}>Modern comforts just a step away</motion.h2>
            </motion.div>
            <div className='features_items'>
                {
                    [...Array(18)].map((_, key) => (
                        <div className='features_item' key={key}>
                            <div className='bullet'></div>
                            <p>2 Security Checkpoints</p>
                        </div>
                    ))
                }
            </div>
        </section>
    )
}

export default Features
