import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Landmark from '../../components/common/dairy/landmark/Landmark';
import Experience from '../../components/common/dairy/experience/Experience';
// import Facilities from '../../components/common/dairy/facilities/Facilities';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const EducationPage = () => {
  return (
    <>
      <Helmet>
        <title>Dairy - Dynamix Group</title>
        <meta name="description" content="Find out the best real estate developments and properties in Mumbai located in areas like Andheri (East), Dahisar, Thane and Goregaon by premium builders in Mumbai, The Dynamix Group." />
      </Helmet>
      <Navigation />
      <Landmark />
      <Experience />
      {/* <Facilities /> */}
      <Contact />
      <Footer />
    </>
  );
};

export default EducationPage;
