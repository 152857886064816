import React from 'react';
import Navigation from '../../components/common/navigation/Navigation';
import TopmainHeading from '../../components/common/legacypage/topmainheading/TopmainHeading';
import FullBanner from '../../components/common/legacypage/fullbanner/FullBanner';
import ScrollerContent from '../../components/common/legacypage/scrollercontent/ScrollerContent';
import LegacyTable from '../../components/common/legacypage/legacytable/LegacyTable';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";




const LegacyPage = () => {
  return (
    <>
      <Helmet>
        <title>Five Decade Legacy | Top Developer in Mumbai - Dynamix Group</title>
        <meta name="description" content="The Dynamix Group has nearly five decades of experience in real estate development, with a delivery success of over 30 million sq.ft. Click to know more!" />
      </Helmet>
      <Navigation />
      <TopmainHeading />
      <FullBanner />
      <ScrollerContent />
      <LegacyTable />
      <Contact />
      <Footer />
    </>
  );
};

export default LegacyPage;
