import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarkwoodland.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkwoodland' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>The Upper Echelons Of Luxury Living</h2>
                    <div className="paraproject">
                        <p>One of the most remarkable and most recently completed residential project undertaken by The Dynamix Group, Woodlands is a testimony to our legacy of crafting the finest homes in Mumbai. The elegant 14-storey tower features some of the most exquisite 4 and 5 BHK apartments ranging from spectacular 3000 sq. ft. to the ultra-spacious 4000 sq. ft. To top it all, Woodlands features landscaped gardens and all the world class amenities needed to live life like a king in the City of Dreams.</p>
                    </div>
                </div>
                <div>
                  <ul>
                  <li><strong>Nature</strong> <span>Residential Tower (14 storey)</span></li>
                    <li><strong>Location</strong> <span>Malad (East), Mumbai</span></li>
                    <li><strong>Design Architect</strong> <span>M/s IAG Consultants Pvt. Ltd.</span></li>
                    <li><strong>BMC Architect</strong> <span>M/s Kalpana Consultant Pvt. Ltd.</span></li>
                    <li><strong>Structural Consultant</strong> <span>M/s Mahimtura Consultant Pvt. Ltd.</span></li>
                    <li><strong>Contractor</strong> <span>	M/s Skoll Construction Pvt. Ltd.</span></li>
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
