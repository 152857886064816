import React from 'react'
import { Link } from "react-router-dom";
// Images
import CallIcn from '../../../assets/icons/call.svg'
import mailblack from '../../../assets/icons/mail-black.svg'
import WhatsAppIcn from '../../../assets/icons/whatsapp.svg'


const BottomNav = () => {
    return (
        <section className='bottom_navigation'>
            <div className='bottom_nav_container'>
                <div>
                <a href="tel:+912248970000"><img src={CallIcn} alt="nav icn" /></a>

                    
                    
                </div>
                <div>
                    <Link to="/contact-us" onClick={() => window.scrollTo(0, 0)}>
                        <img src={mailblack} alt="nav icn" />
                    </Link>
                </div>
                {/* <div>
                    <img src={WhatsAppIcn} alt="nav icn" />
                </div>
                <div className='btn'>GET IN <br /> TOUCH</div> */}
            </div>
        </section>
    )
}

export default BottomNav
