
// import management_2 from '../assets/about/ourmanagement/management_2.png';
// import management_1 from '../assets/about/ourmanagement/management_1.png';
//
import Jaywardhan from '../assets/about/ourmanagement/Jaywardhan.webp';
import JessieKuruvilla from '../assets/about/ourmanagement/JessieKuruvilla.webp';
import AnimeshDas from '../assets/about/ourmanagement/AnimeshDas.webp';
import AnthonyGonsalves from '../assets/about/ourmanagement/AnthonyGonsalves.webp';
import NarendraShastri from '../assets/about/ourmanagement/NarendraShastri.webp';
import AtulNemani from '../assets/about/ourmanagement/AtulNemani.jpg';
import NainaBahadkar from '../assets/about/ourmanagement/NainaBahadkar.webp';
import JitendraRathi from '../assets/about/ourmanagement/JitendraRathi.webp';

export const ourManagementData = [
 
  {
    src1: Jaywardhan,
    titleone: 'Jayvardhan Goenka',
    typeone: "Director",
    frstpara1: "Jayvardhan Goenka, fondly known as Jay Goenka leads The Dynamix Group, one of India's oldest real estate development groups. He represents the third generation of the Goenka family at the helm of the Group. Commencing his career at the age of 20, Jay swiftly earned the admiration of his senior colleagues and peers due to his unwavering work ethic, meticulous attention to detail, and innate understanding of the business. Currently, he oversees seven ongoing projects, encompassing over 4 million sq. ft., and manages a development pipeline exceeding 10 million sq. ft. spanning Mumbai and Goa",
    frstpara2: "His specific focus lays in design, project management, and acquiring necessary approvals. Furthermore, Jay undertook an internship with the esteemed 'Global Economics' team at McKinsey and Company's Knowledge Centre during a university summer break.",
    frstpara3: "Graduating with honours, Jay excelled academically throughout his education. He completed his schooling at Aiglon College, a renowned Swiss boarding school, where he was selected as the Guardian of the School in his final year. He subsequently enrolled at the London School of Economics and Political Science (LSE), earning a BSc (Honours) First Class degree in Philosophy and Economics.",
    frstpara4: "Beyond his professional pursuits, Jay is a devoted runner, actively participating in various distance running events. During his leisure time, he indulges in reading works on political philosophy, ethics, and business literature.",
    frstpara5: "Currently, Jay also serves on the Management Committee of NAREDCO, a Real Estate body that takes up and addresses a myriad of industry-related issues.",

    src2: JessieKuruvilla,
    titletwo: 'Jessie Kuruvilla',
    typetwo: "Director – Sales & CRM",
    secondpara1: "With an impressive track record spanning over three decades within the Dynamix Group, Ms. Jessie Kuruvilla stands as an accomplished Real Estate Professional. In her current role as the Director of Sales & Customer Relations, she wields her expertise to oversee the critical domains of Sales and Customer Relationship Management, leaving an indelible mark on the organization's success.",
    secondpara2: "Throughout her distinguished career, Jessie has played a pivotal role in shaping the sales function within the group. Her strategic acumen and unwavering dedication have been instrumental in driving the success of some of the Group's most iconic developments. Noteworthy among these are Gokuldham, Yashodham, Mahavir Nagar, Upper Govind Nagar, Parkwoods, and Aldeia de Goa.",
    secondpara3: "Armed with a Post Graduate degree in Psychology, Jessie's passion for knowledge is further reflected in her avid reading habits. Renowned for her meticulous attention to detail, she epitomizes the role of a taskmaster, ensuring that every facet of her work is executed with precision.",
    secondpara4: "Beyond her exceptional sales prowess, Jessie's rich experience and domain expertise extend to strategic coordination with partners and key stakeholders. Her involvement plays a pivotal role in driving operational efficiency, achieving administrative synergies, optimizing project profitability, and maximizing revenue.",
    // secondpara5: "Before joining forces with The Dynamix Group, Anthony held a distinguished position at Synergy Property Developments Services Pvt. Ltd., where he masterminded and successfully delivered monumental projects within India and on an international scale. His portfolio boasts a constellation of renowned and iconic structures that have left an indelible mark on the world stage. Noteworthy among these are the Adventz and Riviera Tower in Sharjah, Pullman, Pride, and Ibis Hotels in Delhi, as well as Verandas, Suncity, and Central Park in Gurugram.",
    // secondpara6: "Educated at the esteemed Walchand Institute of Technology, Anthony holds a Bachelor's Degree in Civil Engineering (BE). His professional qualifications extend to the realm of law, with a prestigious LLM in Construction Law and Arbitration conferred upon him by Robert Gordon University. This unique blend of engineering and legal expertise equips him to navigate complex challenges and intricacies inherent in the construction domain.",
    // secondpara7: "With a firm belief in global best practices, Anthony brings an international perspective to The Dynamix Group. He places a paramount emphasis on the pursuit of quality, the achievement of programmatic and design efficiencies, and the seamless integration of innovation and excellence.",
  },
  {
    src1: AnimeshDas,
    titleone: "Animesh Das",
    typeone: "Director - Sales & CRM",
    frstpara1: "Animesh Das is the esteemed Director of Sales & Customer Relations at the Dynamix Group, entrusted with the pivotal role of overseeing Sales and Customer Relationship Management. With a Master's Degree in Mathematics and a comprehensive MBA education under his belt, he embarked on a career journey that has been both diverse and illustrious.",
    frstpara2: "Mr. Das's professional odyssey commenced in the realm of office automation, where he honed his skills and cultivated a strong foundation for his future endeavors. His journey then led him through the dynamic landscapes of retail and telecom industries, where he demonstrated his versatility and ability to adapt to varying sectors.",
    frstpara3: "Having amassed a wealth of knowledge and expertise, Mr. Das transitioned into the realm of real estate—a sphere where he truly found his calling. His dedication and proficiency were instrumental in his collaborations with eminent real estate developers. Prior to his tenure at the Dynamix Group, Mr. Das contributed significantly to renowned projects in partnership with industry leaders such as Lodha Developers and Omkar Developers.",

    src2: AnthonyGonsalves,
    titletwo: 'Anthony Gonsalves',
    typetwo: "Director - Projects and Engineering",
    secondpara1: "Anthony Nelson Gonsalves boasts over two decades of experience in both civil engineering and real estate development.",
    secondpara2: "Currently serving as the Projects Director at The Dynamix Group, he leads a team of architects and real estate professionals working on various projects across Mumbai, Thane, and Goa. Before joining The Dynamix Group, Anthony successfully managed large-scale projects in India and abroad during his tenure at Synergy Property Developments Services Pvt. Ltd.",
    secondpara3: "He has contributed to some of the world's most renowned and iconic buildings, including Adventz and Riviera Tower in Sharjah, Pullman, Pride, and Ibis Hotels in Delhi, and Verandas, Suncity, and Central Park in Gurugram.",
    secondpara4: "Anthony holds a Bachelor's degree in Civil Engineering from Walchand Institute of Technology. Additionally, he is legally qualified, holding the prestigious LLM in Construction Law and Arbitration from Robert Gordon University. Leveraging his international expertise, Anthony is implementing global best practices at The Dynamix Group, with a specific focus on quality and achieving program and design efficiency.",
  },

  {
    src1: NarendraShastri,
    titleone: 'Narendra Shastri',
    typeone: "Director - Regulatory",
    frstpara1: "Mr. Narendra Shastri assumes the esteemed role of Director – Projects and Engineering at the Dynamix Group. With a remarkable tenure spanning 24 years within the organization, Mr. Shastri has orchestrated the successful execution of projects that collectively span over 10 million square feet. His invaluable contributions have positioned him at the forefront of technical operations, playing a pivotal role in the liaison and construction of every project undertaken by the Dynamix Group.",
    frstpara2: "At present, Mr. Shastri's leadership encompasses paramount responsibilities, including engineering oversight, implementation management, and liaison coordination across the Group's diverse projects.",
    frstpara3: "Educated as a civil engineer, Mr. Shastri boasts a distinguished academic background with a Bachelor's Degree in Engineering (BE) and an esteemed membership as a Member of the Institute of Engineers (MIE). He has further distinguished himself as a Chartered Engineer through the Institute of Engineers.",
    frstpara4: "Mr. Shastri's profound expertise is underscored by his affiliation with esteemed institutions. He holds the esteemed distinction of being a Life Fellow of the Indian Concrete Institute (ICI), a testament to his significant contributions to the realm of concrete technology. Additionally, his commitment to environmental conservation and natural history is evident through his role as a Fellow of the Bombay Natural History Society (BNHS).",

    src2: AtulNemani,
    titletwo: 'Atul Nemani',
    typetwo: "Chief Financial Officer",
    secondpara1: "Atul Nemani is currently serving as the Chief Financial Officer at The Dynamix Group, bringing over 15 years of comprehensive expertise in Financial Planning & Analysis, Tax Regulations & Optimization, Financial Reporting & Controls, CAPEX & OPEX Budgeting, Fund Raising, Treasury Operations, Risk Management, Process Implementation, Secretarial, and Technologies. As a dynamic team player, he contributes to various facets of financial management.",
    secondpara2: "Prior to joining The Dynamix Group, Atul held leadership positions at Kolte Patil Developers Ltd. and was associated with Shapoorji Pallonji & Co Ltd., showcasing a rich history in the financial domain.",
    secondpara3: "Atul Nemani is a Chartered Accountant, having earned this prestigious designation from The Institute of Chartered Accountants of India. With a blend of academic excellence and a proven track record in financial leadership, Atul continues to drive financial success at The Dynamix Group.",
  },

  {
    src1: NainaBahadkar,
    titleone: 'Naina Bahadkar',
    typeone: "Chief Human Resource Officer",
    frstpara1: "Naina Bahadkar is a pragmatic Chief Human Resources Officer with over 20 years of experience in renowned organisations in the Automobile, Financial Services, Building Materials, and Real Estate sectors. She is skilled in Change Management, Organisational Development, HR Automation, and Talent Management.",
    frstpara2: "Naina has helmed and converted numerous budding ideas into successful strategies. She plays a key role in facilitating the creation of a positive, productive, and performance-oriented culture in the workplace. Her ability to conduct difficult conversations without divisiveness and unite different role-players by aligning them around the company's mission makes her a copacetic HR professional.",
    frstpara3: "Naina holds a Master’s Degree in Personnel Management, backed by PMP certifications from IIM Ahmedabad. She is also a member of CPHR BC & Yukon. A recipient of the “Top Women Chief Human Resource Officers - 2022” award by Women Entrepreneurs Magazine, she was also honoured with the “101 HR Super Achievers” 2018-19 award by Times Now18 & ET Awards.",
    frstpara4: "Prior to her tenure at the Dynamix Group, Ms. Bahadkar made significant contributions as an HR professional at Mahindra and Mahindra, Saint Gobain, Runwal Group, to name a few.",


    src2: JitendraRathi,
    titletwo: 'Jitendra Rathi',
    typetwo: "VP Accounts & Finance",
    secondpara1: "Jitendra Rathi, a highly accomplished finance professional with 22 years of diverse experience, holds a B.Com degree and completed a 3-year CA article ship with a distinguished Chartered Accountant Co. Commencing his career in accountancy and income tax practice, he showcased early expertise in financial intricacies. ",
    secondpara2: "In August 2001, Jitendra joined the Dynamix Group, contributing significantly to an Export Oriented Granite Unit in Bangalore and Satara. Serving as Manager of Accounts, he navigated responsibilities ranging from accounting, audit, and taxation to excise, service tax, and foreign transactions. ",
    secondpara3: "Currently, serving as the VP of Accounts and Finance at Dynamix Group, his comprehensive skill set encompasses accounting, audit, taxation, group cash flow management, project cost management, RERA filing, and various management-related responsibilities.",
  },
];




