import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarkparkresidence.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkparkresidence' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>As Premium Aa It Sounds</h2>
                    <div className="paraproject">
                        <p>The Park Residence is a high-end residential tower in the real estate hotbed of Malad (East), but what separates it from the rest is its unique construction. It has a Lower stilt + Upper stilt 16 storeys high-end residential tower with cantilever refugee floors at 6th and 13th floor offering premium apartments of 3 BHK. And that’s not all – rarely will you find such modern external and internal amenities including club house, gymnasium and garden-set swimming pool anywhere else in Mumbai.</p>
                    </div>
                </div>
                <div>
                  <ul>
                  <li><strong>Nature</strong> <span>Residential Tower (16 storey)</span></li>
                    <li><strong>Location</strong> <span>Malad (East), Mumbai</span></li>
                    <li><strong>Design Architect</strong> <span>M/s IAG Consultants Pvt. Ltd.</span></li>
                    <li><strong>BMC Architect</strong> <span>Mr. Abhijit Mehta</span></li>
                    <li><strong>Structural Consultant</strong> <span>M/s Pravin Gala Consultants Pvt. Ltd.</span></li>
                    <li><strong>Contractor</strong> <span>M/s Rushi Housecon</span></li>
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
