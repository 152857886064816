import Img1 from '../assets/about/realestate.png';
import Img2 from '../assets/about/education.png';
import Img3 from '../assets/about/healthcare.png';
import Img4 from '../assets/about/dairy.png';

export const groupData = [
  {
    src: Img1,
    title: 'REal estate',
    href: '/current-projects'
  },
  {
    src: Img2,
    title: 'Education',
    href: '/education'
  },
  {
    src: Img3,
    title: 'Healthcare',
    href: '/healthcare'
  },
  {
    src: Img4,
    title: 'Dairy',
    href: '/dairy'
  }
];
