export const dashboardNavLinks = [
  {
    link: 'Dashboard',
    path: '/admin'
  },
  {
    link: 'Home',
    path: '/admin'
  },
  {
    link: 'Projects',
    path: 'projects'
  },
  {
    link: 'Menu',
    path: '/menu'
  },
  {
    link: 'Pages',
    childrens: [
      {
        link: 'About us',
        path: 'about-us'
      },
      {
        link: 'Construction',
        path: '/construction'
      },
      {
        link: 'Investors ',
        path: '/investors'
      },
      {
        link: 'Buyers Guide ',
        path: '/buyers-guide'
      },
      {
        link: 'Resources ',
        path: '/resources'
      },
      {
        link: 'Career ',
        path: '/career'
      },
      {
        link: 'Contact us ',
        path: '/contact-us'
      }
    ]
  },
  {
    link: 'Blogs',
    path: '/blogs'
  },
  {
    link: 'Leads',
    path: '/leads'
  },
  {
    link: 'Users',
    path: '/users'
  },
  {
    link: 'Form',
    path: '/form'
  },
  {
    link: 'Logout',
    path: '/logout'
  }
];
