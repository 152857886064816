import React from 'react';
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
// Stylesheet
import './legacytable.scss'

const LegacyTable = () => {
    return (
        <section className='tablebg'>

            <div className="text-center">
                <div className="main-heading-table">
                    <h2>A Five DECADE LEGACY</h2>
                </div>
            </div>

            <Layout>
                <div className='tabletext'>
                    <div class="table-responsive">
                        <table border="1">
                            <tbody>
                                <tr>
                                    <th colspan="5">MUMBAI</th>
                                </tr>
                                <tr>
                                    <td>Sr. No.</td>
                                    <td>Project Name</td>
                                    <td>Location</td>
                                    <td>Type</td>
                                    <td>Township</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Gokuldham Township</td>
                                    <td rowspan="6">Goregaon (East)</td>
                                    <td>Residential</td>
                                    <td rowspan="6">Gokuldham Township</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Lakshachandi Apartments</td>
                                    <td>Residential</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Lakshachandi Heights</td>
                                    <td>Residential</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Meenakshi Apartments</td>
                                    <td>Residential</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Meenakshi Tower</td>
                                    <td>Residential</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Prakriti</td>
                                    <td>Residential</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Udyog Bhawan</td>
                                    <td>Goregaon (East)</td>
                                    <td>Commercial</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Yashodham (Bungalows)</td>
                                    <td>Goregaon (East)</td>
                                    <td>Residential</td>
                                    <td rowspan="5">Yashodham Township</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Swapnalok Apartment</td>
                                    <td rowspan="4">Malad East</td>
                                    <td rowspan="4">Residential</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>Swapnalok Tower</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>Vrindavan</td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>Shagun Tower</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>Boulevard</td>
                                    <td rowspan="9">Malad (East)</td>
                                    <td>Residential</td>
                                    <td rowspan="9">Upper Govind Nagar Township</td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>Moksh</td>
                                    <td>Residential</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>Muktangan</td>
                                    <td>Residential</td>
                                </tr>
                                <tr>
                                    <td>16</td>
                                    <td>Park Residence</td>
                                    <td>Residential</td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>Sahyadri</td>
                                    <td>Residential</td>
                                </tr>
                                <tr>
                                    <td>18</td>
                                    <td>Shikharkunj</td>
                                    <td>Residential</td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>Upvan Tower</td>
                                    <td>Residential</td>
                                </tr>
                                <tr>
                                    <td>20</td>
                                    <td>Woodlands</td>
                                    <td>Residential</td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td>Astrum</td>
                                    <td>Residential</td>
                                </tr>
                                <tr>
                                    <td>22</td>
                                    <td>Panchsheel Gardens</td>
                                    <td rowspan="3">Kandivali (West)</td>
                                    <td>Residential</td>
                                    <td rowspan="3">Mahavir Nagar Township</td>
                                </tr>
                                <tr>
                                    <td>23</td>
                                    <td>Panchsheel Heights</td>
                                    <td>Residential</td>
                                </tr>
                                <tr>
                                    <td>24</td>
                                    <td>Panchsheel Heights Arcade</td>
                                    <td>Commercial</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>Ascot Centre</td>
                                    <td>Sahar (Andheri)</td>
                                    <td>Commercial</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>26</td>
                                    <td>Turf Estate</td>
                                    <td>Mahalaxmi</td>
                                    <td>Commercial</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>27</td>
                                    <td>Srishti</td>
                                    <td>Mira Road (East)</td>
                                    <td>Residential</td>
                                    <td>Township</td>
                                </tr>
                                <tr>
                                    <td>28</td>
                                    <td>Hotel &amp; Commercial Developments</td>
                                    <td>Andheri East</td>
                                    <td>Commercial</td>
                                    <td>Hospitality District</td>
                                </tr>
                                <tr>
                                    <td>29</td>
                                    <td>Divum</td>
                                    <td>Goregaon Precinct</td>
                                    <td>Residential</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>30</td>
                                    <td>Avanya</td>
                                    <td>Dahisar East</td>
                                    <td>Residential</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>31</td>
                                    <td>Luma</td>
                                    <td>Andheri East</td>
                                    <td>Residential</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="table-responsive">
                        <table border="1">
                            <tbody>
                                <tr>
                                    <th colspan="5">THANE</th>
                                </tr>
                                <tr>
                                    <td>Sr. No.</td>
                                    <td>Project Name</td>
                                    <td>Location</td>
                                    <td>Type</td>
                                    <td>Township</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Jasmine Tower</td>
                                    <td rowspan="3">Thane (West)</td>
                                    <td rowspan="3">Residential</td>
                                    <td rowspan="3">Vasant Vihar Township</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Rajnigandha</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Vasant Vihar</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Parkwoods (Phase I - A,B,C &amp; D)</td>
                                    <td rowspan="3">Thane (West)</td>
                                    <td>Residential</td>
                                    <td rowspan="3">Parkwoods Township</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Parkwoods (Phase II)</td>
                                    <td>Commercial</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>D Mart</td>
                                    <td>Commercial</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="table-responsive">
                        <table border="1">
                            <tbody>
                                <tr>
                                    <th colspan="5">GOA</th>
                                </tr>
                                <tr>
                                    <td>Sr. No.</td>
                                    <td>Project Name</td>
                                    <td>Location</td>
                                    <td>Type</td>
                                    <td>Township</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Frangipani Villas</td>
                                    <td rowspan="3">Bambolim, Goa</td>
                                    <td rowspan="3">Residential</td>
                                    <td rowspan="3">Aldeia de Goa Township</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Seaside Plots</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Vida de Aldeia Apartments</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Layout>
        </section>
    )
}

export default LegacyTable
