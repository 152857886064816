import React, {useEffect, useState} from 'react';
import { motion } from 'framer-motion';
// Component
import BlogSlider from './BlogSlider';
import { textAnimate } from '../projects/Projects';
// Stylesheet
import './blog.scss';
import axios from "axios";

// data
//import { blogData } from '../../../../constants/blogData';
const baseURL ="https://dynamixgroup.co.in/dynamixapi/api/V1/blog?_sort=post_timestamp&_order=DESC&limit=4";
const Blog = () => {
  const [homeBlogList, setHomeBlogList] = useState([]);
  const getBlogs = async () => {
    const res = await axios.get(baseURL);
    setHomeBlogList(res.data);
  }

  useEffect(() => {
    getBlogs()
  }, []);


  return (
    <section className="blog">
      <div className="blog_container">
        <motion.div className="left_container" initial="offscreen" whileInView="onscreen">
          <motion.p className="title_underline" variants={textAnimate}>
            BLOGS & NEWS
          </motion.p>
          <motion.h2 variants={textAnimate}>Explore Our Blogs</motion.h2>
          <p>Stay Tuned To The Latest News and Blog Postings From The Dynamix Group</p>
        </motion.div>
        <div className="right_container">
          <BlogSlider blogData={homeBlogList} />
        </div>
      </div>
    </section>
  );
};

export default Blog;
