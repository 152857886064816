import React from 'react'
// Components
import ExpSlider from './ExpSlider'
// Stylesheet
import './exphealthcare.scss'

// Data
import { exphealthslide } from '../../../../constants/expData'

const Experience = () => {
    return (
        <section className='exphealthcare' id='Amenities'>
            <div className='exp_title_container'>
                <div>
                <h2>Bringing Smiles to More Than 800 Patients Everyday.</h2>
                <p>Gokuldham Medical Centre caters to the needs of over 800 patients daily. The Diagnostic Department has a well-equipped Pathology Lab and a host of facilities like X-Ray, Sonography, Stress Tests to name a few. Advanced facilities like Radiology & Audiometry are also available at the centre. One can have a routine consultation in different departments throughout the day.</p>
                <p>With a well-developed Therapeutics Department, Gokuldham Medical Centre provides services such as Physiotherapy, Speech Therapy, Occupational Therapy & Rehabilitation and Dental Care at an affordable prices. The Centre has also recently added state-of-the-art Operation Theatres for Ophthalmic and other minor surgical procedures.</p>
                </div>
            </div>
            <ExpSlider expData={exphealthslide} />
        </section>
    )
}

export default Experience
