import React, { useRef, useEffect } from "react";
// Components
import Layout from "../../../layout/Layout/Layout";
import LayoutMain from "../../../layout/LayoutMain/LayoutMain";
// Images
import GG1rep from "../../../../assets/gallery/Bathroom.jpg";
import GG2rep from "../../../../assets/gallery/Bed-Room.webp";
import GG3rep from "../../../../assets/gallery/Deck.webp";
import GG4rep from "../../../../assets/gallery/Dinning-Area.webp";
import GG5rep from "../../../../assets/gallery/Elevation.webp";
import GG6rep from "../../../../assets/gallery/Elevation_1.webp";
import GG7rep from "../../../../assets/gallery/Elevation-Top-View.webp";
import GG8rep from "../../../../assets/gallery/Elevation-Top-View_1.webp";
import GG9rep from "../../../../assets/gallery/Entrance.webp";
import GG10rep from "../../../../assets/gallery/Kitchen.webp";
import GG11rep from "../../../../assets/gallery/Living-Room.webp";
import GG12rep from "../../../../assets/gallery/Lobby.webp";



// Stylesheet
import "./gallerygoa.scss";

import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Fancybox(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div ref={containerRef}>{props.children}</div>;
}
//
const Collage = () => {
  return (

    <section className="gallerycollapsealdeiadegoa">
       <Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >
      <>
        <div className="gallary_containeraldeiadegoa">
        <div className="gallary_item grid_row_span_2">
            <a data-fancybox="gallery" href={GG1rep}>
              <img src={GG1rep} alt="gallery img" />
              <div class="overlay">
                <span className="hover-text">Bathroom</span>
              </div>
            </a>
          </div>
          <div className="gallary_item">
          <a data-fancybox="gallery" href={GG2rep}>
              <img src={GG2rep} alt="gallery img" />
              <div class="overlay">
                <span className="hover-text">BedRoom</span>
              </div>
            </a>
          </div>
          <div className="gallary_item grid_col_span_2 grid_row_span_2">
          <a data-fancybox="gallery" href={GG3rep}>
              <img src={GG3rep} alt="gallery img" />
              <div class="overlay">
                <span className="hover-text">Deck</span>
              </div>
            </a>
          </div>
          <div className="gallary_item">
          <a data-fancybox="gallery" href={GG4rep}>
              <img src={GG4rep} alt="gallery img" />
              <div class="overlay">
                <span className="hover-text">DINING AREA</span>
              </div>
            </a>
          </div>

          <div className="gallary_item grid_row_span_2">
          <a data-fancybox="gallery" href={GG5rep}>
              <img src={GG5rep} alt="gallery img" />
              <div class="overlay">
                <span className="hover-text">ELEVATION SIDE
                VIEW</span>
              </div>
            </a>
          </div>
          <div className="gallary_item">
          <a data-fancybox="gallery" href={GG6rep}>
              <img src={GG6rep} alt="gallery img" />
              <div class="overlay">
                <span className="hover-text">Elevation</span>
              </div>
            </a>
          </div>

          <div className="gallary_item grid_col_span_2 grid_row_span_2">
          <a data-fancybox="gallery" href={GG7rep}>
              <img src={GG7rep} alt="gallery img" />
              <div class="overlay">
                <span className="hover-text">Elevation Top View</span>
              </div>
            </a>
          </div>
          <div className="gallary_item">
          <a data-fancybox="gallery" href={GG8rep}>
              <img src={GG8rep} alt="gallery img" />
              <div class="overlay">
                <span className="hover-text">Elevation Top View</span>
              </div>
            </a>
          </div>

          {/* <div className="gallary_item grid_row_span_2">
            <a data-fancybox="gallery" href={GG9rep}>
              <img src={GG9rep} alt="gallery img" />
              <div class="overlay">
                <span className="hover-text">Entrance</span>
              </div>
            </a>
          </div> */}
          <div className="gallary_item">
          <a data-fancybox="gallery" href={GG10rep}>
              <img src={GG10rep} alt="gallery img" />
              <div class="overlay">
                <span className="hover-text">Kitchen</span>
              </div>
            </a>
          </div>
          <div className="gallary_item grid_col_span_2">
          <a data-fancybox="gallery" href={GG11rep}>
              <img src={GG11rep} alt="gallery img" />
              <div class="overlay">
                <span className="hover-text">Living Room</span>
              </div>
            </a>
          </div>
          <div className="gallary_item">
          <a data-fancybox="gallery" href={GG12rep}>
              <img src={GG12rep} alt="gallery img" />
              <div class="overlay">
                <span className="hover-text">Lobby</span>
              </div>
            </a>
          </div>

        </div>
      </>
      </Fancybox>
    </section>
  )
}

const Gallery = () => {
  return (
    <section className="gallery" id="Gallery">
      <LayoutMain>
        <div className="gallary_title">
          <p>GALLERY</p>
          <h2>EXPLORE  raet</h2>
        </div>
      </LayoutMain>
      <Layout>
        {/* Single collage */}
        <Collage />
        {/* Single collage */}

      </Layout>
    </section>

  );
};


export default Gallery;
