import React from 'react'
import Navigation from '../../components/common/navigation/Navigation'
import CompletedProject from '../../components/common/plpcompleted/completedproject/CompletedProject'
import Projectone from '../../components/common/plpcompleted/projectone/Projectone'
import { Helmet } from "react-helmet";





<Helmet>
<link rel="canonical" href="https://www.dynamixgroup.co.in/completed-projects" />        
</Helmet>
const PlpPagescompleted = () => {
  return (
    <>
      <Navigation />
      <CompletedProject />
      <Projectone />
    </>
  )
}

export default PlpPagescompleted
