import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cplakshachandiapartments/hero/Hero';
import Landmark from '../../components/common/cplakshachandiapartments/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>Residential Apartments In Goregaon East | Luxury Homes in Near Film City- Lakshachandi Apartments</title>
        <meta name="description" content="Looking for a luxury home near Film City, Mumbai? We offer premium residential apartments in Goregaon East with modern amenities. Explore Lakshachandi Apartments in Goregaon by Dynamix Group now!" />
        <link rel="canonical" href="https://www.dynamixgroup.co.in/completed-projects/lakshachandi-apartments" />
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
