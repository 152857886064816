import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Landmark from '../../components/common/realstate/landmark/Landmark';
import Experience from '../../components/common/realstate/experience/Experience';
import Facilities from '../../components/common/realstate/facilities/Facilities';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const EducationPage = () => {
  return (
    <>
      <Helmet>
        <title>2 BHK Flats In Ghodbunder Road, Thane (W) | Parkwoods - Dynamix Group</title>
        <meta name="description" content="Looking for a luxurious 2 BHK flat in Thane? Parkwoods by Dynamix Group, offers luxurious 2 BHK flats just behind D Mart, Ghodbunder Road, Thane West." />
      </Helmet>
      <Navigation />
      <Landmark />
      <Experience />
      <Facilities />
      <Contact />
      <Footer />
    </>
  );
};

export default EducationPage;
