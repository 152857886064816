import React from 'react'
import Layout from "../../../layout/LayoutMain/LayoutMain";

// Components
// Stylesheet
import './csrpagec.scss'

// Data
//
const Experience = () => {
    return (
        <Layout>
            <section className='csrpg peding' id='bgpadclr'>
                <div className='content-one'>
                   <h3 className='hiddendesk'>WHAT WE DO IS THE REFLECTION OF OUR PRINCIPLES.</h3>

                    <p>“Our company is founded on strong principles and values. Whether it is high impact CSR and social service, or building top quality developments for all income groups, we strive to enhance people’s lives by creating a better built environment.”</p>
                </div>
                <div>
                   <h3 className='hiddenmob'>WHAT WE DO IS THE REFLECTION OF OUR PRINCIPLES.</h3>
                </div>
                
            </section>
        </Layout>
    )
}

export default Experience
