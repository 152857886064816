import React from 'react'
// Components
import ExpSlider from './ExpSlider'
// Stylesheet
import './slideeducation.scss'

// Data
import { expeducationslider } from '../../../../constants/expData'

const Experience = () => {
    return (
        <section className='educationslide' id='Amenities'>
            <div className='exp_title_container'>
                <div>
                <h1 className='new-htwo-hone'>Schools and Institutions Managed by G.A.E.T</h1>
                <p>The trust has received donations from various philanthropic organisations & corporate bodies which helped us establish schools & institutions.</p>
                </div>
            </div>
            <ExpSlider expData={expeducationslider} />
        </section>
    )
}

export default Experience
