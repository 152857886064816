import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarkshikhar.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkshikhar' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>Find Your Happy Home</h2>
                    <div className="paraproject">
                        <p>The 14-storeys Shikharkunj project with 2 and 3-BHK apartments reverberates with echoes of happiness. Not only does it delight its residents with unrestricted views of the Marve Beach and Aarey Colony, but also lies at a stone’s throw from Goregaon and Malad railway stations as well as international and domestic airports. Three-level car parking, swimming pool, and all civic amenities at the location make it one happy destination for home-owners in Mumbai.</p>
                    </div>
                </div>
                <div>
                  <ul>
                  <li><strong>Nature</strong> <span>Residential Tower (2 wings – 14 storeys each)</span></li>
                    <li><strong>Location</strong> <span>Malad (East), Mumbai</span></li>
                    <li><strong>Design Architect</strong> <span>M/s Architectural Consultants</span></li>
                    <li><strong>BMC Architect</strong> <span>M/s Architectural Consultants</span></li>
                    <li><strong>Structural Consultant</strong> <span>M/s Rushi Housecone</span></li>
                    <li><strong>Contractor</strong> <span>M/s Rushi Housecone</span></li>
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
