import React from 'react'
import { Link } from 'react-router-dom';
import './projectone.scss';
import { listingData } from '../../../../constants/listingData';
const Projectone = () => {
  console.log(listingData)
  return (
    <div class="listing-sectioncuurent text-center">
      {
        listingData.map((item, index) => (
          <Link to={`${item.link}`} onClick={() => window.scrollTo(0, 0)}>
            <section class="section relative-box" style={{ backgroundImage: `url(${item.src})` }}>
              <div class="flex-box">
                <div className='mid-sec'>
                  <span>{item.num}</span>
                  <div class="loader-one"></div>



                  {/* <figure class="chart-two animate">
                    <svg role="img" xmlns="http://www.w3.org/2000/svg">
                      <span>{item.num}</span>
                      <circle class="circle-background" />
                      <circle class="circle-foreground" />
                    </svg>
                  </figure> */}


                </div>
                <div>
                  <h2 class="title-center">{item.name}</h2>
                  {/* <h5>View Details <small>+</small></h5> */}
                </div>
              </div>
              <div class="bottom-tagline test">
                <div class="psudo-box">
                  <h6>{item.city}</h6>
                </div>
                <h3>{item.destination}</h3>
              </div>
            </section>
          </Link>
        ))
      }
    </div>
  )
}

export default Projectone

