import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cpshikharkunj/hero/Hero';
import Landmark from '../../components/common/cpshikharkunj/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>2 & 3 BHK Apartments In Malad East | Luxury Flats in Malad East |  Shikharkunj - Dynamix Group</title>
        <meta name="description" content="Explore premium 2 & 3 BHK apartments in Malad East in Shikharkunj by Dynamix Group. Get luxury 2 & 3 BHK flats in Malad Mumbai with top-notch amenities." />
        <link rel="canonical" href="https://www.dynamixgroup.co.in/completed-projects/shikharkunj" />        
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
