import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarklakshachandi.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarklakshachandi' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>A Life Blessed</h2>
                    <div className="paraproject">
                        <p>From grand entrance lobbies and a banquet hall, to a multi-facility clubhouse and a swimming pool, Lakshachandi Apartments is your doorway to living better. Consisting of two 3-winged 15-storey towers and one 11-storey tower, Lakshachandi Apartments stand out as the ideal residential spaces in Goregaon (East). As the name suggests, it’s truly blessed by the Gods.</p>
                    </div>
                </div>
                <div>
                  <ul>
                  <li><strong>Nature</strong> <span>Residential Tower</span></li>
                    <li><strong>Location</strong> <span>Goregaon (East), Mumbai</span></li>
                    <li><strong>Design Architect</strong> <span>M/s Architectural Consultants</span></li>
                    <li><strong>BMC Architect</strong> <span>M/s Architectural Consultants</span></li>
                    <li><strong>Structural Consultant</strong> <span>M/s Mahimtura Consultant</span></li>
                    <li><strong>Contractor</strong> <span>	M/s Better Builder; M/s Ekesha Constructions</span></li>
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
