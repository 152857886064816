export const buyerguideBottomNavData = [
    {
        title: "LOAN FAQS",
        src: "LOANFAQS"
    },
    // {
    //     title: "TAX BENEFIT INFORMATION",
    //     src: "TAXBENEFITINFORMATION"
    // },
    {
        title: "EMI CALCULATOR",
        src: "EMICALCULATOR"
    },
    {
        title: "FAQS",
        src: "FAQS"
    },

]