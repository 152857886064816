import React, { useState } from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import Cursorarrowwhite from "../../../../assets/icons/cursor-arrow.svg";
import Cursorarrowblack from "../../../../assets/icons/cursor-arrowblack.svg";
import star from "../../../../assets/icons/star.svg";

// Stylesheet
import "./loanfaq.scss";

import { loanFeaturesData } from "../../../../constants/loanFeaturesData";

const LoanFaq = () => {
    const [active, setActive] = useState(0);
    const toggle = (i) => {
        if (active === i) {
            return setActive(0);
        }
        setActive(i);
    };
    return (
        <section className='mainbox' id="LOANFAQS">
            <Layout>
                <div className='loanfaq'>
                    <h2>Loan FAQs</h2>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has */}
                    {/* been the industry's standard dummy text ever since the 1500s.</p> */}
                </div>
                <div className="faqlist">
                    <ul>
                        {

                            loanFeaturesData.map((item, index) => (

                                <li className={active === index ? "listingbox activebg" : "quesbox"} key={index}>
                                    <div className='quesbox' onMouseEnter={() => toggle(index)}>
                                        <h2>{item.name}</h2>
                                        <p>{item.paraques}</p>
                                        <div className="imgarrow">
                                            {
                                                active === index ? <img src={Cursorarrowwhite} alt="plus" /> : <img src={Cursorarrowblack} alt="plus" />
                                            }
                                        </div>
                                    </div>
                                    <div className={active === index ? "anserbox anserbox_active" : "anserbox"}>
                                        <div className="right-answer">
                                            <h3>{item.paradetailtwo}</h3>
                                            <ul>
                                                {item.links.map((lnk, indx) => (
                                                    <li key={indx}>
                                                        <span><img src={star} alt='star' /></span>
                                                        <code>{lnk}</code>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>

                </div>
            </Layout>

        </section>
    )
}

export default LoanFaq;
