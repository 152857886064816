export const aboutBottomNavData = [
    {
        title: "Our Story",
        src: "Ourstory"
    },
    {
        title: "Our Values",
        src: "Ourvalues"
    },
    {
        title: "Our Design Ethos",
        src: "Ourdesignethos"
    },
    {
        title: "Group of Companies",
        src: "groupofcompany"
    },
    {
        title: "CSR",
        src: "cSR"
    },
    {
        title: "Management",
        src: "management"
    },
]