import React from 'react';
// import { motion } from 'framer-motion';
// Video
// import { Link } from 'react-router-dom';
import Layout from '../../../layout/Layout/Layout';

import QRluma1 from '../../../../assets/pdp/lumaqr/luma_qr.jpeg';
import QRluma2 from '../../../../../../revamp/src/assets/pdp/lumaqr/LumaTCqr.png';

import Lumapdf1 from '../../../../assets/resource-pdf/ascotcenterll/six-monthly-compliance-monitoring-report-june-dec-2022-1.pdf';
import Lumapdf2 from '../../../../assets/resource-pdf/ascotcenterll/environment-clearance-certificate.pdf';
import Lumapdf3 from '../../../../assets/resource-pdf/ascotcenterll/E.C.-NOC-09.12.2014.pdf';

// Stylesheet
import './barcode.scss';

const Barcode = () => {
  return (
    <section className="barcode-sec">
      <Layout>
        <div className="pdp_decription">
          <div className="bannertagline">
            <p>
              MahaRERA Registration Numbers P51800012155 (Tower A&B) & P51800054472 (Tower C). <br/> Project details are available at
              <a href="https://maharera.mahaonline.gov.in" target="_blank" rel="noopener">
                {' '}
                https://maharera.mahaonline.gov.in 
              </a>
            </p>
            {/* <p>MahaRERA Registration No. P51800018784.</p> */}
          </div>

          <div className="pdp_barcode">
            <div className="barcode">
              <img src={QRluma1} alt="plan" />
              <span>Luma Tower A & B</span>
            </div>
            <div className="barcode">
              <img src={QRluma2} alt="plan" />
              <span>Luma Tower C</span>
            </div>
          </div>
        </div>
      </Layout>

      <Layout>
        <div className="btn-viewcenter">
          <a className="btn btn-black" target="_blank" href={Lumapdf1}>
            Environment Clearance Certificate
          </a>
          <a className="btn btn-black" target="_blank" href={Lumapdf2}>
            Six Monthly Compliance Monitoring Report
          </a>
          <a className="btn btn-black" target="_blank" href={Lumapdf3}>
            E.C.-NOC
          </a>
        </div>
      </Layout>
    </section>
  );
};

export default Barcode;
