import React from 'react'
// Components
import ExpSlider from './ExpSlider'
// Stylesheet
import './experiencesrishti.scss'

// Data
import { expsrishtiData } from '../../../../constants/expData'

const Experience = () => {
    return (
        <section className='expsrishti' id='Amenities'>
            <div className='exp_title_container'>
                <div>
                <p>AMENITIES</p>
                <h2>THIS IS A CHANCE TO RETHINK<br/>
                YOUR IDEA OF WORK-LIFE BALANCE</h2>
                </div>
            </div>
            <ExpSlider expData={expsrishtiData} />
        </section>
    )
}

export default Experience
