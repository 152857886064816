import React from 'react'
import Navigation from '../../components/common/navigation/Navigation'
import TopHeadingcareer from '../../components/common/channelpartner/topheadingcareer/TopHeadingcareer';
import TeamDetail from '../../components/common/channelpartner/teamdetail/TeamDetail';
import Footer from '../../components/common/footer/Footer'
import { Helmet } from "react-helmet";



const Channelpartner = () => {
  return (
    <>

      <Helmet>
        <title>Enquire For Property In Mumbai, Thane, And Goa | Dynamix Group</title>
        <meta name="description" content="Find out the best real estate developments in Mumbai, Thane, and Goa by the Dynamix Group. Contact now." />
      </Helmet>
      <Navigation />
      <TopHeadingcareer />
      <TeamDetail />
      <Footer />
    </>
  )
}

export default Channelpartner
