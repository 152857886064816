import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarkascotcentre.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkascotcentre' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>The Heartbeat Of Andheri (East)</h2>
                    <div className="paraproject">
                        <p>Ascot Centre is a lavish 20-acre, 6-storeys commercial centre right in the heart of Andheri (East) offering office space ranging from 5000 sq. ft. to 25000 sq. ft. In its immediate vicinity lie the Mumbai International Airport, many 5-star hotels, and the commercial complex, Seepz.</p>
                    </div>
                </div>
                <div>
                  <ul>
                    <li><strong>Nature</strong> <span>Commercial</span></li>
                    <li><strong>Location</strong> <span>Andheri (E), Mumbai</span></li>
                    <li><strong>Design Architect</strong> <span>M/s Hafeez Contractor (14 storey)</span></li>
                    <li><strong>BMC Architect</strong> <span>M/s Bhatnagar Ambre Kothari</span></li>
                    <li><strong>Structural Consultant</strong> <span>M/s Mahimtura Consultant Pvt. Ltd.</span></li>
                    <li><strong>Contractor</strong> <span>M/s Likproof (I) Pvt. Ltd.</span></li>
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
