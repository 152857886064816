import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarkswapnalok.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkswapnalok' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>Your Dreamy Habitat</h2>
                    <div className="paraproject">
                        <p>If you have ever fancied living the life in Mumbai, then Swapnalok’s 20-storeys residential towers is the place for you. The best part is that everything – right from schools, colleges and hospitals to markets, playgrounds, malls and temples – is extremely accessible. Swapnalok’s USP lies in its brilliant location, infrastructure and construction – a guarantee of a home that will last generations.</p>
                    </div>
                </div>
                <div>
                  <ul>
                  <li><strong>Nature</strong> <span>Residential Towers (3 wings – 20 storeys each)</span></li>
                    <li><strong>Location</strong> <span>Malad (East), Mumbai</span></li>
                    <li><strong>Design Architect</strong> <span>M/s Architectural Consultants</span></li>
                    <li><strong>BMC Architect</strong> <span>M/s Architectural Consultants</span></li>
                    <li><strong>Structural Consultant</strong> <span>M/s Rajiv Shah & Associates</span></li>
                    <li><strong>Contractor</strong> <span>M/s Rushi Housecone</span></li>
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
