import React from 'react';
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import DimentionIcn from '../../../../assets/about/about-banner.webp'
// Stylesheet
import './fullbanner.scss'


const FullBanner = () => {


    return (
        <section className='fullbanner'>

            <div className='fullbanner'>
                <img src={DimentionIcn} alt="banner" />
            </div>

            <Layout>
                <div className='bannerdetail' id='Ourstory'>
                    <div>
                        <p>Built over decades, the Dynamix Group is a leading conglomerate dedicated to design and innovation that inspires and unites aspirations. Renowned for its well-designed residential and commercial properties in Mumbai, Thane and Goa, the group has demonstrated a focus in the real estate industry on building modern properties where families, professionals, and businesses can thrive.</p>

                        <p>Founded in the late 1970s by the visionary late Mr. K. M. Goenka, the Dynamix Group has established itself as one of the foremost names in Indian real estate. Under the guidance of Mr. Jayvardhan Goenka, the third-generation leader actively involved in the group's activities, the conglomerate continues to drive forward, leveraging his extensive 13 years of real estate experience.</p>
         
                        <p>The Dynamix Group has played a crucial role in addressing the acute shortage of affordable housing faced by the middle and lower-income groups in Mumbai's suburban areas. Developments like Gokuldham and Yashodham in Goregaon East have served as landmarks in their journey, propelling the group in its undertaking of further, and sometimes larger projects in areas such as Vasant Vihar in Thane West, Shristi in Mira Road, Upper Govind Nagar in Malad East, and Mahavir Nagar in Kandivali West. Upper Govind Nagar in Malad East, and Mahavir Nagar in Kandivali West.</p>

                        <p>Alongside affordable housing, the Dynamix Group has also ventured into prestigious luxury properties that set new standards for premium living. Stand-alone projects like Parkwoods in Thane, Divum in Goregaon Precinct, Avanya in Dahisar, Astrum in Malad, Luma in Andheri, and the luxury seaside hill station, Aldeia De Goa at Bambolim Beach showcase the group's commitment to excellence. These developments have become benchmarks in the real estate industry for developers and buyers, alike.</p>

                        <p>Beyond real estate, the group’s portfolio extends to other industries. The Dynamix Group owns and operates Schreiber Dynamix Dairy Ltd., one of the largest dairies in India. Additionally, Crystal Granite & Marble Pvt. Ltd., a 100% export-oriented unit, is engaged in cutting and polishing high-grade granite and marble for international markets.</p>

                        <p>Deeply embedded in the ethos of the company is the responsibility of giving back to society. The Goenka and Associates Educational Trust manages 9 educational institutions ranging from Primary Schools to ICSE, CBSE, HSC, and SSC Junior Colleges, as well as a dedicated Counselling Centre for neurodivergent learners with special educators. The schools educate over 22,000 students across the Mumbai Suburbs and Thane. The Goenka and Associates Medical and Research Centre provides subsidized healthcare to lower to mid-income patients through the Gokuldham Medical & Research Centre, treating over 900 patients per day. The group's social welfare trust manages the Krishna Vatika Devasthanam in Goregaon East, further contributing to the welfare of the community.</p>

                        <p>Choose the Dynamix Group for innovative designs, exceptional quality, and a commitment to community development. Join us in creating thriving communities that shape the future.</p>
                    </div>

                </div>
            </Layout>
        </section>
    )
}

export default FullBanner
