import React from 'react';
import './heroparkwood.scss';


import { motion } from 'framer-motion';


const textAnimate = {
    offscreen: { opacity: 0, y: 300 },
    onscreen: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.25 } }
};

const Hero = () => {
    return (
        <motion.section className='pdp_heroparkwoodbanner overlay-bg'>
            <motion.div className="hero_content" initial="offscreen" whileInView="onscreen" variants={textAnimate}>
                <h1 className='new-hone'>Parkwoods</h1>
                <h2 className='new-hone-hthree'>Thane West</h2>
                <h2 className='titlepara'>Spacious 2 bed residences on Ghodbunder Road, Thane</h2>
                {/* <h3 className="detail">Thane West</h3>
                <p>
                Spacious 2 bed residences in Ghodbunder Road, Thane
                </p> */}
            </motion.div>
        </motion.section>

    )
}

export default Hero
