import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarkboulevard.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkboulevard' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>Where Dreams Take Flight</h2>
                    <div className="paraproject">
                        <p>Boulevard-15 is a 13-storey residential tower that redefines relaxed and comfort living. Located in the stunning locale of Malad (East), Boulevard-15 offers 2 and 3 BHK residences with a unique blend of luxury and rejuvenation.</p>
                    </div>
                </div>
                <div>
                  <ul>
                    <li><strong>Nature</strong> <span>Residential Tower (13 storey)</span></li>
                    <li><strong>Location</strong> <span>Malad (East), Mumbai</span></li>
                    <li><strong>Design Architect</strong> <span>M/s IAG Consultants Pvt. Ltd.</span></li>
                    <li><strong>BMC Architect</strong> <span>Mr. Abhijit Mehta</span></li>
                    <li><strong>Structural Consultant</strong> <span>M/s Pravin Gala Consultants Pvt. Ltd.</span></li>
                    <li><strong>Contractor</strong> <span>M/s Rushi Housecon</span></li>
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
