export const footerData = [
    {
        name: "About Us",
        links: [
            ["Our Story", "/about-us#Ourstory"],
            ["Our Design Ethos", "/about-us#Ourdesignethos"],
            ["Group of Companies", "/about-us#groupofcompany"],
            ["Management", "/about-us#Ourstory"],
            ["CSR", "/about-us#cSR"],
            ["Channel Partner Registration", ""],
        ]
    },
    {
        name: "Real Estate",
        links: [
            ["Current Projects", "/current-projects"],
            ["Completed Projects", "/completed-projects"],
        ]
    },
    {
        name: "Buyers Guide",
        links: [

            ["Loan FAQs", "/buyers-guide#LOANFAQS"],
            ["Tax Benifit Information", "/buyers-guide#TAXBENEFITINFORMATION"],
            ["FAQs", "/buyers-guide#FAQS"],
            ["EMI Calculator", "/buyers-guide#EMICALCULATOR"],

        ]
    },
    {
        name: "Investors & NRI Desk",
        links: [

            ["Annual Filing", "/an-investors-heaven#Annualfiling"],
            ["Notice & Circulars", "/an-investors-heaven#Noticescirculars"],
            ["Careers", "/careers"],

        ]
    },
]