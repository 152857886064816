import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cpboulevard/hero/Hero';
import Landmark from '../../components/common/cpboulevard/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>2/3 BHK Residential Flats In Malad, Luxury Flats in Malad East | Boulevard-15 by Dynamix Groups</title>
        <meta name="description" content="Boulevard-15 Residential Tower offers 2/3 BHK apartments in Malad with a unique blend of luxury which redefines relaxed and comfortable living. Explore these luxury flats in Malad East now!!" />
        <link rel="canonical" href="https://www.dynamixgroup.co.in/completed-projects/boulevard-15" />
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
