import React from 'react';
import {motion} from 'framer-motion'
// Components
import Layout from '../../../layout/Layout/Layout';
import DimentionIcn from '../../../../assets/about/ethosfrontnew.webp';

// Stylesheet
import './ethospage.scss';
import { textAnimate } from '../../home/projects/Projects';

const EthosPage = () => {
  return (
    <section className="ethospage" id="Ourdesignethos">
      <Layout>
        <div className="ethos_row">
          <div className="left_container">
            <motion.div className="hide-mobile" initial="offscreen" whileInView="onscreen">
              {/* <motion.p className="title_underline" variants={textAnimate}>Design Ethos</motion.p> */}
              <motion.h2 variants={textAnimate}>Our Design Ethos</motion.h2>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s.
              </p> */}
            </motion.div>
            <div className="detailpara-ethos">
              <p>
                At Dynamix, we aspire to create remarkable buildings. We hold ourselves to the very highest standards of
                intellectual rigour in crafting our designs, looking over every last detail meticulously. We work with the country’s best
                architects and engineers, and constantly push them to push their own boundaries – such that they may create something
                better.
              </p>

              <p>
                Ludwig Mies Van Der Rohe, Dieter Rams, Frank Lloyd Wright, Steve Jobs. These are but a handful of names of remarkable
                individuals who, through design, have transformed the lives of many. Their work is inspirational, yet accessible; rigorous,
                yet functional; elegant, yet simple. Crucially, their designs – and the principles that they employed in producing them –
                are timeless.
              </p>

              <p>
                Ludwig Mies Van Der Rohe, Dieter Rams, Frank Lloyd Wright, Steve Jobs. These are but a handful of names of remarkable
                individuals who, through design, have transformed the lives of many. Their work is inspirational, yet accessible; rigorous,
                yet functional; elegant, yet simple. Crucially, their designs – and the principles that they employed in producing them –
                are timeless.
              </p>

              <p>
                We hope that one day, our work can occupy a space amidst the pantheon of the greats, like Fallingwater, the Barcelona
                Pavilion, the Braun ET 66 Calculator, and the iPod.
              </p>
            </div>
          </div>
          <div className="right_container">
            <div className="only-mobile">
              {/* <p className="title_underline">Design Ethos</p> */}
              <h2>Our Design Ethos</h2>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s.
              </p> */}
            </div>
            <img src={DimentionIcn} alt="front" />
          </div>
        </div>
      </Layout>
    </section>
  );
};

export default EthosPage;
