import React from 'react'
import Navigation from '../../components/common/navigation/Navigation'
import ResourcesCardspage from '../../components/common/resourcespage/resourcecardspage/ResourcesCardspage'
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer'
import { Helmet } from "react-helmet";





const ResourcesPage = () => {
  return (
    <>
      <Helmet>
        <title>Resources - Dynamix Group</title>
      </Helmet>
      <Navigation />
      <ResourcesCardspage />
      <Contact />
      <Footer />
    </>
  )
}

export default ResourcesPage
