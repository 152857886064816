import React, { useState } from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import PlusIcn from "../../../../assets/icons/plus.png";
import MinusIcn from "../../../../assets/icons/minus.png";
// Stylesheet
import "./frequentlyfaq.scss";

import { frequentlyFeaturesData } from "../../../../constants/frequentlyFeaturesData";

const FrequentlyFaq = () => {
    const [active, setActive] = useState(null);
    const toggle = (i) => {
        if (active == i) {
            return setActive(null);
        }
        setActive(i);
    };
    return (
        <section className='frequently' id="FAQS">
            <Layout>
                <div className='frequently_title'>
                    <h2>Frequently Asked Questions</h2>
                    
                </div>
                <div className='dropdown_container'>
                    {
                        frequentlyFeaturesData.map((item, index) => (
                            <div key={index}>
                                <div className='dropdown_item'>
                                    <div className='dropdown_title_container' onClick={()  =>  toggle(index)}>
                                        <div className='dropdown_title'>
                                            <h3>{item.name}</h3>
                                        </div>
                                        {
                                            active == index ? <img src={MinusIcn} alt="plus" /> : <img src={PlusIcn} alt="plus" />
                                        }

                                    </div>
                                    <hr />
                                </div>
                                <ul className={active == index ? "info_content info_active" : "info_content"}>
                                <div className="para-ans">{item.paradetail}</div>
                                <div className="para-ans">{item.paradetailtwo}</div>

                                    {item.links.map((lnk, indx) => (
                                        <li key={indx}>
                                            {lnk}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))
                    }
                </div>
            </Layout>
        </section>
    )
}

export default FrequentlyFaq;
