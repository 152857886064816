import React from 'react';
// Components
// Images
import DimentionIcn from '../../../../assets/buyerguide.jpg'
// Stylesheet
import './fullbanner.scss'

const FullBanner = () => {
    return (
        <section className='buyerguide-fullbanner'>

            <div className='fullbanner'>
                <img src={DimentionIcn} alt="banner" />
            </div>
        </section>
    )
}

export default FullBanner
