import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import AdminNavigation from '../../../components/common/admin/adminNavigation/AdminNavigation';
import AdminSidebar from '../../../components/common/admin/adminSidebar/AdminSidebar';
import './adminLayout.scss';

const AdminLayout = () => {
  useEffect(() => {
    document.title = 'Dynamix - Admin';
  });
  return (
    <section className="admin_layout">
      <aside className="aside_layout">
        <AdminSidebar />
      </aside>
      <section className="main_content_layout">
        <AdminNavigation />
        <Outlet />
      </section>
    </section>
  );
};

export default AdminLayout;
