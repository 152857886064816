import CompetitiveCompensation from "../assets/icons/careericons/CompetitiveCompensation.svg";
import HealthcareCoverage from "../assets/icons/careericons/HealthcareCoverage.svg";
// import RetirementPlanning from "../assets/icons/careericons/RetirementPlanning.svg";
import FlexibleWorkArrangements from "../assets/icons/careericons/FlexibleWorkArrangements.svg";
import ProfessionalDevelopment from "../assets/icons/careericons/ProfessionalDevelopment.svg";
import CareerGrowth from "../assets/icons/careericons/CareerGrowth.svg";
import PaidTimeOff from "../assets/icons/careericons/PaidTimeOff.svg";
// import PerformanceBonuses from "../assets/icons/careericons/PerformanceBonuses.svg";
import MaternityandPaternityLeave from "../assets/icons/careericons/MaternityandPaternityLeave.svg";
import EmployeeAssistanceProgram from "../assets/icons/careericons/EmployeeAssistanceProgram.svg";
import WellnessInitiatives from "../assets/icons/careericons/WellnessInitiatives.svg";
import CompanyEvents from "../assets/icons/careericons/CompanyEvents.svg";
// import TransportationAssistance from "../assets/icons/careericons/TransportationAssistance.svg";
import EmployeeRecognition from "../assets/icons/careericons/EmployeeRecognition.svg";
// import StockOptions from "../assets/icons/careericons/StockOptions.svg";
import DiversityandInclusion from "../assets/icons/careericons/DiversityandInclusion.svg";
// import ChildcareSupport from "../assets/icons/careericons/ChildcareSupport.svg";
// import VolunteerOpportunities from "../assets/icons/careericons/VolunteerOpportunities.svg";



export const joinFeature = [//
    {
        name: "Competitive Compensation",
        src: CompetitiveCompensation,  
    },
    {
        name: "Healthcare Coverage",
        src: HealthcareCoverage,
    },
    // {
    //     name: "Retirement Planning",
    //     src: RetirementPlanning,
    // },
    {
        name: "Flexible Work Arrangements",
        src: FlexibleWorkArrangements,
    },
    {
        name: "Professional Development",
        src: ProfessionalDevelopment,
    },
    {
        name: "Career Growth",
        src: CareerGrowth, 
    },
    {
        name: "Paid Time Off",
        src: PaidTimeOff,
    },
    // {
    //     name: "Performance Bonuses",
    //     src: PerformanceBonuses,
    // },
    {
        name: "Maternity and Paternity Leave",
        src: MaternityandPaternityLeave,
    },
    {
        name: "Employee Assistance Program",
        src: EmployeeAssistanceProgram,
    },
    {
        name: "Wellness Initiatives",
        src: WellnessInitiatives,
    },
    {
        name: "Company Events",
        src: CompanyEvents,
    },
    // {
    //     name: "Transportation Assistance",
    //     src: TransportationAssistance,
    // },
    {
        name: "Employee Recognition",
        src: EmployeeRecognition,
    },
    // {
    //     name: "Stock Options",
    //     src: StockOptions,
    // },
    {
        name: "Diversity and Inclusion",
        src: DiversityandInclusion,
    },
    // {
    //     name: "Childcare Support",
    //     src: ChildcareSupport,
    // },
    // {
    //     name: "Volunteer Opportunities",
    //     src: VolunteerOpportunities,
    // },
]