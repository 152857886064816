import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/luma/hero/Hero';
import BottomNav from '../../components/common/luma/bottomNav/BottomNav';
import Landmark from '../../components/common/luma/landmark/Landmark';
import Info from '../../components/common/luma/info/Info';
import Experience from '../../components/common/luma/experience/Experience';
// import Features from '../../components/common/luma/features/Features';
import Gallery from '../../components/common/luma/gallery/Gallery';
import Walkthrough from '../../components/common/luma/walkthrough/Walkthrough';
import VirtualTour from '../../components/common/luma/virtualTour/VirtualTour';
// import Exterior from '../../components/common/luma/exterior/Exterior';
import FloorPlan from '../../components/common/luma/floorPlan/FloorPlan';
import Barcode from '../../components/common/luma/barcode/Barcode';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { pdpBottomNavData } from '../../constants/pdpBottomNavData';
import { Helmet } from "react-helmet";



const LumaPage = () => {
  return (
    <>
      <Helmet>
        <title>Premium 2 & 3 Bed Residences in Andheri East | Residential Projects near Mumbai International Airport Luma
        </title>
        <meta name="description" content="Discover Spacious 2 & 3 bhk flats with private balconies in Andheri east at Luma by Dynamix Group. We offer luxury residential projects near mumbai international airport with top notch ameneties." />
        <link rel="canonical" href="https://www.dynamixgroup.co.in/current-projects/luma" />
      </Helmet>
      <Navigation />
      <Hero />
      <BottomNav pdpBottomNavData={pdpBottomNavData} />
      <Landmark />
      <Info />
      <Experience />
      {/* <Features /> */}
      <Gallery />
      <Walkthrough />
      <VirtualTour />
      {/* <Exterior /> */}
      <FloorPlan />
      <Barcode />
      <Contact />
      <Footer />
    </>
  );
};

export default LumaPage;
