import React, { useRef, useEffect } from 'react';

// Components
import Layout from "../../../layout/Layout/Layout";
import LayoutMain from "../../../layout/LayoutMain/LayoutMain";
// Images
import DG1rep from "../../../../assets/gallery/DG1rep.webp";
import DG2rep from "../../../../assets/gallery/DG2rep.webp";
import DG3rep from "../../../../assets/gallery/DG3rep.webp";
import DG4rep from "../../../../assets/gallery/DG4rep.webp";
import DG5rep from "../../../../assets/gallery/DG5rep.webp";
import DG6rep from "../../../../assets/gallery/DG6rep.webp";
import DG7rep from "../../../../assets/gallery/DG7rep.webp";
import DG8rep from "../../../../assets/gallery/DG8rep.webp";
// import DG9rep from "../../../../assets/gallery/DG9rep.webp";
// import DG10rep from "../../../../assets/gallery/DG10rep.webp";
// import DG11rep from "../../../../assets/gallery/DG11rep.webp";

// Stylesheet
import "./gallerydivum.scss";


import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Fancybox(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div ref={containerRef}>{props.children}</div>;
}




const Collage = () => {
  return (
    <section className="gallerycollapsedivum">
      <Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >
      <>
        <div className="gallary_containerdivum">
          <div className="gallary_item grid_row_span_2">
            <a data-fancybox="gallery" href={DG1rep}>
            <img src={DG1rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">FRONT VIEW ELEVATION</span>
            </div>
            </a>
          </div>
          <div className="gallary_item">
            <a data-fancybox="gallery" href={DG2rep}>
            <img src={DG2rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">FRONT VIEW ELEVATION</span>
            </div>
            </a>
          </div>
          <div className="gallary_item grid_col_span_2 grid_row_span_2">
            <a data-fancybox="gallery" href={DG3rep}>
            <img src={DG3rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">LIVING ROOM</span>
            </div>
            </a>
          </div>
          <div className="gallary_item ">
            <a data-fancybox="gallery" href={DG4rep}>
            <img src={DG4rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">BIRD EYE VIEW ELEVATION</span>
            </div>
            </a>
          </div>
        </div>
        <div className="gallary_containerdivum">
          <div className="gallary_item grid_row_span_2">
            <a data-fancybox="gallery" href={DG5rep}>
            <img src={DG5rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">ENTRANCE GATE</span>
            </div>
            </a>
          </div>
          <div className="gallary_item ">
            <a data-fancybox="gallery" href={DG6rep}>
            <img src={DG6rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">LIVING ROOM</span>
            </div>
            </a>
          </div>
          <div className="gallary_item grid_col_span_2 grid_row_span_2">
            <a data-fancybox="gallery" href={DG7rep}>
            <img src={DG7rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">MASTER BEDROOM</span>
            </div>
            </a>
          </div>
          <div className="gallary_item ">
            <a data-fancybox="gallery" href={DG8rep}>
            <img src={DG8rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">MASTER BEDROOM</span>
            </div>
            </a>
          </div>
        </div>
      </>
      </Fancybox>
    </section>

  )
}
//
const Gallery = () => {
  return (
    <section className="gallery" id="Gallery">
      <LayoutMain>
        <div className="gallary_title">
          <p>GALLERY</p>
          <h2>EXPLORE  DIVUM</h2>
        </div>
      </LayoutMain>
      <Layout>
        {/* Single collage */}
        <Collage />
        {/* Single collage */}

      </Layout>
    </section>

  );
};


export default Gallery;
