import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images

// Stylesheet
import "./landmarkpachsheel.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkpanchsheel' id="Overview">
                <div>
                    <p>Overview</p>
                    <h2>Tranquillity At Its Best</h2>
                    <div className="paraproject">
                        <p>What makes Panchsheel Gardens one of the most sought after residential addresses in Kandivali (West)? The answer lies in its excellent location blessed with peace and calm – accompanied by wide roads, tree-lined avenues, shopping amenities, playground, temples, educational institutions and hospitals. It is part of the Mahavir Nagar township, which is spread across 140 acres – the perfect place to live in harmony.</p>
                    </div>
                </div>
                <div>
                  <ul>
                  <li><strong>Nature</strong> <span>Residential Towers (3 wings – 14 storeys)</span></li>
                    <li><strong>Location</strong> <span>Kandivali (West), Mumbai</span></li>
                    <li><strong>Design Architect</strong> <span>M/s Architectural Consultants</span></li>
                    <li><strong>BMC Architect</strong> <span>M/s Architectural Consultants</span></li>
                    <li><strong>Structural Consultant</strong> <span>M/s Naik Consultant</span></li>
                    <li><strong>Contractor</strong> <span>	M/s J V Enterprises</span></li>
                  </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
