
import LayoutMain from '../../../layout/LayoutMain/LayoutMain';



import React from 'react'
import './disclaimercontent.scss';

const DisclaimerContent = () => {
  return (

    <LayoutMain>

    <div className="disclaimertxt">
        <div className="main-heading">
          <h2>Disclaimer</h2>
        </div>
        <div className="para-detail">
          <p>Thank you for visiting the website of Dynamix Group.</p>
          <p>By using or accessing this website, you agree with the Disclaimer without any qualification or limitation.</p>
          <p>Dynamix Group and its subsidiaries / affiliates (‘Dynamix’) reserve the right to terminate, revoke, modify, alter, add and delete any one or more of the terms and conditions of the website. Dynamix shall be under no obligation to notify the visitor of the amendment(s) made to the terms and conditions and the visitor shall be bound by such amended terms and conditions.</p>
          <p>Computer generated images, walkthroughs, rendered images and representational imagery used on this website are artist's impressions and are a indicative of the actual designs. The imagery used on the website may not represent actuals or may be indicative of style only.</p>
          <p>The visuals and information contained herein marked as "artist’s impression" are artistic impressions being indicative in nature and are for general information purposes only. The visuals contained marked as "representational image" and other visuals /images /photographs are general images and are not correlated with the project as shall be finally developed and handed over. Photographs of interiors, surrounding views and location may not represent actuals or may have been digitally altered. These photographs may not represent actuals or may be indicative only. Computer generated images, walkthroughs and render images are the artist's impression and are an indicative of the actual designs. While appropriate and enough care is taken by Dynamix to ensure that information contained herein is up to date, accurate and correct, the readers / users are requested to make an independent enquiry with Dynamix before relying upon the same.</p>
          <p>The information on this website is presented as general information and no representation or warranty is expressly or impliedly given as to its accuracy, completeness or correctness. It does not constitute part of a legal offer or contract.</p>
          <p>This website may unintentionally include inaccuracies or errors with respect to the description of a plot/ flat size, site plan, floor plan, a rendering, a photo, elevation, prices, taxes, adjacent properties, amenities, design guidelines, completion dates, features, zoning, buyer incentives etc. The user must verify all the details and specifications, including but not limited to the area, amenities, specifications, services, terms of sales, payments and all other relevant terms independently and with Dynamix, at site and from approved plans etc. including with our sales / marketing team prior to concluding any decision for buying any unit in any of our projects / developments.</p>
          <p>Notwithstanding anything, in no event shall Dynamix, its promoters, partners/ directors, employees and agents be liable to the visitor/user for any or all damages, losses and causes of action (including but not limited to negligence), errors, injury, whether direct, indirect, consequential or incidental, suffered or incurred by any person/s or due to any use and/or inability to use this site or information or its links, hyperlinks, action taken or abstained or any transmission made through this website.</p>
          <p>While enough care is taken by Dynamix to ensure that information and data depicted on the website are up to date, accurate and correct, the readers/ users are requested to make their independent enquiry before relying upon the same.</p>
          <p>Nothing contained should be misconstrued as promotion, advertising, marketing, booking, selling or an offer for sale or invitation or inducement to purchase a unit in any project by Dynamix. Any interested party should verify all the information including designs, plans, specifications, facilities, features, payment schedules, terms of sales etc. independently with Dynamix, their own advisors, and RERA prior to concluding any decision to purchase or transact. Only the contents of executed and registered Agreements to Sell shall be valid for agreed terms and conditions and to be binding on parties concerned.</p>
          <p>Thank you for visiting the website of Dynamix Group.</p>
          <p>By using or accessing this website, you agree with the Disclaimer without any qualification or limitation.</p>
          <p>Dynamix Group and its subsidiaries / affiliates (‘Dynamix’) reserve the right to terminate, revoke, modify, alter, add and delete any one or more of the terms and conditions of the website. Dynamix shall be under no obligation to notify the visitor of the amendment(s) made to the terms and conditions and the visitor shall be bound by such amended terms and conditions.</p>
          <p>Computer generated images, walkthroughs, rendered images and representational imagery used on this website are artist's impressions and are a indicative of the actual designs. The imagery used on the website may not represent actuals or may be indicative of style only.</p>
          <p>The visuals and information contained herein marked as "artist’s impression" are artistic impressions being indicative in nature and are for general information purposes only. The visuals contained marked as "representational image" and other visuals /images /photographs are general images and are not correlated with the project as shall be finally developed and handed over. Photographs of interiors, surrounding views and location may not represent actuals or may have been digitally altered. These photographs may not represent actuals or may be indicative only. Computer generated images, walkthroughs and render images are the artist's impression and are an indicative of the actual designs. While appropriate and enough care is taken by Dynamix to ensure that information contained herein is up to date, accurate and correct, the readers / users are requested to make an independent enquiry with Dynamix before relying upon the same.</p>
          <p>The information on this website is presented as general information and no representation or warranty is expressly or impliedly given as to its accuracy, completeness or correctness. It does not constitute part of a legal offer or contract.</p>
          <p>This website may unintentionally include inaccuracies or errors with respect to the description of a plot/ flat size, site plan, floor plan, a rendering, a photo, elevation, prices, taxes, adjacent properties, amenities, design guidelines, completion dates, features, zoning, buyer incentives etc. The user must verify all the details and specifications, including but not limited to the area, amenities, specifications, services, terms of sales, payments and all other relevant terms independently and with Dynamix, at site and from approved plans etc. including with our sales / marketing team prior to concluding any decision for buying any unit in any of our projects / developments.</p>
          <p>Notwithstanding anything, in no event shall Dynamix, its promoters, partners/ directors, employees and agents be liable to the visitor/user for any or all damages, losses and causes of action (including but not limited to negligence), errors, injury, whether direct, indirect, consequential or incidental, suffered or incurred by any person/s or due to any use and/or inability to use this site or information or its links, hyperlinks, action taken or abstained or any transmission made through this website.</p>
          <p>While enough care is taken by Dynamix to ensure that information and data depicted on the website are up to date, accurate and correct, the readers/ users are requested to make their independent enquiry before relying upon the same.</p>
          <p>Nothing contained should be misconstrued as promotion, advertising, marketing, booking, selling or an offer for sale or invitation or inducement to purchase a unit in any project by Dynamix. Any interested party should verify all the information including designs, plans, specifications, facilities, features, payment schedules, terms of sales etc. independently with Dynamix, their own advisors, and RERA prior to concluding any decision to purchase or transact. Only the contents of executed and registered Agreements to Sell shall be valid for agreed terms and conditions and to be binding on parties concerned.</p>
          <p>Thank you for visiting the website of Dynamix Group.</p>
          <p>By using or accessing this website, you agree with the Disclaimer without any qualification or limitation.</p>
          <p>Dynamix Group and its subsidiaries / affiliates (‘Dynamix’) reserve the right to terminate, revoke, modify, alter, add and delete any one or more of the terms and conditions of the website. Dynamix shall be under no obligation to notify the visitor of the amendment(s) made to the terms and conditions and the visitor shall be bound by such amended terms and conditions.</p>
          <p>Computer generated images, walkthroughs, rendered images and representational imagery used on this website are artist's impressions and are a indicative of the actual designs. The imagery used on the website may not represent actuals or may be indicative of style only.</p>
          <p>The visuals and information contained herein marked as "artist’s impression" are artistic impressions being indicative in nature and are for general information purposes only. The visuals contained marked as "representational image" and other visuals /images /photographs are general images and are not correlated with the project as shall be finally developed and handed over. Photographs of interiors, surrounding views and location may not represent actuals or may have been digitally altered. These photographs may not represent actuals or may be indicative only. Computer generated images, walkthroughs and render images are the artist's impression and are an indicative of the actual designs. While appropriate and enough care is taken by Dynamix to ensure that information contained herein is up to date, accurate and correct, the readers / users are requested to make an independent enquiry with Dynamix before relying upon the same.</p>
          <p>The information on this website is presented as general information and no representation or warranty is expressly or impliedly given as to its accuracy, completeness or correctness. It does not constitute part of a legal offer or contract.</p>
          <p>This website may unintentionally include inaccuracies or errors with respect to the description of a plot/ flat size, site plan, floor plan, a rendering, a photo, elevation, prices, taxes, adjacent properties, amenities, design guidelines, completion dates, features, zoning, buyer incentives etc. The user must verify all the details and specifications, including but not limited to the area, amenities, specifications, services, terms of sales, payments and all other relevant terms independently and with Dynamix, at site and from approved plans etc. including with our sales / marketing team prior to concluding any decision for buying any unit in any of our projects / developments.</p>
          <p>Notwithstanding anything, in no event shall Dynamix, its promoters, partners/ directors, employees and agents be liable to the visitor/user for any or all damages, losses and causes of action (including but not limited to negligence), errors, injury, whether direct, indirect, consequential or incidental, suffered or incurred by any person/s or due to any use and/or inability to use this site or information or its links, hyperlinks, action taken or abstained or any transmission made through this website.</p>
          <p>While enough care is taken by Dynamix to ensure that information and data depicted on the website are up to date, accurate and correct, the readers/ users are requested to make their independent enquiry before relying upon the same.</p>
          <p>Nothing contained should be misconstrued as promotion, advertising, marketing, booking, selling or an offer for sale or invitation or inducement to purchase a unit in any project by Dynamix. Any interested party should verify all the information including designs, plans, specifications, facilities, features, payment schedules, terms of sales etc. independently with Dynamix, their own advisors, and RERA prior to concluding any decision to purchase or transact. Only the contents of executed and registered Agreements to Sell shall be valid for agreed terms and conditions and to be binding on parties concerned.</p>
        </div></div>
        </LayoutMain>


  )
}

export default DisclaimerContent
