import React from 'react';
// import { motion } from 'framer-motion';
// Video
// import { Link } from 'react-router-dom';
import Layout from "../../../layout/Layout/Layout";


import QRGOA1 from '../../../../assets/pdp/algoaqr/Aldeia-de-Goa-qrcode.png';
import QRGOA2 from '../../../../assets/pdp/algoaqr/Frangipani-qrcode.png';

// Stylesheet
import './barcode.scss';



const Barcode = () => {
  return (

    <section className='barcode-sec'>   
       <Layout>
       <div className='pdp_decription'>
                <div className='bannertagline'>
                    <p>The Project has been Registered Via GoaRERA Numbers: <br/> PRGO03180177, PRGO03180182 and is available on the website <a href="https://www.rera.goa.gov.in/reraApp/home" target="_blank" rel="noopener"> https://www.rera.goa.gov.in/reraApp/home</a> under registered projects.</p>
                </div>

                <div className='pdp_barcode'>
                    <div className='barcode'>
                        <img src={QRGOA1} alt="plan" />
                        <span>Aldeia de Goa</span>
                    </div>
                    <div className='barcode'>
                        <img src={QRGOA2} alt="plan" />
                        <span>Frangipani</span>
                    </div>
                </div>
            </div>
    </Layout>


    </section>

  );
};

export default Barcode;
