export const pdpBottomNavData = [
    {
        title: "Overview",
        src: "Overview"
    },
    {
        title: "Neighbourhood",
        src: "Neighbourhood"
    },
    {
        title: "Amenities",
        src: "Amenities"
    },
    // {
    //     title: "Project Features",
    //     src: "Project"
    // },
    {
        title: "Gallery",
        src: "Gallery"
    },
    // {
    //     title: "Walkthrough",
    //     src: "Walkthrough"
    // },
    // {
    //     title: "Virtual Tour",
    //     src: "Virtual"
    // },
    // {
    //     title: "360° View",
    //     src: "View"
    // },
    {
        title: "Floor Plans",
        src: "Plan"
    },
    {
        title: "Brochure",
        src: "Brochure"
    },
]