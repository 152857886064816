import React from 'react'

import Astrum from "../../../../assets/project-listing/Astrum.webp";
import Avanya from "../../../../assets/project-listing/Avanya.webp";
import Divum from "../../../../assets/project-listing/Divum.webp";
import aldeGoa from "../../../../assets/project-listing/Goanew.webp";
import Parkwood from "../../../../assets/project-listing/Parkwood.webp";
import Srishti from "../../../../assets/project-listing/Srishti.webp";
import { Link } from 'react-router-dom';

import './galleryrow.scss';

const GalleryRow = () => {
  return (

    <section className='galleryrow'>

      <div className="gallery-heading">
        <div className="text-centercontent">
          <div className="main-heading">
            <div className="psudo-box">
              <h6>Construction</h6>
              <span className="psudo-border"></span>
            </div>
            <h2>Masterful Creations</h2>
          </div>
          <div className="para-detail mt-4">
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
              been the industry's standard dummy text ever since the 1500s.</p>
          </div>
        </div>
      </div>

      <div className="cardcontainer">
        <div className="card">
          <Link to="/gallerypage">
            <img src={Divum} alt="3d icon" />
            <div className="card__head"><span>Divum</span><h5><a href='/divum'>View Details</a> <span>+</span></h5></div>
          </Link>
        </div>
        <div className="card">
          <Link to="/gallerypage">
          <img src={Avanya} alt="3d icon" />
          <div className="card__head"><span>Avanya</span><h5><a href='/avanya'>View Details</a> <span>+</span></h5></div>
          </Link>
        </div>
        <div className="card">
          <Link to="/gallerypage">
          <img src={Parkwood} alt="3d icon" />
          <div className="card__head"><span>Parkwoods</span><h5><a href='/Parkwoods'>View Details</a> <span>+</span></h5></div>
          </Link>
        </div>
      </div>

      <div className="cardcontainer">
        <div className="card">
          <Link to="/gallerypage">
          <img src={Astrum} alt="3d icon" />
          <div className="card__head"><span>Astrum</span><h5><a href='/astrum'>View Details</a> <span>+</span></h5></div>
          </Link>
        </div>
        <div className="card">
          <Link to="/gallerypage">
          <img src={Srishti} alt="3d icon" />
          <div className="card__head"><span>Srishti</span><h5><a href='/srishtinamaah'>View Details</a> <span>+</span></h5></div>
          </Link>
        </div>
        <div className="card">
          <Link to="/gallerypage">
          <img src={aldeGoa} alt="3d icon" />
          <div className="card__head"><span>Goa</span><h5><a href='/aldeiadegoa'>View Details</a> <span>+</span></h5></div>
          </Link>
        </div>
      </div>

    </section>





  )
}

export default GalleryRow
