import React from 'react'
import Layout from '../../../layout/Layout/Layout';
import Facebook from "../../../../assets/icons/facebook.png";
import Instagram from "../../../../assets/icons/instagram.png";
import LinkedIn from "../../../../assets/icons/linkedin.png";
import Twitter from "../../../../assets/icons/twitter.png";
import Featimage from "../../../../assets/featuremenu.png";

import './topmainheadingnri.scss';

const TopmainHeadingnri = () => {
  return (

    <section>
      <div className="guidedeheading">
        <div className="text-center">
          <div className="main-heading">
            <div className="psudo-box">
              <h6>NRI GUIDE</h6>
              <span className="psudo-border"></span>
            </div>
            <h1 className='new-htwo-hone'>NRI Insights and Information</h1>
          </div>
          <div className="para-detail mt-4">
            <p>Understanding Non-Resident Indians, Persons of Indian Origin, and Overseas Corporate Bodies</p>
          </div>
        </div>
      </div>
    </section>


  )
}

export default TopmainHeadingnri
